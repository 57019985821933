// js
import {
    DataDeliveryMen,
    InfoDeliveryMenInterfaces,
} from '../../interfaces';
import functions from '../../utils/functions';

const ArrayOptionsDropDawn = (v: DataDeliveryMen): Array<string> => {
    let arrayOptionsDropDawn: Array<string> = [];
    if (v.acceptRegistred === 'Validado') {
        if (v.allow_change_photo) {
            arrayOptionsDropDawn = ['Recuperar senha', 'Bloquear', 'Bloquear permissão de foto'];
        }
        if (!v.allow_change_photo) {
            arrayOptionsDropDawn = ['Recuperar senha', 'Bloquear', 'Desbloquear permissão de foto'];
        }
    }
    if (v.acceptRegistred === 'Pendente') {
        arrayOptionsDropDawn = ['Bloquear', 'Validar entregador'];
    }
    if (v.acceptRegistred === 'Bloqueado') {
        arrayOptionsDropDawn = ['Desbloquear'];
    }
    if (v.acceptRegistred === 'Inativo') {
        arrayOptionsDropDawn = ['Restaurar'];
    }
    if (v.fix) {
        arrayOptionsDropDawn = ['Desfixar entregador', ...arrayOptionsDropDawn];
    }
    if (!v.fix) {
        arrayOptionsDropDawn = ['Fixar entregador', ...arrayOptionsDropDawn];
    }

    if (v.acceptRegistred !== 'Inativo') {
        return [...arrayOptionsDropDawn, 'Excluir'];
    }
    return ['Restaurar', 'Fixar entregador'];
};

const OneDeliveryMen = (v: DataDeliveryMen): Array<InfoDeliveryMenInterfaces> => (
    [
        {
            label: 'Nome Completo:',
            value: v.name,
        },
        {
            label: 'CPF:',
            value: functions.MaskIdentifier(v.cpf),
        },
        {
            label: 'Data de Nascimento:',
            value: v.birth_date,
        },
        {
            label: 'Telefone:',
            value: functions.MaskPhone(v.telephone),
        },
        {
            label: 'Email:',
            value: v.email,
        },
        {
            label: 'CEP:',
            value: v.cep,
        },
        {
            label: 'Rua:',
            value: v.address,
        },
        {
            label: 'Bairro:',
            value: v.neighborhood,
        },
        {
            label: 'Complemento:',
            value: `${v.number_home}/${v.complement}`,
        },
        {
            label: 'Status do Entregador:',
            value: v.acceptRegistred,
        },
        {
            label: 'Taxa de Aceitação:',
            value: v.acceptInviteTax,
        },
        {
            label: 'Ranking:',
            value: v.ranking,
        },
        {
            label: 'Estrelas:',
            value: `${v.rating} estrelas`,
        },
        {
            label: 'Corridas Aceitas:',
            value: v.accept_invite_delivery,
        },
        {
            label: 'Estabelecimento:',
            value: `${v.id_seller_link},${v.name_seller_link}` || '',
        },
    ]
);

export default {
    ArrayOptionsDropDawn,
    OneDeliveryMen,
};
