/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

// js
import {
    Home,
    Container,
    ContainerInner,
    ContainerUsers,
    TitleScreen,
    TitleDefault,
    ContentUsers,
    ContainerHeader,
    // CheckBox,
    ContainerInfo,
    ContainerBodyDeliveryMen,
} from './usersStyles';
import components from './UsersComponents';
import profile from '../../services/profile';
import usersConstants from './usersConstants';
import usersFunctions from './usersFunctions';
import { RootState } from '../../store';
import { DataSubSellerInterfaces } from '../../interfaces';
import { DataInputsInterfaces } from './usersInterfaces';

// components
import MenuComponent from '../../components/Menu/MenuComponent';
import colors from '../../styles/colors';
import ButtonAuth from '../Auth/components/Button';
import { updateHistoryRoutes } from '../../store/modules/seller/actions';

const UsersPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const infoSeller = useSelector((state: RootState) => state.seller.seller);
    const historyRoutes = useSelector((state: RootState) => state.seller.historyRoutes);

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    // const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [allSellers, setAllSellers] = useState<Array<DataSubSellerInterfaces>>([]);
    const [idScreen, setIdScreen] = useState<string | boolean | null>('list');
    const [inputs, setInputs] = useState<DataInputsInterfaces>(usersConstants.initialInputs);
    const [validationExistSeller, setValidationExistSeller] = useState(true);
    const [qtdDropDawn, setQtdDropDawn] = useState(3);
    const [idActionDropDawn, setIdActionDropDawn] = useState<number | null>(null);

    // constants
    const {
        buttonProps,
        itemProps,
    } = useDropdownMenu(qtdDropDawn);

    // methods
    // const onChangeIsCheckedAll = (txt: React.ChangeEvent<HTMLInputElement>) => {
    //     const validationBoolean = txt.target.value.toLowerCase() === 'true';
    //     setIsCheckedAll(validationBoolean);
    //     const InfoSellerActionFormat = allSellers.map((v) => {
    //         if (v.id) {
    //             return {
    //                 ...v,
    //                 checked: validationBoolean,
    //             };
    //         }

    //         return v;
    //     });
    //     setAllSellers(InfoSellerActionFormat || []);
    // };

    const GetInfoScreen = async () => {
        const GetInfoSellers = await profile.GetInfoSeller('', infoSeller?.id, 'sub_sellers', infoSeller?.token);
        if (GetInfoSellers.status === 200) {
            const InfoSellerActionFormat = GetInfoSellers?.result.map((v) => {
                if (v.id) {
                    return {
                        ...v,
                        checked: false,
                    };
                }

                return v;
            });
            setAllSellers(InfoSellerActionFormat || []);
            if (!InfoSellerActionFormat.length) {
                setValidationExistSeller(false);
            }
        }
    };

    const GoToCreateSubSeller = async () => {
        setIdScreen('create');
        setInputs(usersConstants.initialInputs);
    };

    const GoToListSubSeller = () => {
        setIdScreen('list');
    };

    const CreateSubSeller = async () => {
        usersFunctions.CreateSubSeller(
            inputs,
            setInputs,
            idScreen,
            setIdScreen,
            infoSeller,
            GetInfoScreen,
        );
    };

    const DeleteAllUsersSelect = async () => {
        usersFunctions.DeleteAllUsersSelect(
            allSellers,
            infoSeller,
            GetInfoScreen,
        );
    };

    // useEffect
    useEffect(() => {
        GetInfoScreen();
        if (historyRoutes.slice(-1)[0] === window.location.pathname) {
            // eslint-disable-next-line max-len
            const removeLastItem = historyRoutes.length === 1 ? [] : historyRoutes.slice(0, historyRoutes.length - 1);
            dispatch(updateHistoryRoutes(removeLastItem));
        }
    }, []);

    // renders
    const RenderScreen = () => {
        if (idScreen === 'list') {
            return (
                <ContainerUsers visibleItem={visibleDrawer}>
                    <TitleScreen>Usuários</TitleScreen>
                    <ContentUsers>
                        {allSellers.length ? (
                            <ContainerHeader padding="10px 0px 10px 0px" justifyContent="space-between">
                                <ContainerBodyDeliveryMen
                                    isOpen={idActionDropDawn !== null}
                                >
                                    {/* eslint-disable-next-line max-len */}
                                    {/* <CheckBox type="checkbox" checked={isCheckedAll} onChange={onChangeIsCheckedAll} value={JSON.stringify(!isCheckedAll)} /> */}
                                    <TitleDefault widthText="20%" color={colors.theme.textTabs}>Nome do usuário</TitleDefault>
                                    <TitleDefault widthText="20%" marginLeft="5px" color={colors.theme.textTabs}>E-mail</TitleDefault>
                                    <TitleDefault widthText="20%" marginLeft="5px" color={colors.theme.textTabs}>Função</TitleDefault>
                                </ContainerBodyDeliveryMen>
                                <TitleDefault widthText="20px" marginRight="5px" />
                            </ContainerHeader>
                        ) : null}
                        {!allSellers.length && validationExistSeller === false ? (
                            <TitleDefault fontWeight="bold" fontSize="15px">Sem usuários para serem exibidos.</TitleDefault>
                        ) : null}
                        {components.RenderAllSellers(
                            allSellers,
                            setAllSellers,
                            infoSeller,
                            GetInfoScreen,
                            setIdScreen,
                            setInputs,
                            buttonProps,
                            itemProps,
                            idActionDropDawn,
                            setIdActionDropDawn,
                            setQtdDropDawn,
                            historyRoutes,
                            dispatch,
                        )}
                        <ContainerInfo flexDirection="row" justifyContent="flex-start" marginTop="40px">
                            <ButtonAuth onClick={GoToCreateSubSeller} label="CRIAR USUÁRIO" colorButton={null} colorText={null} />
                            {allSellers.length ? (
                                <ButtonAuth onClick={DeleteAllUsersSelect} label="DELETAR USUÁRIO" colorButton={colors.theme.backgroundDrawer} colorText={null} />
                            ) : null}
                        </ContainerInfo>
                    </ContentUsers>
                </ContainerUsers>
            );
        }

        if (idScreen === 'create' || idScreen === 'edit' || idScreen === 'tradePassword') {
            return components.RenderModifySubSeller(
                inputs,
                setInputs,
                idScreen,
                visibleDrawer,
                CreateSubSeller,
                GoToListSubSeller,
            );
        }

        return null;
    };

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent
                        visibleDrawer={visibleDrawer}
                        setVisibleDrawer={setVisibleDrawer}
                    />
                    {RenderScreen()}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default UsersPage;
