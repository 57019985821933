// js
import { DataInputsInterfaces } from './usersInterfaces';
import { DataArchiveInterface } from '../../interfaces';

const initialArchive: DataArchiveInterface = {
    eventFile: new Blob(),
    eventName: '',
    file: '',
};

const initialInputs: DataInputsInterfaces = {
    nameClient: '',
    emailClient: '',
    passwordClient: '',
    confirmPasswordClient: '',
    type: 'operator',
    photo: initialArchive,
    identifier: '',
};

const allTabs = [
    {
        id: 'operator',
        title: 'Operador',
    },
    {
        id: 'admin',
        title: 'Admin Empresa',
    },
];

export default {
    allTabs,
    initialInputs,
    initialArchive,
};
