// js
import { DataInputsInterfaces } from './profileInterfaces';

// constants
const initialInputs: DataInputsInterfaces = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    photo: '',
};

export default {
    initialInputs,
};
