/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React from 'react';

// js
import {
    ContainerHeader,
    ContainerInfo,
    PhotoSeller,
    TitleDefault,
    ImgEllipsis,
    ContainerNull,
    TabOption,
    ContainerOptions,
    ContainerOpenDropDawn,
    ContainerBodyDeliveryMen,
    ContainerImgEllipsis,
} from './deliveryManStyles';
import {
    iconEllipsis,
    logoAuth,
    starEnabled,
    starDisabled,
} from '../../assets/index';
import colors from '../../styles/colors';
import {
    DataDeliveryMen,
    DataSellerInterface,
    ArchivesDeliveryMenInterfaces,
    InfoDeliveryMenInterfaces,
} from '../../interfaces';
import functions from '../../utils/functions';
import deliveryMan from '../../services/deliveryMan';
import deliveryManFunctions from './deliveryManFunctions';
import users from '../../services/users';
import { updateHistoryRoutes } from '../../store/modules/seller/actions';

const RenderAllSellers = (
    allDeliveryMen: Array<DataDeliveryMen>,
    setAllDeliveryMen: (v: Array<DataDeliveryMen>) => void,
    infoSeller: DataSellerInterface,
    GetInfoScreen: () => void,
    setIdScreen: (v: string) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    buttonProps: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    itemProps: any,
    idActionDropDawn: number | null,
    setIdActionDropDawn: (v: number | null) => void,
    setQtdDropDawn: (v: number) => void,
    setInfoDeliveryMen: (v: Array<InfoDeliveryMenInterfaces>) => void,
    setArchivesDeliveryMen: (v: ArchivesDeliveryMenInterfaces) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    historyRoutes: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dispatch: any,
): React.ReactElement | null => {
    if (allDeliveryMen.length) {
        return (
            <>
                {allDeliveryMen.map((v, i) => {
                    // constants
                    const arrayOptionsDropDawn = deliveryManFunctions.ArrayOptionsDropDawn(v);

                    // methods
                    const GetDataDeliveryMen = () => {
                        setInfoDeliveryMen(deliveryManFunctions.OneDeliveryMen(v).filter((itensForm) => itensForm.label !== 'Photo:' && itensForm.label !== 'ProofOfResidence:' && itensForm.label !== 'LicenseVehicle'));
                        setArchivesDeliveryMen({
                            photo: v.photo || '',
                            proof_of_residence: v.proof_of_residence || '',
                            license_vehicle: v.license_vehicle || '',
                            id: v.id,
                            status: v.acceptRegistred,
                        });
                    };
                    const openViewDeliveryMen = () => {
                        dispatch(updateHistoryRoutes([...historyRoutes, window.location.pathname]));
                        setIdScreen('viewDeliveryMen');
                        GetDataDeliveryMen();
                    };
                    const openDropDawn = () => {
                        if (idActionDropDawn) {
                            setIdActionDropDawn(null);
                            setQtdDropDawn(4);
                        } else {
                            setIdActionDropDawn(v.id);
                            setQtdDropDawn(arrayOptionsDropDawn.length);
                        }
                    };

                    // renders
                    const RenderAcceptRegistred = () => {
                        if (v.acceptRegistred === 'Pendente') {
                            return (
                                <TitleDefault padding="2px 5px 2px 5px" backgroundColor={colors.theme.backgroundStatusDeliveryMenPending} color={colors.theme.colorStatusDeliveryMen}>
                                    Pendente
                                </TitleDefault>
                            );
                        }
                        if (v.acceptRegistred === 'Validado') {
                            return (
                                <TitleDefault padding="2px 5px 2px 5px" backgroundColor={colors.theme.backgroundStatusDeliveryMenValidate} color={colors.theme.colorStatusDeliveryMen}>
                                    Validado
                                </TitleDefault>
                            );
                        }
                        if (v.acceptRegistred === 'Inativo') {
                            return (
                                <TitleDefault padding="2px 5px 2px 5px" backgroundColor={colors.theme.backgroundTabs} color={colors.basic.white}>
                                    Inativo
                                </TitleDefault>
                            );
                        }

                        return (
                            <TitleDefault padding="2px 5px 2px 5px" backgroundColor={colors.theme.redLightMedium} color={colors.theme.colorStatusDeliveryMen}>
                                Bloqueado
                            </TitleDefault>
                        );
                    };
                    const RenderAllStar = () => (
                        [1, 2, 3, 4, 5].map((star) => {
                            if (star <= v.rating) {
                                return <ImgEllipsis src={starEnabled} />;
                            }
                            return <ImgEllipsis src={starDisabled} />;
                        })
                    );
                    const RenderOptionsDropDawn = () => {
                        if (v.id === idActionDropDawn) {
                            return arrayOptionsDropDawn.map((label, index) => {
                                const onFunctionDropDawn = async () => {
                                    if (label === 'Validar entregador') {
                                        dispatch(updateHistoryRoutes(
                                            [...historyRoutes, window.location.pathname],
                                        ));
                                        setIdScreen('validationDeliveryMen');
                                        GetDataDeliveryMen();
                                    }
                                    if (label === 'Bloquear permissão de foto') {
                                        const GetInfoAllow = await
                                        deliveryMan.allowChangePhotoDeliveryMan(
                                            false,
                                            v?.id,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoAllow.message);
                                    }
                                    if (label === 'Desbloquear permissão de foto') {
                                        const GetInfoAllow = await
                                        deliveryMan.allowChangePhotoDeliveryMan(
                                            true,
                                            v?.id,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoAllow.message);
                                    }
                                    if (label === 'Restaurar') {
                                        const GetInfoFixDeliveryMen = await
                                        deliveryMan.DeleteDeliveryMan(
                                            v.id,
                                            null,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoFixDeliveryMen.message);
                                    }
                                    if (label === 'Recuperar senha') {
                                        const GetInfoRecoverPassword = await
                                        users.RecoverPasswordUsers(
                                            v.email,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoRecoverPassword.message);
                                    }
                                    if (label === 'Bloquear') {
                                        const GetInfoBlockDeliveryMen = await
                                        deliveryMan.BlockDeliveryMen(
                                            v.id,
                                            true,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoBlockDeliveryMen.message);
                                    }
                                    if (label === 'Desbloquear') {
                                        const GetInfoBlockDeliveryMen = await
                                        deliveryMan.BlockDeliveryMen(
                                            v.id,
                                            null,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoBlockDeliveryMen.message);
                                    }
                                    if (label === 'Fixar entregador') {
                                        const GetInfoFixDeliveryMen = await
                                        deliveryMan.FixDeliveryMen(
                                            infoSeller?.id,
                                            v.id, true,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoFixDeliveryMen.message);
                                    }
                                    if (label === 'Desfixar entregador') {
                                        const GetInfoFixDeliveryMen = await
                                        deliveryMan.FixDeliveryMen(
                                            infoSeller?.id,
                                            v.id, null,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoFixDeliveryMen.message);
                                    }
                                    if (label === 'Excluir') {
                                        const GetInfoFixDeliveryMen = await
                                        deliveryMan.DeleteDeliveryMan(
                                            v.id,
                                            true,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoFixDeliveryMen.message);
                                    }
                                };
                                return (
                                    <ContainerOptions>
                                        <TabOption
                                            {...itemProps[index]}
                                            onClick={onFunctionDropDawn}
                                            color={label === 'Excluir' ? colors.theme.backgroundButtonLogin : colors.theme.backgroundDrawer}
                                        >
                                            {label}
                                        </TabOption>
                                    </ContainerOptions>
                                );
                            });
                        }

                        return null;
                    };
                    return (
                        <ContainerHeader marginBottom={i === allDeliveryMen?.length - 1 ? '50px' : '0px'} padding="10px 0px 10px 0px" backgroundColor={v.fix ? colors.theme.redLightLow : colors.basic.white} justifyContent="space-between">
                            <ContainerBodyDeliveryMen
                                isOpen={v.id === idActionDropDawn}
                                onClick={openViewDeliveryMen}
                            >
                                <ContainerInfo flexDirection="row" width="16%" alignItems="center">
                                    <PhotoSeller src={v.photo || logoAuth} />
                                    <ContainerNull>
                                        <TitleDefault marginBottom="5px" color={colors.theme.textTabs}>{v.name}</TitleDefault>
                                        <TitleDefault color={colors.theme.textTabs}>
                                            {functions.MaskPhone(v.telephone)}
                                        </TitleDefault>
                                    </ContainerNull>
                                </ContainerInfo>
                                <ContainerInfo width="16%">
                                    <TitleDefault widthText="100%" color={colors.theme.textTabs}>{v.email}</TitleDefault>
                                </ContainerInfo>
                                <ContainerInfo width="16%">
                                    <TitleDefault widthText="30%" color={colors.theme.textTabs}>{v.acceptInviteTax}</TitleDefault>
                                </ContainerInfo>
                                <ContainerInfo width="16%">
                                    {RenderAcceptRegistred()}
                                </ContainerInfo>
                                <ContainerInfo width="16%" flexDirection="row">
                                    {RenderAllStar()}
                                </ContainerInfo>
                            </ContainerBodyDeliveryMen>
                            <ContainerOpenDropDawn
                                {...buttonProps}
                                isOpen={v.id === idActionDropDawn}
                                role="menu"
                                onClick={openDropDawn}
                            >
                                <ContainerImgEllipsis>
                                    <ImgEllipsis src={iconEllipsis} />
                                </ContainerImgEllipsis>
                                {RenderOptionsDropDawn()}
                            </ContainerOpenDropDawn>
                        </ContainerHeader>
                    );
                })}
            </>
        );
    }

    return null;
};

export default {
    RenderAllSellers,
};
