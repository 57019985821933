// js
import consts from '../utils/consts';
import { DataOrdersInterfaces } from '../interfaces';

// constants
const { baseUrl } = consts;

// interfaces
interface OrdersInterfaces {
    status: number,
    message: string,
}

// interface GetOrdersInterfaces extends OrdersInterfaces {
//     result: Array<DataOrdersInterfaces> | null,
// }

interface PostOrdersInterfaces extends OrdersInterfaces {
    result: null,
}

interface GetOneOrderInterface extends OrdersInterfaces {
    status: number,
    message: string,
    result: DataOrdersInterfaces | null,
}
interface OrderCreateInterface {
    nameClient: string,
    telephoneClient: string,
    cepClient: string,
    addressClient: string,
    numberClient: string,
    complementClient: string,
    referencePointClient: string,
    neighborhoodClient: string,
    observation: string,
    total: number,
    back: boolean,
    id?: number | null,
}

interface CoordinateInterface {
    lat: number,
    lng: number,
}

interface GetCoordinatesInterface extends OrdersInterfaces {
    result: CoordinateInterface | null,
}

// api
async function GetOrders(
    idOrder: '' | number,
    status: '' | 'run' | 'finished',
    period: '' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS',
    idSeller: number,
    token: string,
): Promise<any> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const validationPath = period ? `${baseUrl}/orders?idSeller=${idSeller}&period=${period}` : `${baseUrl}/orders?idSeller=${idSeller}`;
        const validationPathStatus = status ? `${validationPath}&status=${status}` : validationPath;
        const validationPathIdOrder = idOrder ? `${validationPathStatus}&idOrder=${idOrder}` : validationPathStatus;
        const response = await fetch(validationPathIdOrder, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: null,
            status: 500,
            message: '',
        };
    }
}

async function CreateOrders(
    order: OrderCreateInterface,
    idAccount: number,
    typeAccount: string,
    token: string,
): Promise<PostOrdersInterfaces> {
    try {
        const method = 'POST';
        const body = JSON.stringify(order);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            method,
            body,
            headers,
        };
        const validationPath = order?.id ? `${baseUrl}/orders?idAccount=${idAccount}&typeAccount=${typeAccount}&idOrder=${order?.id}` : `${baseUrl}/orders?idAccount=${idAccount}&typeAccount=${typeAccount}`;
        const response = await fetch(validationPath, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: null,
            status: 500,
            message: '',
        };
    }
}

async function EditStatusOrders(
    subStatus: string,
    idOrder: number,
    token: string,
): Promise<OrdersInterfaces> {
    try {
        const method = 'PUT';
        const data = {
            subStatus,
        };
        const body = JSON.stringify(data);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/orders/updateSubStatus?idOrder=${idOrder}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function GetInfoOrders(
    cpf: string,
    idSeller: number,
    token: string,
): Promise<GetOneOrderInterface> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/orders/autocomplete?cpf=${cpf}&idSeller=${idSeller}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: null,
            status: 500,
            message: '',
        };
    }
}

async function FinishRun(
    status: string,
    idOrder: number,
    token: string,
): Promise<GetOneOrderInterface> {
    try {
        const data = {
            status,
            type: 'adm', // informar que quem está cancelando o pedido é o ADM (para deletar).
        };
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/orders/updateStatus?idOrder=${idOrder}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: null,
            status: 500,
            message: '',
        };
    }
}

async function GetCoordinatesWithAddress(
    addressClient: string,
    token: string,
): Promise<GetCoordinatesInterface> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const path = `${baseUrl}/maps/addressForCoordinates?address=${addressClient}`;
        const response = await fetch(path, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: null,
            status: 500,
            message: '',
        };
    }
}

async function DeleteOneQueue(
    idOrderQueue: number,
    token: string,
): Promise<GetCoordinatesInterface> {
    try {
        const method = 'DELETE';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const path = `${baseUrl}/queueOfOrders?idOrderQueue=${idOrderQueue}`;
        const response = await fetch(path, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: null,
            status: 500,
            message: '',
        };
    }
}

async function GetDelivery(
    idOrder: number,
    idSeller: number,
    token: string,
): Promise<any> {
    try {
        const method = 'GET';
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const response = await fetch(`${baseUrl}/orders?idSeller=${idSeller}&idOrder=${idOrder}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // Alert.alert('Aviso', 'Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
    }
}

export default {
    GetInfoOrders,
    GetOrders,
    CreateOrders,
    EditStatusOrders,
    FinishRun,
    GetCoordinatesWithAddress,
    DeleteOneQueue,
    GetDelivery,
};
