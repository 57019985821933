// libraries
import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';

// js
import {
    LOGOUT,
    UPDATE_SELLER,
    UPDATE_TRANSFERABLE_BALANCE,
    UPDATE_QTD_DELIVERIES_WITH_RUN,
    UPDATE_QTD_MOTOBOY_PEDING,
    UPDATE_HISTORY_ROUTES,
    UPDATE_EXEMPTION,
} from './constants';

const initialState = {
    seller: null,
    historyRoutes: [],
    transferable_balance: 0,
    qtdDeliveriesWithRun: [],
    qtdMotoboyPending: 0,
    exemption: false,
};

const handlers = {
    [LOGOUT]: () => initialState,
    [UPDATE_SELLER]: (state = initialState, action: AnyAction) => {
        const seller = action.payload;
        return { ...state, seller };
    },
    [UPDATE_HISTORY_ROUTES]: (state = initialState, action: AnyAction) => {
        const historyRoutes = action.payload;
        return { ...state, historyRoutes };
    },
    [UPDATE_TRANSFERABLE_BALANCE]: (state = initialState, action: AnyAction) => {
        const transferable_balance = action.payload;
        return { ...state, transferable_balance };
    },
    [UPDATE_QTD_DELIVERIES_WITH_RUN]: (state = initialState, action: AnyAction) => {
        const qtdDeliveriesWithRun = action.payload;
        return { ...state, qtdDeliveriesWithRun };
    },
    [UPDATE_QTD_MOTOBOY_PEDING]: (state = initialState, action: AnyAction) => {
        const qtdMotoboyPending = action.payload;
        return { ...state, qtdMotoboyPending };
    },
    [UPDATE_EXEMPTION]: (state = initialState, action: AnyAction) => {
        const exemption = action.payload;
        return { ...state, exemption };
    },
};

export default handleActions(handlers, initialState);
