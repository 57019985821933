/* eslint-disable max-len */
// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

// js
import {
    Container,
    Home,
    ContainerInner,
    ContainerDeliveryMen,
    TitleScreen,
    ContentDeliveryMen,
    ContainerHeader,
    TitleDefault,
    ContainerBodyDeliveryMen,
    TabOptionScreen,
    TextTabOption,
    ContainerInfo,
    TabCreateEstablishment,
    ContainerBanks,
    TextBank,
    ContainerFrame,
    TitleLabel,
    Input,
} from './establishmentStyles';
import {
    DataSubSellerInterfaces,
} from '../../interfaces';
import components from './EstablishmentComponents';
import profile from '../../services/profile';
import usersConstants from '../Users/usersConstants';
import usersFunctions from '../Users/usersFunctions';
import { DataInputsInterfaces } from '../Users/usersInterfaces';
import { RootState } from '../../store';
import deliveryApi from '../../services/delivery';
import uploads from '../../services/uploads';
import allBanks from '../../utils/allBanks';

// components
import MenuComponent from '../../components/Menu/MenuComponent';
import UsersComponents from '../Users/UsersComponents';
import establishmentConstants from './establishmentConstants';
import CreateEstablishmentComponent from './components/CreateEstablishmentComponent';
import ModalDeliveryManComponent from './components/ModalDeliveryManComponent';
import sellers from '../../services/sellers';
import { updateHistoryRoutes } from '../../store/modules/seller/actions';
import ModalComponent from '../../components/Modal/ModalComponent';
import colors from '../../styles/colors';

const EstablishmentPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const infoSeller = useSelector((state: RootState) => state.seller.seller);
    const historyRoutes = useSelector((state: RootState) => state.seller.historyRoutes);

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    // const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [allSubSellers, setAllSubSellers] = useState<Array<any>>([]);
    const [idScreen, setIdScreen] = useState(true);
    const [qtdDropDawn, setQtdDropDawn] = useState(4);
    const [idActionDropDawn, setIdActionDropDawn] = useState<number | null>(null);
    const [allSellers, setAllSellers] = useState<Array<DataSubSellerInterfaces>>([]);
    const [existSellers, setExistSellers] = useState(true);
    const [existSubSellers, setExistSubSellers] = useState(true);
    const [modalDeliveryMan, setModalDeliveryMan] = useState(false);
    const [openModalBank, setOpenModalBank] = useState(false);
    const [idSellerSelect, setIdSellerSelect] = useState<number>(0);
    const [listMotoboyFixed, setListMotoboyFixed] = useState<Array<any>>([]);
    const [allBank, setAllBank] = useState<any>([]);
    const [allBankDefault, setAllBankDefault] = useState<any>([]);
    const [viewEditSubSeller, setViewEditSubSeller] = useState<string | boolean | null>(null);
    const [inputs, setInputs] = useState<DataInputsInterfaces>(usersConstants.initialInputs);
    const [inputsCreateSeller, setInputsCreateSeller] = useState<any>(null);
    const [searchBank, setSearchBank] = useState('');

    // constants
    const {
        buttonProps,
        itemProps,
    } = useDropdownMenu(qtdDropDawn);

    // methods
    const GoToSubSellers = () => {
        setIdScreen(false);
        setViewEditSubSeller(null);
    };

    const GoToSellers = () => {
        setIdScreen(true);
        setViewEditSubSeller(null);
        setInputsCreateSeller(null);
    };

    const GoToEstablishment = () => {
        setIdScreen(true);
        setViewEditSubSeller(null);
    };

    const ActionOpenModalBank = () => {
        setOpenModalBank(true);
    };

    const GetInfoScreen = async () => {
        const GetInfoSubSellers = await profile.GetInfoSeller('', '', 'sub_sellers', infoSeller?.token);
        const GetAllSellers = await profile.GetInfoSeller('', '', 'sellers', infoSeller?.token);
        if (GetInfoSubSellers.status === 200 && GetAllSellers.status === 200) {
            setAllSubSellers(GetInfoSubSellers.result || []);
            setAllSellers(GetAllSellers.result || []);
            if (!GetAllSellers.result.length) {
                setExistSellers(false);
            }
            if (!GetInfoSubSellers.result.length) {
                setExistSubSellers(false);
            }
        }
    };

    const CreateSeller = async () => {
        if (inputsCreateSeller?.address && inputsCreateSeller?.number && inputsCreateSeller?.neighborhood && inputsCreateSeller?.city && (typeof inputsCreateSeller?.photo !== 'string' ? inputsCreateSeller?.photo?.eventName?.length > 0 : inputsCreateSeller?.photo?.length > 0)) {
            const response = await deliveryApi.GetCoordinatesWithAddress(
                `${inputsCreateSeller?.address}, ${inputsCreateSeller?.number}, ${inputsCreateSeller?.city}`,
                infoSeller?.token,
            );
            const latitude = response.result?.lat || 0;
            const longitude = response.result?.lng || 0;
            const fullAddress = `${inputsCreateSeller?.address}, ${inputsCreateSeller?.number} - ${inputsCreateSeller?.neighborhood}, ${inputsCreateSeller?.city}`;
            if (inputsCreateSeller?.id && latitude !== 0 && longitude !== 0) {
                const ActionEditSeller = await sellers.EditOneSeller(
                    inputsCreateSeller || establishmentConstants.initialInputsCreateSeller,
                    '',
                    fullAddress,
                    latitude,
                    longitude,
                    infoSeller?.token,
                );
                if (ActionEditSeller.status === 200) {
                    GoToSellers();
                    GetInfoScreen();
                    // eslint-disable-next-line no-alert
                    alert(ActionEditSeller?.message);
                } else {
                    // eslint-disable-next-line no-alert
                    alert(ActionEditSeller?.message);
                }
            } else if (!inputsCreateSeller?.id && latitude !== 0 && longitude !== 0) {
                const uploadPhoto = typeof inputsCreateSeller?.photo !== 'string' && inputsCreateSeller ? await uploads.UploadFile(inputsCreateSeller.photo.eventFile) : '';
                const ActionCreateSeller = await sellers.CreateOneSeller(
                    inputsCreateSeller || establishmentConstants.initialInputsCreateSeller,
                    uploadPhoto,
                    fullAddress,
                    latitude,
                    longitude,
                    infoSeller?.token,
                );
                if (ActionCreateSeller.status === 201) {
                    GoToSellers();
                    GetInfoScreen();
                    // eslint-disable-next-line no-alert
                    alert(ActionCreateSeller?.message);
                } else {
                    // eslint-disable-next-line no-alert
                    alert(ActionCreateSeller?.message);
                }
            } else {
                // eslint-disable-next-line no-alert
                alert('Não conseguimos identificar sua localização.');
            }
        } else {
            // eslint-disable-next-line no-alert
            alert('Preencha todos os campos.');
        }
    };

    const EditSubSeller = () => {
        usersFunctions.CreateSubSeller(
            inputs,
            setInputs,
            'editSubSeller',
            setViewEditSubSeller,
            infoSeller,
            GetInfoScreen,
        );
    };

    const GoToCreateEstablishment = () => {
        setInputsCreateSeller(establishmentConstants.initialInputsCreateSeller);
    };

    const GetListMotoboy = async (refresh?: boolean) => {
        const GetAllListMotoboyFixed = await sellers.GetListMotoboyFixed(idSellerSelect, infoSeller?.token);
        if (GetAllListMotoboyFixed.status === 200) {
            setListMotoboyFixed(GetAllListMotoboyFixed.result || []);
            if (refresh) {
                setModalDeliveryMan(true);
            } else {
                setModalDeliveryMan(false);
                setIdSellerSelect(0);
            }
        }
    };

    // useEffect
    useEffect(() => {
        GetInfoScreen();
        if (historyRoutes.slice(-1)[0] === window.location.pathname) {
            // eslint-disable-next-line max-len
            const removeLastItem = historyRoutes.length === 1 ? [] : historyRoutes.slice(0, historyRoutes.length - 1);
            dispatch(updateHistoryRoutes(removeLastItem));
        }
        setInterval(() => {
            GetInfoScreen();
        }, 5000);
        const allBanksFormat = allBanks.allBanks.filter((v) => typeof v?.code === 'number' && v?.code > 0).map(
            (v) => {
                let newData = {};
                if (String(v?.code).length === 1) {
                    newData = {
                        label: `00${v?.code} - ${v?.name}`,
                        id: `00${v?.code}`,
                    };
                }
                if (String(v?.code).length === 2) {
                    newData = {
                        label: `0${v?.code} - ${v?.name}`,
                        id: `0${v?.code}`,
                    };
                }
                if (String(v?.code).length === 3) {
                    newData = {
                        label: `${v?.code} - ${v?.name}`,
                        id: String(v?.code),
                    };
                }
                return newData;
            },
        );
        setAllBank(allBanksFormat);
        setAllBankDefault(allBanksFormat);
    }, []);

    useEffect(() => {
        if (idSellerSelect) {
            GetListMotoboy(true);
        }
    }, [idSellerSelect]);

    useEffect(() => {
        if (inputsCreateSeller?.cep.length === 9) {
            // https://viacep.com.br/
            const cepFormat = inputsCreateSeller?.cep.replace('-', '');
            const url = `https://viacep.com.br/ws/${cepFormat}/json/`;
            // eslint-disable-next-line no-undef
            const ajax = new XMLHttpRequest();
            ajax.open('GET', url, true);
            ajax.send();
            ajax.onreadystatechange = () => {
                if (ajax.readyState === 4 && ajax.status === 200 && ajax.responseText) {
                    const data = JSON.parse(ajax.responseText);
                    if (data?.logradouro && data?.bairro) {
                        setInputsCreateSeller({
                            ...inputsCreateSeller,
                            address: data.logradouro,
                            neighborhood: data.bairro,
                            city: data.localidade,
                        });
                    }
                }
            };
        }
    }, [inputsCreateSeller?.cep]);

    // renders
    const RenderBodyScreen = () => {
        if (idScreen && !viewEditSubSeller) {
            return (
                <ContentDeliveryMen>
                    {allSellers.length ? (
                        <ContainerHeader padding="0px 0px 0px 0px" justifyContent="space-between">
                            <ContainerBodyDeliveryMen
                                isOpen={idActionDropDawn !== null}
                            >
                                <TitleDefault widthText="15%" marginLeft="5px">Nome do Estabelecimento</TitleDefault>
                                <TitleDefault widthText="15%" marginLeft="5px">E-mail</TitleDefault>
                                <TitleDefault widthText="15%" marginLeft="5px">Entregadores fixados</TitleDefault>
                                <TitleDefault widthText="15%" marginLeft="5px">Saldo</TitleDefault>
                            </ContainerBodyDeliveryMen>
                            <TitleDefault widthText="20px" marginRight="5px" />
                        </ContainerHeader>
                    ) : null}
                    {!allSellers.length && existSellers === false ? (
                        <TitleDefault fontWeight="bold" fontSize="15px">Sem estabelecimentos para serem exibidos.</TitleDefault>
                    ) : null}
                    {components.RenderAllSellers(
                        allSellers,
                        setAllSellers,
                        infoSeller,
                        GetInfoScreen,
                        idScreen,
                        setIdScreen,
                        buttonProps,
                        itemProps,
                        idActionDropDawn,
                        setIdActionDropDawn,
                        setQtdDropDawn,
                        setViewEditSubSeller,
                        setInputs,
                        setInputsCreateSeller,
                        setIdSellerSelect,
                        historyRoutes,
                        dispatch,
                    )}
                    <TabCreateEstablishment onClick={GoToCreateEstablishment}>
                        <TextTabOption>Cadastrar novo Estabelecimento</TextTabOption>
                    </TabCreateEstablishment>
                </ContentDeliveryMen>
            );
        }
        if (!idScreen && !viewEditSubSeller) {
            return (
                <ContentDeliveryMen>
                    {allSubSellers.length ? (
                        <ContainerHeader padding="0px 0px 0px 0px" justifyContent="space-between">
                            <ContainerBodyDeliveryMen
                                isOpen={idActionDropDawn !== null}
                            >
                                <TitleDefault widthText="20%" marginLeft="5px">Nome do Usuário</TitleDefault>
                                <TitleDefault widthText="20%" marginLeft="5px">E-mail</TitleDefault>
                                <TitleDefault widthText="20%" marginLeft="5px">Loja Responsável</TitleDefault>
                                <TitleDefault widthText="20%" marginLeft="5px">Tipo de Usuário</TitleDefault>
                            </ContainerBodyDeliveryMen>
                            <TitleDefault widthText="20px" marginRight="5px" />
                        </ContainerHeader>
                    ) : null}
                    {!allSubSellers.length && existSubSellers === false ? (
                        <TitleDefault fontWeight="bold" fontSize="15px">Sem usuários para serem exibidos.</TitleDefault>
                    ) : null}
                    {components.RenderAllSellers(
                        allSubSellers,
                        setAllSubSellers,
                        infoSeller,
                        GetInfoScreen,
                        idScreen,
                        setIdScreen,
                        buttonProps,
                        itemProps,
                        idActionDropDawn,
                        setIdActionDropDawn,
                        setQtdDropDawn,
                        setViewEditSubSeller,
                        setInputs,
                        setInputsCreateSeller,
                        setIdSellerSelect,
                        historyRoutes,
                        dispatch,
                    )}
                </ContentDeliveryMen>
            );
        }

        return null;
    };

    const RenderScreen = () => {
        if (viewEditSubSeller) {
            return UsersComponents.RenderModifySubSeller(
                inputs,
                setInputs,
                'editSubSeller',
                visibleDrawer,
                EditSubSeller,
                GoToSubSellers,
            );
        }

        if (inputsCreateSeller) {
            return CreateEstablishmentComponent(
                inputsCreateSeller,
                setInputsCreateSeller,
                visibleDrawer,
                GoToSellers,
                CreateSeller,
                allBank,
                allBankDefault,
                ActionOpenModalBank,
            );
        }

        return (
            <ContainerDeliveryMen visibleItem={visibleDrawer}>
                <TitleScreen>Estabelecimentos</TitleScreen>
                <ContainerInfo flexDirection="row" justifyContent="center">
                    <TabOptionScreen onClick={GoToEstablishment} focus={idScreen === true}>
                        <TextTabOption>Estabelecimentos</TextTabOption>
                    </TabOptionScreen>
                    <TabOptionScreen onClick={GoToSubSellers} focus={idScreen === false}>
                        <TextTabOption>Usuários</TextTabOption>
                    </TabOptionScreen>
                </ContainerInfo>
                {RenderBodyScreen()}
            </ContainerDeliveryMen>
        );
    };

    const onChangeSearching = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = txt.target;
        const removeSpaces = value?.toUpperCase()?.trim();
        const formatAllBanks = allBankDefault.filter((el: any) => el.label?.toUpperCase()?.trim().includes(removeSpaces));
        setAllBank(formatAllBanks);
        setSearchBank(value);
    };

    const RenderAllBanks = () => (
        <ContainerBanks>
            <ContainerFrame divideInput="100%">
                <TitleLabel>Procure seu banco</TitleLabel>
                <Input
                    value={searchBank}
                    color={colors.basic.gray}
                    onChange={onChangeSearching}
                    border={`1px solid ${colors.basic.gray}`}
                    placeholder="Nome ou número"
                />
            </ContainerFrame>
            {allBank.map((el: any) => {
                const SelectedBank = () => {
                    setOpenModalBank(false);
                    setSearchBank('');
                    setAllBank(allBankDefault);
                    setInputsCreateSeller({
                        ...inputsCreateSeller,
                        bank_number: el?.id,
                    });
                };
                if (el?.id) {
                    return <TextBank onClick={SelectedBank}>{el?.label}</TextBank>;
                }
                return null;
            })}
        </ContainerBanks>
    );

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent
                        visibleDrawer={visibleDrawer}
                        setVisibleDrawer={setVisibleDrawer}
                    />
                    {RenderScreen()}
                </ContainerInner>
                <ModalComponent
                    modalIsOpen={openModalBank}
                    setModalIsOpen={setOpenModalBank}
                    RenderBodyModal={RenderAllBanks}
                />
                {ModalDeliveryManComponent(modalDeliveryMan, listMotoboyFixed, infoSeller, GetListMotoboy, setModalDeliveryMan, setIdSellerSelect)}
            </Home>
        </Container>
    );
};

export default EstablishmentPage;
