/* eslint-disable max-len */
// libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    LineSeries,
    makeWidthFlexible,
} from 'react-vis';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

// js
import auth from '../../services/auth';
import dashboard from '../../services/dashboard';
import constants from './homeConstants';
import colors from '../../styles/colors';
import components from './HomeComponents';
import functions from '../../utils/functions';
import {
    Home,
    Container,
    ContainerInner,
    ContainerDashboard,
    TitleScreen,
    ContentCards,
    ContainerAdjustSpace,
    ContainerInfo,
    ContainerChart,
    ContainerHeaderCart,
    TitleDefault,
    CirclePurple,
    ContainerShadow,
    ContainerFilters,
    TextDefault,
    ContainerBoxInfo,
    ContainerSelect,
    Option,
    ContainerHeaderDashboard,
    ContainerGraph,
    BarGraph,
    ContainerAllChart,
} from './homeStyles';
import { RootState } from '../../store';
import { updateSeller } from '../../store/modules/seller/actions';
import { DataResultInfoDashboard, DataSubSellerInterfaces } from '../../interfaces';
import profile from '../../services/profile';

// components
import MenuComponent from '../../components/Menu/MenuComponent';
import ModalComponent from '../../components/Modal/ModalComponent';

const HomePage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const infoSeller = useSelector((state: RootState) => state.seller.seller);

    // constants
    const initialWidth = isMobile ? window.innerWidth / 1.4 : window.innerWidth / 1.8;
    const FlexibleXYPlot = makeWidthFlexible(XYPlot);
    const initialInfoDashboard: DataResultInfoDashboard = {
        indicators: [],
        medias: [],
        performance: {},
        lastDeliveries: [],
        newMotoboys: { graph: [] },
    };

    // states
    const [allInfoDashboard, setAllInfoDashboard] = useState<
    DataResultInfoDashboard>(initialInfoDashboard);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [widthActual, setWidthActual] = useState(initialWidth);
    const [allSellers, setAllSellers] = useState<Array<DataSubSellerInterfaces>>([]);
    const [sellerSelect, setSellerSelect] = useState(`${0},TODOS`);
    const [refreshDashboard, setRefreshDashboard] = useState('');
    const [companySelect, setCompanySelect] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [typeModal, setTypeModal] = useState(0);
    const [formDelivery, setFormDelivery] = useState(constants.formDelivery);

    // functions for use effect
    const RefreshTokenAction = async () => {
        const refresh = await auth.RefreshToken(infoSeller?.tokenRefresh || '', infoSeller?.typeAccount || '');
        const newFormatInfoSeller = {
            ...infoSeller,
            token: refresh?.result?.token,
        };
        dispatch(updateSeller(newFormatInfoSeller));
        const GetAllSellers = await profile.GetInfoSeller('', '', 'sellers', infoSeller?.token);
        if (GetAllSellers.status === 200) {
            setAllSellers(GetAllSellers.result);
        }
    };

    const InitialFuncScreen = async () => {
        const GetInfoDashboard = await dashboard.GetInfoDashboard(
            infoSeller?.id,
            infoSeller?.typeAccount,
            sellerSelect.split(',')[0] === '0' ? '' : sellerSelect.split(',')[0],
            infoSeller?.token,
        );
        setAllInfoDashboard(GetInfoDashboard?.result || initialInfoDashboard);
    };

    const onChangeSellerLinkDeliveryMen = async (
        txt: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const FormatSplit = txt.target.value.split(',');
        setSellerSelect(txt.target.value);
        const GetInfoDashboard = await dashboard.GetInfoDashboard(
            infoSeller?.id,
            infoSeller?.typeAccount,
            FormatSplit[0] === '0' ? '' : FormatSplit[0],
            infoSeller?.token,
        );
        setAllInfoDashboard(GetInfoDashboard?.result || initialInfoDashboard);
    };

    const onChangeExportSelect = async (
        txt: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const formatValue = Number(txt.target.value);
        if (formatValue === 1) {
            setModalIsOpen(true);
            setTypeModal(1);
        }
        if (formatValue === 2) {
            setModalIsOpen(true);
            setTypeModal(2);
        }
    };

    // useEffect
    useEffect(() => {
        setTimeout(() => {
            RefreshTokenAction();
            InitialFuncScreen();
        }, 500);
        setInterval(() => {
            const newUuid = uuidv4();
            setRefreshDashboard(newUuid);
        }, 5000);
    }, []);

    useEffect(() => {
        if (refreshDashboard) {
            InitialFuncScreen();
        }
    }, [refreshDashboard]);

    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            if (!isMobile) {
                if (window.innerWidth <= 1200) {
                    setWidthActual(window.innerWidth / 1.4);
                } else {
                    setWidthActual(window.innerWidth / 1.8);
                }
            }
        }, true);

        return adjustSize;
    }, []);

    // renders
    const RenderComponentLastDelivery = () => {
        if (allInfoDashboard.lastDeliveries.length) {
            return (
                <>
                    <TitleDefault fontWeight="bold" fontSize="24px" marginTop="20px" marginBottom="20px">Últimas Entregas</TitleDefault>
                    <ContainerShadow>
                        <ContainerFilters height="50px" backgroundColor={colors.theme.backgroundContainerInfoTabs} borderBottom="2px solid rgba(0, 0, 0, 0.2)">
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Nome do Cliente</TextDefault>
                            {infoSeller?.typeAccount === 'business' ? (
                                <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Estabelecimento</TextDefault>
                            ) : null}
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Bairro de Entrega</TextDefault>
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Entregador</TextDefault>
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Data</TextDefault>
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Horário</TextDefault>
                        </ContainerFilters>
                        {components.RenderAllLastDelivery(allInfoDashboard.lastDeliveries, infoSeller)}
                    </ContainerShadow>
                </>
            );
        }

        return null;
    };

    const RenderTickFormatY = (v: string) => {
        if (!allInfoDashboard.performance.lineChart?.filter((value) => value.y !== 0).length) {
            return '0';
        }
        return v;
    };

    const RenderNewsMotoboys = () => {
        if (infoSeller?.typeAccount === 'business') {
            return (
                <ContainerBoxInfo>
                    <ContainerInfo
                        width="100%"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <ContainerInfo>
                            <TitleDefault
                                color={colors.basic.white}
                                fontSize="16px"
                                fontWeight="bold"
                                marginBottom="10px"
                            >
                                Novos Entregadores
                            </TitleDefault>
                            <TitleDefault color={colors.basic.white}>
                                {allInfoDashboard.newMotoboys.label || ''}
                            </TitleDefault>
                        </ContainerInfo>
                        <TitleDefault
                            color={colors.basic.white}
                            fontSize="18px"
                            fontWeight="bold"
                        >
                            {`+ ${allInfoDashboard.newMotoboys.qtd || ''}`}
                        </TitleDefault>
                    </ContainerInfo>
                    <ContainerGraph>
                        {allInfoDashboard.newMotoboys.graph.map((v: any) => (
                            <BarGraph
                                percentage={!Number(v) ? 1 : (Number(v) * 100) / Math.max(...allInfoDashboard.newMotoboys.graph)}
                                maxNumber={100}
                            />
                        ))}
                    </ContainerGraph>
                </ContainerBoxInfo>
            );
        }

        return null;
    };

    const RenderPickerAllSellers = () => {
        if (infoSeller?.typeAccount === 'business') {
            return (
                <ContainerSelect
                    value={sellerSelect}
                    onChange={onChangeSellerLinkDeliveryMen}
                    width="200px"
                >
                    <Option value={`${0},TODOS`}>TODOS</Option>
                    {allSellers.map((allInfoSeller) => (
                        <Option value={`${allInfoSeller.id},${allInfoSeller.name}`}>{allInfoSeller.name}</Option>
                    ))}
                </ContainerSelect>
            );
        }

        return null;
    };

    const RenderScreen = () => (
        <ContainerDashboard visibleItem={visibleDrawer}>
            <ContainerHeaderDashboard>
                <ContainerHeaderCart marginBottom="0px">
                    <TitleScreen>Dashboard</TitleScreen>
                    {RenderPickerAllSellers()}
                </ContainerHeaderCart>
                <ContainerSelect
                    value=""
                    onChange={onChangeExportSelect}
                    width="200px"
                >
                    <Option value={0}>EXPORTAR</Option>
                    <Option value={1}>Entregas</Option>
                    <Option value={2}>Saldo</Option>
                </ContainerSelect>
            </ContainerHeaderDashboard>
            <ContentCards>
                {components.RenderAllCards(allInfoDashboard?.indicators)}
            </ContentCards>
            <ContainerAdjustSpace>
                <ContainerAllChart>
                    <ContainerChart>
                        <ContainerHeaderCart>
                            <ContainerInfo width="69%" marginRight="10px">
                                <TitleDefault fontWeight="bold" fontSize="16px" marginBottom="10px">
                                    {allInfoDashboard?.performance?.title || ''}
                                </TitleDefault>
                                <TitleDefault color={colors.basic.grayUltra}>
                                    {allInfoDashboard?.performance?.period || ''}
                                </TitleDefault>
                            </ContainerInfo>
                            <ContainerInfo>
                                <ContainerInfo flexDirection="row" alignItems="center">
                                    <CirclePurple />
                                    <TitleDefault color={colors.basic.grayUltra} fontSize="16px" marginLeft="5px">
                                        {allInfoDashboard?.performance?.subtitle || ''}
                                    </TitleDefault>
                                </ContainerInfo>
                                <TitleDefault fontWeight="bold" fontSize="16px" marginTop="10px">
                                    {allInfoDashboard?.performance?.qtd || '0'}
                                </TitleDefault>
                            </ContainerInfo>
                        </ContainerHeaderCart>
                        <FlexibleXYPlot
                            height={300}
                            margin={constants.marginXYPlot}
                        >
                            <HorizontalGridLines />
                            <VerticalGridLines
                                style={constants.stylesGridVertical}
                                tickTotal={constants.lineChart.length}
                            />
                            <XAxis
                                style={constants.stylesXAxis(widthActual, isMobile)}
                                tickFormat={functions.GlobalRenderTickFormatX}
                                tickSize={0}
                                hideLine
                            />
                            <YAxis
                                style={constants.stylesXAxis(widthActual, isMobile)}
                                tickFormat={RenderTickFormatY}
                                tickValues={!allInfoDashboard.performance.lineChart?.filter((value) => value.y !== 0).length ? [0] : undefined}
                                top={!allInfoDashboard.performance.lineChart?.filter((value) => value.y !== 0).length ? 260 : 5}
                                tickSize={0}
                                hideLine
                            />
                            {allInfoDashboard.performance.lineChart?.filter((v) => v.y !== 0).length ? (
                                <LineSeries
                                    data={allInfoDashboard.performance.lineChart}
                                    stroke={colors.basic.purple}
                                    style={constants.stylesLineSeries}
                                    curve="curveMonotoneX"
                                />
                            ) : (
                                <LineSeries
                                    data={allInfoDashboard.performance.lineChart}
                                    stroke={colors.basic.purple}
                                    style={constants.stylesLineSeries}
                                    curve="curveMonotoneX"
                                    yDomain={[0, 1]}
                                />
                            )}
                        </FlexibleXYPlot>
                    </ContainerChart>
                    {RenderComponentLastDelivery()}
                </ContainerAllChart>
                <ContainerAllChart width="25%">
                    {RenderNewsMotoboys()}
                    {components.RenderAllMediaGeneral(allInfoDashboard.medias)}
                </ContainerAllChart>
            </ContainerAdjustSpace>
        </ContainerDashboard>
    );

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent
                        visibleDrawer={visibleDrawer}
                        setVisibleDrawer={setVisibleDrawer}
                    />
                    {RenderScreen()}
                </ContainerInner>
                <ModalComponent
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    RenderBodyModal={() => components.RenderBodyModal(typeModal, companySelect, setCompanySelect, allSellers, infoSeller, formDelivery, setFormDelivery, setModalIsOpen)}
                />
            </Home>
        </Container>
    );
};

export default HomePage;
