// js
import seller from './seller';

export const actions = {
    seller: seller.actions,
};

export const reducers = {
    seller: seller.reducer,
};
