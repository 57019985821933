const isDevelopment = window.location.hostname.includes('development') || window.location.hostname.includes('localhost') ? 'development' : '';
const isStaging = window.location.hostname.includes('staging') ? 'staging' : '';
const isBeta = window.location.hostname.includes('adm.deixoja.com');

const ValidationBaseUrl = (): string => {
    if (isBeta) {
        return 'https://api.deixoja.com';
    }
    const environment = isDevelopment || isStaging;
    return `https://${environment}.deliveryapi.dotcoding.com.br`;
};

const baseUrl = ValidationBaseUrl();

const admPass = '245BVECRP64352RUOMAKsVcCUZu0bmXkrxmLoqGZfmBui4fIYTxb88JLEcxUtp0';

const fontFamily = '"Inter"';

const fontSize = {
    default: 22,
};

const getFontWeight = (family: 'Inter-Thin' | 'Inter-Light' | 'Inter-Regular' | 'Inter-Bold' | 'Inter-Black'): number => {
    if (family === 'Inter-Thin') {
        return 100;
    }
    if (family === 'Inter-Light') {
        return 300;
    }
    if (family === 'Inter-Regular') {
        return 400;
    }
    if (family === 'Inter-Bold') {
        return 700;
    }
    if (family === 'Inter-Black') {
        return 900;
    }
    return 0;
};

export default {
    admPass,
    baseUrl,
    fontFamily,
    fontSize,
    getFontWeight,
};
