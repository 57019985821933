// js
import reducer from './reducers';
import {
    logoutAuth,
    updateSeller,
    updateQtdDeliveriesWithRun,
    updateQtdMotoboyPeding,
    updateTransferableBalance,
    updateHistoryRoutes,
    updateExemption,
} from './actions';

// constants
const actions = {
    logoutAuth,
    updateSeller,
    updateQtdDeliveriesWithRun,
    updateQtdMotoboyPeding,
    updateTransferableBalance,
    updateHistoryRoutes,
    updateExemption,
};

export default { actions, reducer };
