// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface MenuInterface {
    currentLocation?: boolean,
    visibleItem?: boolean,
}

interface ContainerHeaderScreenInterfaces {
    widthScreen?: string,
    visibleItem?: boolean,
}

interface ContainerOpenDropDawnInterfaces {
    visibleItem?: boolean,
    isOpen?: boolean,
    typeAccount?: string,
}

interface LogoInterfaces {
    cursorActive?: boolean,
}

interface MyMoneyInterfaces {
    fontWeight?: string,
}

interface IconDrawerOptionInterfaces {
    height?: string,
    width?: string,
}

// styles - menu
export const Header = styled.div<MenuInterface>`
    align-items: center;
    background-color: ${colors.theme.backgroundDrawer};
    justify-items: center;
    display: flex;
    flex-direction: column;
    transition-duration: 400ms;
    position: absolute;
    z-index: 1;
    width: ${(props) => (props.visibleItem ? '120px' : '50px')};
    height: 100%;
`;

export const DrawerOption = styled.text`
    font-size: 12px;
    margin-top: 5px;
`;

export const IconDrawerOption = styled.img<IconDrawerOptionInterfaces>`
    object-fit: contain;
    height: 20px;
    margin-right: 5px;
    width: ${(props) => (props.width || '30px')};
`;

export const ContainerDrawerOption = styled.div`
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
`;

export const ContainerRansom = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 10px;
    margin-right: 5px;
`;

export const MoneyIcon = styled.img`
    height: 34px;
    object-fit: contain;
    width: 34px;
`;

export const MyMoney = styled.div<MyMoneyInterfaces>`
    color: ${colors.basic.black};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
`;

export const ContainerHeaderScreen = styled.div<ContainerHeaderScreenInterfaces>`
    background-color: ${colors.theme.backgroundHeaderScreenApp};
    display: flex;
    justify-content: flex-end;
    padding-right: ${(props) => (props.visibleItem ? '70px' : '80px')};
    transition-duration: 400ms;
    height: 60px;
    width: ${(props) => (props.widthScreen || '100%')};
`;

export const ContainerInfoSeller = styled.div<MenuInterface>`
    align-items: center;
    display: flex;
    justify-content: space-between;
    transition-duration: 400ms;
    margin-left: 10px;
    padding-left: ${(props) => (props.visibleItem ? '130px' : '60px')};
    min-width: 100%;
`;

export const ContainerFlex = styled.div`
    display: flex;
`;

export const ContainerMenu = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    min-height: 100%;
    /* max-width: 100%;
    min-width: 100%; */
`;

export const AdjustSpace = styled.div`
    height: 30px;
`;

export const Home = styled.div<MenuInterface>`
    display: ${(props) => (props.visibleItem ? 'inline' : 'none')};
    width: 120px;
`;

export const BoxHeader = styled.div`
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.theme.backgroundButtonLogin};
    display: flex;
    height: 70px;
    margin-bottom: 30px;
    padding: 0px 5px 0px 5px;
    width: 100%;
`;

export const TabOption = styled.a<ContainerOpenDropDawnInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    cursor: pointer;
    display: ${(props) => (props.isOpen ? 'none' : 'flex')};
    font-weight: normal;
    font-size: 12px;
`;

export const NumberNotification = styled.text`
    font-size: 10px;
    background-color: ${colors.theme.backgroundButtonLogin};
    color: ${colors.basic.white};
    border-radius: 50%;
    font-weight: bold;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContainerInfoTabDrawer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Separator = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

export const HamburgerOpen = styled.div<MenuInterface>`
    display: none;
    z-index: 4;
    background-color: green;
    cursor: pointer;
`;

export const OpenIcon = styled.img`
    cursor: pointer;
    width: 30px;
    height: 30px;
    object-fit: contain;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
`;

export const GoActionDrawer = styled.img`
    cursor: pointer;
    height: 30px;
    width: 30px;
`;

export const LogoHeader = styled.img<LogoInterfaces>`
    cursor: ${(props) => (props.cursorActive ? 'pointer' : 'default')};
    border-radius: 25px;
    background-color: ${colors.theme.backgroundLogoDefault};
    height: 40px;
    width: 40px;
`;

export const Logo = styled.img<LogoInterfaces>`
    cursor: ${(props) => (props.cursorActive ? 'pointer' : 'default')};
    height: 52px;
    width: 60px;
`;

export const ContainerOpenDropDawn = styled.button<ContainerOpenDropDawnInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.isOpen ? 'transparent' : colors.basic.white)};
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    border: none;
    height: ${(props) => (props.isOpen ? '0px' : `${props.typeAccount === 'sellers' ? '100px' : '75px'}`)};
    padding: 10px;
    margin-top: 10px;
    transition-duration: 400ms;
    position: absolute;
    z-index: 100;
    top: ${(props) => (props.isOpen ? '10px' : '60px')};
    right: 90px;
    box-shadow: ${(props) => (props.isOpen ? 'none' : '0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2)')};
    width: ${(props) => (props.isOpen ? '20px' : '180px')};
`;

export const IconOptions = styled.img<ContainerOpenDropDawnInterfaces>`
    object-fit: contain;
    margin-right: 20px;
    display: ${(props) => (props.isOpen ? 'none' : 'flex')};
    height: 24px;
    width: 24px;
`;

export const ContainerOptions = styled.div`
    align-items: center;
    display: flex;
    width: 100%;
`;

export const AdjustIcon = styled.div`
    align-items: center;
    display: flex;
`;
