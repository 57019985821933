// libraries
import React from 'react';

// js
import {
    ContainerHeader,
    TextDefault,
    ContainerFrame,
    TitleLabel,
    Input,
} from './components/DeliveryInProgress/deliveryInProgressStyles';
import { ContainerModalEvaluation, Star, ContainerAllStar } from './components/HistoryDelivery/historyDeliveryStyles';
import { starDisabled, starEnabled } from '../../assets';
import sellers from '../../services/sellers';

// components
import ButtonAuth from '../Auth/components/Button';

// interfaces
import { DataOrdersInterfaces, DataSellerInterface } from '../../interfaces';
import delivery from '../../services/delivery';

const RenderBodyModal = (
    oneOrder: DataOrdersInterfaces | null,
): React.ReactElement | null => {
    // methods
    function RenderInput(label: string, value?: string) {
        return (
            <ContainerFrame>
                <TitleLabel>{label}</TitleLabel>
                <Input
                    value={value}
                    disabled
                />
            </ContainerFrame>
        );
    }

    return (
        <>
            <ContainerHeader spaccing={40}>
                <TextDefault fontSize="18px" fontWeight="bold">DETALHES DA ENTREGA</TextDefault>
                <TextDefault fontSize="18px" fontWeight="bold">{`NÚMERO DO PEDIDO: #${oneOrder?.id}`}</TextDefault>
            </ContainerHeader>
            {RenderInput('Nome do cliente:', oneOrder?.name_client)}
            {RenderInput('Telefone do cliente:', oneOrder?.telephone_client)}
            {RenderInput('Haverá retorno:', oneOrder?.back ? 'Sim' : 'Não')}
            {RenderInput('Endereço de entrega:', oneOrder?.address_client)}
            {RenderInput('Observações:', oneOrder?.observation)}
        </>
    );
};

const RenderEvaluationBodyModal = (
    idOrderMain: number,
    setExistQueue: (v: boolean) => void,
    oneOrderHistory: Array<DataOrdersInterfaces>,
    setOneOrderHistory: (v: Array<DataOrdersInterfaces>) => void,
    starSelected: number,
    setStarSelected: (v: number) => void,
    infoSeller: DataSellerInterface,
    setOpenModalEvaluation: (v: boolean) => void,
): React.ReactElement | null => {
    // constants
    const allStars = [1, 2, 3, 4, 5];

    // methods
    const SendEvaluation = async () => {
        if (starSelected) {
            const ActionAvaliation = await sellers.AvaliationMotoboy(
                starSelected,
                oneOrderHistory[0]?.id_user || 0,
                oneOrderHistory[0]?.id,
                infoSeller?.token,
            );
            if (ActionAvaliation?.status === 200) {
                const GetInfoDelivery = await delivery.GetDelivery(
                    idOrderMain,
                    infoSeller?.id,
                    infoSeller?.token,
                );
                setOneOrderHistory(GetInfoDelivery?.result);
                setExistQueue(true);
                setStarSelected(0);
            }
            setOpenModalEvaluation(false);
            // eslint-disable-next-line no-alert
            alert(ActionAvaliation?.message);
        } else {
            // eslint-disable-next-line no-alert
            alert('Selecione o número de estrelas para avaliar este pedido.');
        }
    };

    // renders
    const RenderStars = () => (
        allStars.map((v) => {
            const ActiveStar = () => {
                if (starSelected === v) {
                    setStarSelected(0);
                } else {
                    setStarSelected(v);
                }
            };
            return (
                <Star onClick={ActiveStar} src={v <= starSelected ? starEnabled : starDisabled} />
            );
        })
    );

    return (
        <ContainerModalEvaluation>
            <ContainerAllStar>
                {RenderStars()}
            </ContainerAllStar>
            <ButtonAuth onClick={SendEvaluation} label="ENVIAR AVALIAÇÃO" colorButton={null} colorText={null} />
        </ContainerModalEvaluation>
    );
};

export default {
    RenderBodyModal,
    RenderEvaluationBodyModal,
};
