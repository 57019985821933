// libraries
import React from 'react';
import Modal from 'react-modal';
import { isMobile } from 'react-device-detect';

// js
import colors from '../../styles/colors';

// interfaces
interface ModalComponentInterfaces {
    modalIsOpen: boolean,
    setModalIsOpen: (v: boolean) => void,
    RenderBodyModal: any,
}

const ModalComponent: React.FC<ModalComponentInterfaces> = (props: ModalComponentInterfaces) => {
    // constants
    const {
        modalIsOpen,
        setModalIsOpen,
        RenderBodyModal,
    } = props;

    // styles - modal
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            minWidth: isMobile ? '90vw' : '40vw',
            maxHeight: '80%',
        },
        overlay: {
            backgroundColor: colors.theme.backgroundModal,
        },
    };

    // methods
    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={{ content: { ...customStyles.content, overflowY: 'auto' }, overlay: customStyles.overlay }}
        >
            {RenderBodyModal()}
        </Modal>
    );
};

export default ModalComponent;
