// js
import consts from '../utils/consts';
import { DataSellerInterface } from '../interfaces';

// constants
const { baseUrl } = consts;
const headerApplicationJson = {
    'Content-Type': 'application/json',
};

// interfaces
interface AuthInterfaces {
    status: number,
    result: { account: DataSellerInterface | null, token: string, tokenRefresh: string };
    message: string,
}

// api
async function Login(identifier: string, password: string): Promise<AuthInterfaces> {
    try {
        const data = {
            identifier,
            password,
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/sellers/token`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { account: null, token: '', tokenRefresh: '' },
            status: 500,
            message: '',
        };
    }
}

async function RecoverPassword(identifier: string): Promise<AuthInterfaces> {
    try {
        const data = {
            identifier,
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/sellers/recoverPassword`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { account: null, token: '', tokenRefresh: '' },
            status: 500,
            message: '',
        };
    }
}

async function RegisterSeller(
    name: string,
    telephone: string,
    email: string,
): Promise<AuthInterfaces> {
    try {
        const data = {
            name,
            telephone,
            email,
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/sellers/requestRegistration`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { account: null, token: '', tokenRefresh: '' },
            status: 500,
            message: '',
        };
    }
}

async function RefreshToken(
    refresh: string,
    typeAccount: string,
): Promise<{ result: { token: string } }> {
    try {
        const data = {
            refresh,
            typeAccount,
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/sellers/refreshToken`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { token: '' },
        };
    }
}

export default {
    Login,
    RefreshToken,
    RecoverPassword,
    RegisterSeller,
};
