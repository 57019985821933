/* eslint-disable max-len */
// libraries
import React, { useEffect } from 'react';
import {
    Switch,
    Route,
    useHistory,
    Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';

// pages
import LoginPage from '../pages/Auth/screens/Login/LoginPage';
import RecoverPasswordPage from '../pages/Auth/screens/RecoverPassword/RecoverPasswordPage';
import RegisterPage from '../pages/Auth/screens/Register/RegisterPage';
import HomePage from '../pages/Home/HomePage';
import ProfilePage from '../pages/Profile/ProfilePage';
import DeliveryPage from '../pages/Delivery/DeliveryPage';
import UsersPage from '../pages/Users/UsersPage';
import DeliveryManPage from '../pages/DeliveryMan/DeliveryManPage';
import FinancePage from '../pages/Finance/FinancePage';
import EstablishmentPage from '../pages/Establishment/EstablishmentPage';

// js
import { RootState } from '../store';
import consts from '../utils/consts';
import { DataOrdersInterfaces } from '../interfaces';
import {
    updateExemption,
    updateQtdDeliveriesWithRun,
    updateQtdMotoboyPeding,
    updateTransferableBalance,
} from '../store/modules/seller/actions';

const Routes: React.FC = () => {
    // useSelector
    const infoSeller = useSelector((state: RootState) => state.seller.seller);

    // dispatch
    const dispatch = useDispatch();

    // constants
    const history = useHistory();
    const validationNotExistUser = !window.location.pathname.includes('/recoverPassword') && !window.location.pathname.includes('/registerUser') && !infoSeller?.id;
    const validationExistUser = (window.location.pathname.includes('/recoverPassword') || window.location.pathname.includes('/registerUser') || window.location.pathname === '/') && infoSeller?.id;

    // conditions
    if (validationNotExistUser) {
        // console.log('validationNotExistUser');
        history.push('/');
    }

    if (validationExistUser) {
        if (infoSeller?.typeAccount === 'sub_sellers' && infoSeller?.type === 'operator') {
            // console.log('/delivery');
            history.push('/delivery');
        } else {
            // console.log('/dashboard');
            history.push('/dashboard');
        }
    }

    if (!window.location.pathname.includes('/delivery')) {
        const { baseUrl } = consts;
        const socket = io(baseUrl);
        socket.disconnect();
    }

    // rota para redirecionar o usuário quando digitar uma rota não encontrada.
    const pathNotFound = () => {
        if (infoSeller) {
            if (infoSeller?.typeAccount === 'sub_sellers' && infoSeller?.type === 'operator') {
                // console.log('2 - /delivery');
                return '/delivery';
            }
            // console.log('2 - /dashboard');
            return '/dashboard';
        }
        // console.log('3 - /');
        return '/';
    };
    const RouteNotFound = () => {
        if (infoSeller) {
            if (infoSeller?.typeAccount === 'sub_sellers' && infoSeller?.type === 'operator') {
                return DeliveryPage;
            }
            return HomePage;
        }
        return LoginPage;
    };

    // useEffect
    useEffect(() => {
        const socket = io(consts.baseUrl);
        if (!infoSeller?.id) {
            socket.disconnect();
        }
        socket.on('details', (details) => {
            dispatch(updateQtdMotoboyPeding(details?.qtdMotoboyPending));
            if (infoSeller?.typeAccount === 'sellers') {
                const GetBalanceOnlySeller = details.sellers.find((v: any) => v.id === infoSeller.id);
                dispatch(updateTransferableBalance(GetBalanceOnlySeller?.transferable_balance));
                dispatch(updateExemption(GetBalanceOnlySeller?.exemption));
            } else if (infoSeller?.typeAccount === 'business') {
                dispatch(updateTransferableBalance(details?.business?.transferable_balance));
            }
        });
        socket.on('validateOrder', (validateOrder) => {
            const GetOrder = (validateOrder?.orders || []).filter(
                (v: DataOrdersInterfaces) => Number(v?.id_seller) === Number(infoSeller?.id),
            );
            dispatch(updateQtdDeliveriesWithRun(GetOrder));
        });

        return () => {
            socket.disconnect();
        };
    }, [infoSeller?.id]);

    return (
        <Switch>
            {!infoSeller?.id ? (
                <>
                    <Route path="/" exact component={LoginPage} />
                    <Route path="/recoverPassword" component={RecoverPasswordPage} />
                    <Route path="/registerUser" component={RegisterPage} />
                </>
            ) : (
                <>
                    <Route path="/dashboard" component={HomePage} />
                    <Route path="/profileSeller" component={ProfilePage} />
                    <Route path="/delivery" component={DeliveryPage} />
                    <Route path="/users" component={UsersPage} />
                    <Route path="/deliverymen" component={DeliveryManPage} />
                    <Route path="/finance" component={FinancePage} />
                    <Route path="/establishment" component={EstablishmentPage} />
                </>
            )}
            <Route path={pathNotFound()} component={RouteNotFound()} />
            <Redirect to={pathNotFound()} />
        </Switch>
    );
};

export default Routes;
