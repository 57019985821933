// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// js
import { ImageGoBack } from './goBackStyles';
import { arrow } from '../../assets/index';
import { RootState } from '../../store';
import ToNavigation from '../../routes/navigation';
import { updateHistoryRoutes } from '../../store/modules/seller/actions';

const GoBackComponent: React.FC = () => {
    // useSelector
    const historyRoutes = useSelector((state: RootState) => state.seller.historyRoutes);

    // dispatch
    const dispatch = useDispatch();

    // states
    const [redirect, setRedirect] = useState(false);
    const [pathRoute, setPathRoute] = useState('');

    // useEffect
    useEffect(() => {
        if (redirect) {
            // eslint-disable-next-line max-len
            const removeLastItem = historyRoutes.length === 1 ? [] : historyRoutes.slice(0, historyRoutes.length - 1);
            dispatch(updateHistoryRoutes(removeLastItem));
        }
    }, [redirect]);

    // methods
    const ActionGoBack = () => {
        // eslint-disable-next-line max-len
        const lastHistoryActual = historyRoutes?.length === 1 ? historyRoutes[0] : historyRoutes.slice(-1)[0];
        if (lastHistoryActual === window.location.pathname) {
            window.location.reload();
            // eslint-disable-next-line max-len
            const removeLastItem = historyRoutes.length === 1 ? [] : historyRoutes.slice(0, historyRoutes.length - 1);
            dispatch(updateHistoryRoutes(removeLastItem));
        } else {
            setRedirect(true);
            setPathRoute(lastHistoryActual);
        }
    };
    return (
        <>
            <ToNavigation redirect={redirect} pathRoute={pathRoute} />
            <ImageGoBack src={arrow} onClick={ActionGoBack} />
        </>
    );
};

export default GoBackComponent;
