// libraries
import styled from 'styled-components';

// js
import colors from '../../../../styles/colors';

// interfaces
interface LabelInterface {
    textAlign?: string,
}

interface TextDefaultInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
}
interface ContainerInterfaces {
    height?: string,
    padding?: string,
    backgroundColor?: string,
}
interface ContainerFiltersInterfaces {
    backgroundColor?: string,
    borderBottom?: string,
    borderRadius?: string,
    marginBottom?: string,
    height?: string,
    width?: string,
    cursorActive?: boolean,
}

interface TitleHeaderInterfaces {
    justifyContent?: string,
}
interface ContainerSellerInterfaces {
    justifyContent?: string,
    width?: string,
}
interface ButtonStatusInterfaces {
    backgroundColor?: string,
}

interface ContainerEmptyInterfaces {
    alignSelf?: string,
    marginBottom?: string,
    width?: string,
}

interface ContainerHeaderInterface {
    spaccing?: number,
}

interface ButtonInterfaces {
    tradeBackground?: boolean,
    marginLeft?: string,
}

interface ContainerFrameInterfaces {
    justifyContent?: string,
}

export const TextDefault = styled.text<TextDefaultInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    font-size: ${(props) => (props.fontSize || '14px')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    text-align: center;
    min-width: 16%;
    @media (max-width: 1200px) {
        font-size: 8px;
    }
    @media (max-width: 500px) {
        font-size: 6px;
    }
`;

export const TextHeaderInProgress = styled.div`
    color: ${colors.basic.black};
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;

export const Container = styled.div<ContainerInterfaces>`
    display: flex;
    flex-direction: column;
    width: 49%;
    height: ${(props) => (props.height || 'auto')};
    background-color: ${(props) => (props.backgroundColor || colors.theme.backgroundBoxCreateSeller)};
    padding: ${(props) => (props.padding || '0px 10px 10px 10px')};
    /* height: 60%;
    overflow-y: scroll; */
`;

export const ContainerQueue = styled.div`
    width: 100%;
`;

export const ContainerBodyQueue = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
`;

export const TitleHeader = styled.div<TitleHeaderInterfaces>`
    display: flex;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    color: ${(props) => (props.color || colors.basic.black)};
    width: 19%;
`;

export const HeaderQueue = styled.text`
    color: ${colors.basic.black};
    font-size: 18px;
    font-weight: bold;
`;

export const ContainerChecked = styled.div`
    align-items: center;
    display: flex;
    border-radius: 15px;
    cursor: pointer;
    background-color: ${colors.basic.white};
    height: 30px;
    justify-content: center;
    width: 30px;
`;

export const Checked = styled.div`
    background-color: ${(props) => (props.color || colors.basic.red)};
    border-radius: 10px;
    height: 20px;
    width: 20px;
`;

export const ContainerFilters = styled.div<ContainerFiltersInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    display: flex;
    cursor: ${(props) => (props.cursorActive ? 'pointer' : 'default')};
    justify-content: space-between;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    border-radius: ${(props) => (props.borderRadius || '5px 5px 0px 0px')};
    border-bottom: ${(props) => (props.borderBottom || 'none')};
    padding-left: 10px;
    padding-right: 10px;
    height: ${(props) => (props.height || 'auto')};
    width: ${(props) => (props.width || '100%')};
    @media (max-width: 1200px) {
        height: 30px;
    }
`;

export const ContainerInfo = styled.div`
    display: flex;
    flex-direction: column;
    /* background-color: green; */
    /* height: 200px; */
    width: 100%;
    padding: 20px;
`;

export const ContainerHeader = styled.div<ContainerHeaderInterface>`
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: ${(props) => (`${(props.spaccing || 20) / 2}px`)};
    padding-bottom: ${(props) => (`${(props.spaccing || 20) / 2}px`)};
    margin-top: ${(props) => (`${(props.spaccing || 20) / 2}px`)};
    margin-bottom: ${(props) => (`${(props.spaccing || 20) / 2}px`)};
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${colors.theme.backgroundCardInfo};
    border-radius: 10px;
`;

export const ContainerFrame = styled.div<ContainerFrameInterfaces>`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.justifyContent || 'space-between')};
    margin-top: 10px;
`;

export const TitleLabel = styled.text<LabelInterface>`
    text-align: ${(props) => (props?.textAlign || 'start')};
    color: ${colors.theme.labelInputsCreateSeller};
    font-size: 15px;
    margin-bottom: 5px;

    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const Input = styled.input`
    color: ${colors.theme.backgroundDrawer};
    background-color: ${colors.basic.white};
    border: none;
    border-radius: 5px;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    @media (max-width: 1200px) {
        font-size: 11px;
        height: 30px;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        height: 20px;
    }
`;

export const Button = styled.button<ButtonInterfaces>`
    background-color: ${colors.theme.backgroundTabs};
    height: 60px;
    width: 200px;
    border-radius: 20px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;
    margin-top: 20px;
    margin-left: ${(props) => (props.marginLeft || '0px')};

    :hover {
        background-color: ${(props) => (props.tradeBackground ? colors.theme.grayIntense : `${colors.theme.backgroundTabs}D9`)};
    }

    @media (max-width: 1200px) {
        height: 40px;
        width: 120px;
        border-radius: 10px;
    }
`;

export const ContainerShadow = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 100%;
`;

export const ContainerSeller = styled.div<ContainerSellerInterfaces>`
    align-items: center;
    justify-content: center;
    min-width: 16%;
    display: flex;
`;

export const ButtonStatus = styled.div<ButtonStatusInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor || colors.basic.white)};
    display: flex;
    justify-content: center;
    border-radius: 12.5px;
    height: 25px;
    min-width: 16%;
    padding-left: 5px;
    padding-right: 5px;
    @media (max-width: 1200px) {
        border-radius: 7.5px;
        height: 15px;
    }
`;

export const ContainerEmpty = styled.div<ContainerEmptyInterfaces>`
    align-self: ${(props) => (props.alignSelf || 'flex-end')};
    align-items: center;
    background-color: ${colors.theme.backgroundTabs};
    border-radius: 10px;
    display: flex;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    justify-content: center;
    height: 50px;
    width: ${(props) => (props.width || '50%')};
`;

export const PhotoSeller = styled.img`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 4px;
    @media (max-width: 1200px) {
        width: 20px;
        height: 20px;
    }
`;

export const ContainerFrameMotoboy = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

export const ContainerInputsMotoboy = styled.div`
    display: flex;
    flex: 0.4;
    flex-direction: column;
`;

export const PhotoMotoboy = styled.img`
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-right: 20px;
`;

export const ContainerWarning = styled.div`
    display: flex;
    align-items: center;
    width: 70%;
    @media (max-width: 800px) {
        width: 100%;
    }
`;

export const ImgWarning = styled.img`
    object-fit: contain;
    height: 84px;
    width: 84px;
    @media (max-width: 800px) {
        height: 64px;
        width: 64px;
    }
`;

export const TextWarning = styled.text`
    color: ${colors.basic.black};
    font-size: 20px;
    @media (max-width: 800px) {
        font-size: 15px;
    }
`;

export const ContainerAdjust = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${colors.basic.white};
    width: 100%;
`;

export const TitleDetailsUser = styled.text`
    color: ${colors.basic.black};
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
        font-size: 18px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

export const ContainerAdjustButtons = styled.div``;

export const SeparatorInfos = styled.div<ContainerInterfaces>`
    background-color: ${colors.theme.backgroundBoxCreateSeller};
    margin-bottom: 20px;
    padding: ${(props) => (props.padding || '0px 0px 0px 10px')};
    width: 100%;
`;
