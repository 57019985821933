// functions
async function getDownloadRemoteFile(file: string): Promise<null> {
    // get file in Uint8Array
    const response = await fetch(file);
    const reader = response.body?.getReader();
    const result = await reader?.read();

    // Uint8Array ==> String
    const decoder = new TextDecoder('utf-8');
    const csv = decoder.decode(result?.value);

    // download file
    const fileBlob = new Blob([csv], { type: 'text/csv' });
    const csvURL = window.URL.createObjectURL(fileBlob);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.download = file;
    tempLink.click();

    return null;
}

export default {
    getDownloadRemoteFile,
};
