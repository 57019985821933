// js
import consts from '../utils/consts';
import { DataSellerInterface, DataSubSellerInterfaces } from '../interfaces';

// constants
const { baseUrl } = consts;

// interfaces
interface AuthInterfaces {
    status: number,
    result: { seller: DataSellerInterface | null, token: string, tokenRefresh: string };
    message: string,
}

interface GetInfoSellerInterfaces {
    status: number,
    result: Array<DataSubSellerInterfaces>,
    message: string,
}

// api
async function GetInfoSeller(
    subTypeAccount: string,
    idAccount: string,
    typeAccount: string,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const path = subTypeAccount ? `${baseUrl}/sellers?typeAccount=${typeAccount}${idAccount && `&idAccount=${idAccount}`}&subTypeAccount=${subTypeAccount}` : `${baseUrl}/sellers?typeAccount=${typeAccount}${idAccount && `&idAccount=${idAccount}`}`;
        const response = await fetch(path, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function EditProfile(
    oldPassword: string,
    newPassword: string,
    photo: string,
    idAccount: string,
    typeAccount: string,
    token: string,
): Promise<AuthInterfaces> {
    try {
        const data = {
            oldPassword,
            newPassword,
            photo,
        };
        const method = 'PUT';
        const body = JSON.stringify(data);
        const headers = {
            'Content-Type': 'application/json',
            'x-access-token': token,
        };
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/sellers/updatePassword?idAccount=${idAccount}&typeAccount=${typeAccount}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { seller: null, token: '', tokenRefresh: '' },
            status: 500,
            message: '',
        };
    }
}

export default {
    GetInfoSeller,
    EditProfile,
};
