// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ModalInterfaces {
    openModal: boolean,
    heightActual: number,
}

// styles
export const Modal = styled.div<ModalInterfaces>`
    background-color: ${colors.theme.backgroundModal};
    position: absolute;
    backdrop-filter: blur(2px);
    top: 0px;
    display: ${(props) => (props.openModal ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    height: 100vh;
    width: 100vw;
`;
