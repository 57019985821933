// libraries
import React, { useEffect, useState } from 'react';

// js
import { Modal } from './modalDefaultStyles';

// interfaces
interface ModalComponentInterfaces {
    openModal: boolean,
    RenderBodyModal: any,
}

const ModalDefaultComponent: React.FC<ModalComponentInterfaces> = (
    props: ModalComponentInterfaces,
) => {
    // constants
    const {
        openModal,
        RenderBodyModal,
    } = props;

    // states
    const [heightActual, setHeightActual] = useState(window.innerHeight - 80);

    // useEffect
    useEffect(() => {
        const adjustSize = window.addEventListener('resize', () => {
            setHeightActual(window.innerHeight - 80);
        }, true);

        return adjustSize;
    }, []);

    return (
        <Modal
            openModal={openModal}
            heightActual={heightActual}
        >
            {RenderBodyModal()}
        </Modal>
    );
};

export default ModalDefaultComponent;
