const colors = {
    basic: {
        black: '#000000',
        blue: '#2F49D1',
        blueLight: '#5887FF',
        blueUltraLight: '#0061F7',
        gray: '#707070',
        grayUltra: '#B9B9B9',
        green: '#4BDE97',
        red: '#F26464',
        purple: '#5F2EEA',
        yellow: '#FFB648',
        white: '#ffffff',
    },
    theme: {
        black: '#171717',
        backgroundButtonLogin: '#D02D1C',
        backgroundLogoDefault: '#C7D1FA',
        backgroundFilters: 'rgba(25, 39, 75, 0.8)',
        backgroundBoxAuth: 'rgba(255, 255, 255, 0.1)',
        backgroundCardInfo: '#C4C4C4',
        backgroundBoxCreateSeller: 'rgba(238, 236, 239, 0.5)',
        backgroundModal: 'rgba(0,0,0,0.4)',
        backgroundDrawer: '#444444',
        backgroundInputMedium: 'rgba(196,196,196, 0.5)',
        gray: '#DBDBDB',
        grayIntense: '#9B9B9B',
        buttonCrudUser: '#FF8881',
        backgroundStatusDeliveryMenPending: '#FFAC32',
        backgroundStatusDeliveryMenValidate: '#B5E0FD',
        colorStatusDeliveryMen: '#0A436B',
        colorTextDeliveryMen: '#282A2E',
        lineChart: '#EEEEEE',
        backgroundScreen: '#FF0000',
        backgroundStatusProgress: 'rgba(88, 135, 255, 0.1)',
        backgroundStatusSeeking: 'rgba(255, 172, 50, 0.1)',
        backgroundTabs: '#FE5D5D',
        backgroundContainerInfoTabs: '#F2F2F2',
        backgroundScreenContainerInfoTabs: '#F9F9F9',
        backgroundScreenApp: '#FCFCFC',
        backgroundHeaderScreenApp: '#ECF0F1',
        redLightMedium: 'rgba(231, 76, 60, 0.5)',
        redLightLow: 'rgba(231, 76, 60, 0.15)',
        backgroundDisabledFixedMotoboy: 'rgba(245, 91, 93, 0.1)',
        logout: '#E74C3C',
        textTabs: '#767676',
        backgroundBodyModal: '#DEE0E3',
        labelInputsCreateSeller: '#7E7E7E',
        backgroundMediaTimeAccept: 'rgba(88, 135, 255, 0.1)',
        backgroundMediaRunCancel: 'rgba(75, 222, 151, 0.1)',
        backgroundMediaDeliveryByMotoboy: 'rgba(47, 73, 209, 0.1)',
    },
};

export default colors;
