// libraries
import React from 'react';
import {
    addHours,
    lightFormat,
    getTime,
    differenceInMinutes,
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

// js
import {
    ContainerCardShadow,
    IconCard,
    ContainerInfoCard,
    TitleDefault,
    ContainerPercentage,
    IconPercentage,
    ContainerFilters,
    ContainerSeller,
    TextDefault,
    PhotoSeller,
    ContainerMediaGeneral,
    ContainerInfo,
    LineGray,
    ContainerBackgroundResult,
    ContainerSelect,
    Option,
    ContainerInner,
    Button,
} from './homeStyles';
import {
    iconMediumPercentage,
    iconHighPercentage,
    iconLowPercentage,
    iconTicketMedium,
    iconTmRoutes,
    iconTotalDelivery,
    iconTotalRoutes,
    logoAuth,
} from '../../assets/index';
import colors from '../../styles/colors';
import functions from '../../utils/functions';
import homeFunctions from './homeFunctions';
import dashboard from '../../services/dashboard';
import homeConstants from './homeConstants';
import {
    DashboardMediasInterfaces,
    DataOrdersInterfaces,
    DashboardIndicatorsInterfaces,
    DataSubSellerInterfaces,
    DataSellerInterface,
    formDeliveryInterfaces,
} from '../../interfaces';

const RenderAllCards = (indicators: Array<DashboardIndicatorsInterfaces>): React.ReactElement => (
    <>
        {indicators.map((v) => {
            const RenderIcon = () => {
                if (v.type === 'total') {
                    return iconTotalDelivery;
                }
                if (v.type === 'ticket') {
                    return iconTicketMedium;
                }
                if (v.type === 'time_delivery') {
                    return iconTotalRoutes;
                }
                if (v.type === 'duration_delivery') {
                    return iconTmRoutes;
                }
                return '';
            };
            const RenderIconPercentage = () => {
                if (!String(v?.percentage).includes('-') && (v?.percentage || 0) !== 0) {
                    return iconHighPercentage;
                }
                if (!String(v?.percentage).includes('-') && (v?.percentage || 0) === 0) {
                    return iconMediumPercentage;
                }
                if (String(v?.percentage).includes('-') && (v?.percentage || 0) !== 0) {
                    return iconLowPercentage;
                }
                return '';
            };
            const RenderColorPercentage = () => {
                if (!String(v?.percentage).includes('-') && (v?.percentage || 0) !== 0) {
                    return colors.basic.green;
                }
                if (!String(v?.percentage).includes('-') && (v?.percentage || 0) === 0) {
                    return colors.basic.yellow;
                }
                if (String(v?.percentage).includes('-') && (v?.percentage || 0) !== 0) {
                    return colors.basic.red;
                }
                return colors.basic.black;
            };
            const RenderDescription = () => {
                if (v.type === 'total') {
                    return 'Total de Entregas';
                }
                if (v.type === 'ticket') {
                    return 'Ticket Médio';
                }
                if (v.type === 'time_delivery') {
                    return 'Tempo de Entrega';
                }
                if (v.type === 'duration_delivery') {
                    return 'Duração da Corrida';
                }
                return '';
            };
            const RenderValue = () => {
                if (v.type === 'total') {
                    return v?.value || 0;
                }
                if (v.type === 'ticket') {
                    return `R$ ${functions.toPrice(v?.value || 0, 2)}`;
                }
                if (v.type === 'time_delivery' || v.type === 'duration_delivery') {
                    return `${v?.value?.toFixed(0) || 0}min`;
                }
                return '';
            };
            return (
                <ContainerCardShadow>
                    <IconCard src={RenderIcon()} />
                    <ContainerInfoCard>
                        <TitleDefault fontSize="16px" fontWeight="bold" marginBottom="5px">{RenderValue()}</TitleDefault>
                        <TitleDefault fontSize="12px" fontWeight="bold" color={colors.basic.grayUltra}>{RenderDescription()}</TitleDefault>
                        <ContainerPercentage>
                            <IconPercentage src={RenderIconPercentage()} />
                            <TitleDefault fontSize="10px" fontWeight="bold" marginLeft="3px" color={RenderColorPercentage()}>{v?.percentage?.toFixed(2) || '0'}</TitleDefault>
                            <TitleDefault fontSize="10px" marginLeft="10px" color={colors.basic.grayUltra}>Mês Anterior</TitleDefault>
                        </ContainerPercentage>
                    </ContainerInfoCard>
                </ContainerCardShadow>
            );
        })}
    </>
);

const RenderAllLastDelivery = (
    allDeliveryInProgress: Array<DataOrdersInterfaces>,
    infoSeller: DataSellerInterface,
): React.ReactElement | null => (
    <>
        {allDeliveryInProgress.map((v, i) => (
            <ContainerFilters
                height="50px"
                key={v.id}
                backgroundColor={colors.basic.white}
                cursorActive
                borderBottom={allDeliveryInProgress?.lastIndexOf(allDeliveryInProgress[allDeliveryInProgress.length - 1]) !== i ? '1px solid rgba(0, 0, 0, 0.2)' : 'none'}
                borderRadius={allDeliveryInProgress?.lastIndexOf(allDeliveryInProgress[allDeliveryInProgress.length - 1]) !== i ? '5px 5px 0px 0px' : '5px 5px 5px 5px'}
            >
                <ContainerSeller>
                    <TextDefault fontWeight="bold" fontSize="10px" color={colors.basic.black}>{v.name_client}</TextDefault>
                </ContainerSeller>
                {infoSeller?.typeAccount === 'business' ? (
                    <ContainerSeller>
                        <TextDefault fontWeight="bold" fontSize="10px" color={colors.basic.black}>{v.seller.name}</TextDefault>
                    </ContainerSeller>
                ) : null}
                <ContainerSeller>
                    <TextDefault fontWeight="bold" fontSize="10px" color={colors.theme.textTabs}>{v.neighborhood_client}</TextDefault>
                </ContainerSeller>
                <ContainerSeller>
                    <PhotoSeller src={v?.user?.photo || logoAuth} />
                    <TextDefault fontWeight="bold" fontSize="10px" color={colors.theme.textTabs}>{v?.user?.name}</TextDefault>
                </ContainerSeller>
                <ContainerSeller>
                    <TextDefault fontWeight="bold" fontSize="10px" color={colors.theme.textTabs}>
                        {`${lightFormat(addHours(new Date(v.created_at).getTime(), 3), 'dd.MM.yyyy')}`}
                    </TextDefault>
                </ContainerSeller>
                <ContainerSeller>
                    <TextDefault fontWeight="bold" fontSize="10px" color={colors.theme.textTabs}>
                        {`${lightFormat(new Date(v.created_at).getTime(), 'HH:mm:ss')}`}
                    </TextDefault>
                </ContainerSeller>
            </ContainerFilters>
        ))}
    </>
);

const RenderAllMediaGeneral = (
    allMediaGeneral: Array<DashboardMediasInterfaces>,
): React.ReactElement | null => {
    const RenderAllMediaCards = () => (
        allMediaGeneral.map((v: DashboardMediasInterfaces, i: number) => {
            const RenderTitle = () => {
                if (v.type === 'time_accept') {
                    return 'Tempo de Entrega';
                }
                if (v.type === 'canceled_delivery') {
                    return 'Corridas Canceladas';
                }
                if (v.type === 'accept_delivery') {
                    return 'Corridas Aceitas';
                }
                if (v.type === 'daily_billing') {
                    return 'Faturamento Diário';
                }
                if (v.type === 'favorite_neighborhood') {
                    return 'Bairro Favorito';
                }
                if (v.type === 'delivery_by_motoboy') {
                    return 'Entregas por Corrida';
                }

                return '';
            };
            const RenderRecentResult = () => {
                if (v.type === 'time_accept') {
                    return `${v?.value?.toFixed(2) || 0}min`;
                }
                if (v.type === 'canceled_delivery' || v.type === 'accept_delivery' || v.type === 'delivery_by_motoboy') {
                    return v?.value?.toFixed(2) || 0;
                }
                if (v.type === 'daily_billing') {
                    return `R$ ${functions.toPrice(v?.value || 0, 2)}`;
                }
                if (v.type === 'favorite_neighborhood') {
                    return v?.value || 'Nenhum';
                }

                return '';
            };
            const RenderOldResult = () => {
                if (v.type === 'time_accept') {
                    return `${v?.valueLastMonth?.toFixed(2) || 0}min`;
                }
                if (v.type === 'canceled_delivery' || v.type === 'accept_delivery' || v.type === 'delivery_by_motoboy') {
                    return v?.valueLastMonth?.toFixed(2) || 0;
                }
                if (v.type === 'daily_billing') {
                    return `R$ ${functions.toPrice(v?.valueLastMonth || 0, 2)}`;
                }
                if (v.type === 'favorite_neighborhood') {
                    return v?.valueLastMonth || 'Nenhum';
                }

                return '';
            };
            const RenderBackgroundColor = () => {
                if (v.type === 'time_accept') {
                    return colors.theme.backgroundMediaTimeAccept;
                }
                if (v.type === 'canceled_delivery') {
                    return colors.theme.backgroundMediaRunCancel;
                }
                if (v.type === 'daily_billing' || v.type === 'favorite_neighborhood' || v.type === 'accept_delivery') {
                    return colors.theme.backgroundMediaRunCancel;
                }
                if (v.type === 'delivery_by_motoboy') {
                    return colors.theme.backgroundMediaDeliveryByMotoboy;
                }

                return '';
            };
            const RenderColor = () => {
                if (v.type === 'time_accept') {
                    return colors.basic.blueUltraLight;
                }
                if (v.type === 'canceled_delivery') {
                    return colors.basic.green;
                }
                if (v.type === 'daily_billing' || v.type === 'favorite_neighborhood' || v.type === 'accept_delivery') {
                    return colors.basic.yellow;
                }
                if (v.type === 'delivery_by_motoboy') {
                    return colors.basic.blue;
                }

                return '';
            };
            return (
                <>
                    <ContainerInfo width="100%" flexDirection="row" justifyContent="space-between" key={v.type}>
                        <ContainerInfo>
                            <TitleDefault fontWeight="bold" fontSize="12px" marginBottom="5px">
                                {RenderTitle()}
                            </TitleDefault>
                            <TitleDefault fontSize="12px" color={colors.basic.grayUltra}>Mês Anterior</TitleDefault>
                        </ContainerInfo>
                        <ContainerInfo alignItems="flex-end">
                            <TitleDefault fontWeight="bold" fontSize="12px" marginBottom="5px">
                                {RenderRecentResult()}
                            </TitleDefault>
                            <ContainerBackgroundResult backgroundColor={RenderBackgroundColor()}>
                                <TitleDefault fontSize="10px" color={RenderColor()}>
                                    {RenderOldResult()}
                                </TitleDefault>
                            </ContainerBackgroundResult>
                        </ContainerInfo>
                    </ContainerInfo>
                    {allMediaGeneral?.lastIndexOf(allMediaGeneral[
                        allMediaGeneral.length - 1
                    ]) !== i ? <LineGray /> : null}
                </>
            );
        })
    );
    if (allMediaGeneral) {
        return (
            <ContainerMediaGeneral>
                <TitleDefault fontWeight="bold" fontSize="16px" marginBottom="30px">
                    Médias Gerais
                </TitleDefault>
                {RenderAllMediaCards()}
            </ContainerMediaGeneral>
        );
    }

    return null;
};

const RenderBodyModal = (
    type: number,
    companySelect: string,
    setCompanySelect: (v: string) => void,
    allSellers: Array<DataSubSellerInterfaces>,
    infoSeller: DataSellerInterface,
    formDelivery: formDeliveryInterfaces,
    setFormDelivery: (v: formDeliveryInterfaces) => void,
    setModalIsOpen: (v: boolean) => void,
): React.ReactElement | null => {
    if (type === 2) {
        const onChangeCompanySelect = async (txt: React.ChangeEvent<HTMLSelectElement>) => {
            const FormatSplit = txt.target.value;
            setCompanySelect(FormatSplit);
        };
        const GeneratorArchive = async () => {
            let sellerSelect = formDelivery.companySelect.split(',')[0];
            if (infoSeller?.typeAccount === 'sellers') {
                sellerSelect = String(infoSeller?.id);
            }
            const Generate = await dashboard.GeneratorReport(
                'balance',
                '',
                '',
                '',
                '',
                '',
                infoSeller?.typeAccount,
                sellerSelect === '0' ? '' : sellerSelect,
                infoSeller?.token,
            );
            if (Generate.status === 200) {
                // download remote file
                if (Generate?.result?.file) {
                    // reset states
                    setModalIsOpen(false);
                    setFormDelivery(homeConstants.formDelivery);
                    await homeFunctions.getDownloadRemoteFile(Generate.result.file);
                } else {
                    // eslint-disable-next-line no-alert
                    alert(Generate.message || 'Erro ao gerar arquivo.');
                }
            } else {
                // eslint-disable-next-line no-alert
                alert(Generate.message || 'Erro ao gerar arquivo.');
            }
        };
        const RenderOptions = () => {
            if (infoSeller?.typeAccount === 'business') {
                return (
                    <>
                        <Option value={`${0},TODOS`}>TODOS</Option>
                        {allSellers.map((allInfoSeller) => (
                            <Option value={`${allInfoSeller.id},${allInfoSeller.name}`}>{allInfoSeller.name}</Option>
                        ))}
                    </>
                );
            }
            return <Option value={`${infoSeller?.id},${infoSeller?.name}`}>{infoSeller?.name}</Option>;
        };
        return (
            <ContainerInner background={colors.basic.white}>
                <TitleDefault fontWeight="600" fontSize="16px" marginBottom="10px" textAlign="center">
                    Relatório de Saldo
                </TitleDefault>
                <TitleDefault fontWeight="600" marginBottom="10px">
                    Empresa(s)
                </TitleDefault>
                <ContainerSelect
                    value={companySelect}
                    onChange={onChangeCompanySelect}
                    minWidth="40vw"
                    marginLeft="0px"
                >
                    {RenderOptions()}
                </ContainerSelect>
                <Button onClick={GeneratorArchive}>
                    <TitleDefault fontWeight="600" fontSize="16px" textAlign="center" color={colors.basic.white}>
                        Gerar
                    </TitleDefault>
                </Button>
            </ContainerInner>
        );
    }
    if (type === 1) {
        const RenderComponentForm = (typeForm: 'select' | 'input', title: string, value: Date | string) => {
            if (typeForm === 'select') {
                const onChangeSelect = (txt: React.ChangeEvent<HTMLSelectElement>) => {
                    const FormatSplit = txt.target.value;
                    if (title === 'Empresa(s)') {
                        setFormDelivery({ ...formDelivery, companySelect: FormatSplit });
                    }
                    if (title === 'Status') {
                        setFormDelivery({ ...formDelivery, status: FormatSplit });
                    }
                };
                const RenderOptions = () => {
                    if (infoSeller?.typeAccount === 'business') {
                        return (
                            <>
                                <Option value={`${0},TODOS`}>TODOS</Option>
                                {allSellers.map((allInfoSeller) => (
                                    <Option value={`${allInfoSeller.id},${allInfoSeller.name}`}>{allInfoSeller.name}</Option>
                                ))}
                            </>
                        );
                    }
                    return <Option value={`${infoSeller?.id},${infoSeller?.name}`}>{infoSeller?.name}</Option>;
                };
                const RenderOptionsSelect = () => {
                    if (title === 'Empresa(s)') {
                        return RenderOptions();
                    }
                    if (title === 'Status') {
                        return (
                            <>
                                <Option value="nothing,TODOS">TODOS</Option>
                                <Option value="waiting,Aguardando entregador">Aguardando entregador</Option>
                                <Option value="run,Em andamento">Em andamento</Option>
                                <Option value="finished,Finalizadas">Finalizadas</Option>
                                <Option value="canceled,Canceladas">Canceladas</Option>
                            </>
                        );
                    }

                    return null;
                };
                return (
                    <>
                        <TitleDefault fontWeight="600" marginTop="20px" marginBottom="10px">
                            {title}
                        </TitleDefault>
                        <ContainerSelect
                            value={typeof value === 'string' ? value : ''}
                            onChange={onChangeSelect}
                            minWidth="40vw"
                            marginLeft="0px"
                        >
                            {RenderOptionsSelect()}
                        </ContainerSelect>
                    </>
                );
            }
            if (typeForm === 'input') {
                const onChange = (date: any) => {
                    if (title === 'Data inicial') {
                        setFormDelivery({ ...formDelivery, dataInitial: date });
                    }
                    if (title === 'Data final') {
                        setFormDelivery({ ...formDelivery, dataFinal: date });
                    }
                };
                return (
                    <>
                        <TitleDefault fontWeight="600" marginTop="20px" marginBottom="10px">
                            {title}
                        </TitleDefault>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                format="dd/MM/yyyy HH:mm"
                                margin="normal"
                                value={value}
                                onChange={onChange}
                                invalidDateMessage="Data inválida!"
                                disableFuture
                            />
                        </MuiPickersUtilsProvider>
                    </>
                );
            }

            return null;
        };
        const GeneratorDeliveryArchive = async () => {
            const GetDifference = differenceInMinutes(
                getTime(formDelivery.dataFinal),
                getTime(formDelivery.dataInitial),
            );
            if (GetDifference > 0) {
                const initialDate = lightFormat(formDelivery.dataInitial, 'dd/MM/yyyy');
                const initialHour = lightFormat(formDelivery.dataInitial, 'HH:mm');
                const finalDate = lightFormat(formDelivery.dataFinal, 'dd/MM/yyyy');
                const finalHour = lightFormat(formDelivery.dataFinal, 'HH:mm');
                const GetTypeStatusSelect = formDelivery.status.split(',')[0];
                let sellerSelect = formDelivery.companySelect.split(',')[0];
                if (infoSeller?.typeAccount === 'sellers') {
                    sellerSelect = String(infoSeller?.id);
                }
                const Generate = await dashboard.GeneratorReport(
                    'delivery',
                    GetTypeStatusSelect === 'nothing' ? '' : GetTypeStatusSelect,
                    initialDate,
                    finalDate,
                    initialHour,
                    finalHour,
                    infoSeller?.typeAccount,
                    sellerSelect === '0' ? '' : sellerSelect,
                    infoSeller?.token,
                );
                if (Generate.status === 200) {
                    // download remote file
                    if (Generate?.result?.file) {
                        // reset states
                        setModalIsOpen(false);
                        setFormDelivery(homeConstants.formDelivery);
                        await homeFunctions.getDownloadRemoteFile(Generate.result.file);
                    } else {
                        // eslint-disable-next-line no-alert
                        alert(Generate.message || 'Erro ao gerar arquivo.');
                    }
                } else {
                    // eslint-disable-next-line no-alert
                    alert(Generate.message || 'Erro ao gerar arquivo.');
                }
            } else {
                // eslint-disable-next-line no-alert
                alert('A data inicial precisa ser menor do que a data final.');
            }
        };

        return (
            <ContainerInner background={colors.basic.white}>
                <TitleDefault fontWeight="600" fontSize="16px" marginBottom="10px" textAlign="center">
                    Relatório de Entregas
                </TitleDefault>
                {RenderComponentForm('select', 'Empresa(s)', formDelivery.companySelect)}
                {RenderComponentForm('select', 'Status', formDelivery.status)}
                {RenderComponentForm('input', 'Data inicial', formDelivery.dataInitial)}
                {RenderComponentForm('input', 'Data final', formDelivery.dataFinal)}
                <Button onClick={GeneratorDeliveryArchive}>
                    <TitleDefault fontWeight="600" fontSize="16px" textAlign="center" color={colors.basic.white}>
                        Gerar
                    </TitleDefault>
                </Button>
            </ContainerInner>
        );
    }

    return null;
};

export default {
    RenderAllCards,
    RenderAllLastDelivery,
    RenderAllMediaGeneral,
    RenderBodyModal,
};
