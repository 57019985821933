// libraries
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { lightFormat } from 'date-fns';

// js
import {
    Container,
    Home,
    ContainerInner,
    ContainerFinance,
    TitleScreen,
    ContentFinance,
    ContainerInfo,
    TabOption,
    TextTabOption,
    ContainerInfoTabs,
    ImgTab,
    ContainerFrame,
    TitleLabel,
    Input,
    ContainerFilters,
    TextDefault,
    ButtonFilters,
    ContainerShadow,
    ContainerSeller,
    ContainerSelect,
    Option,
    TitlePayment,
    ContainerSeparatorInputs,
    ContainerDocumentPayment,
    ContainerAdjustDocument,
} from './financeStyles';
import colors from '../../styles/colors';
import financeConstants from './financeConstants';
import financeFunctions from './financeFunctions';
import { DataInputsInterfaces, AllHistoryInterfaces } from './financeInterfaces';
import functions from '../../utils/functions';
import financeApi from '../../services/finance';
import { RootState } from '../../store';
import profile from '../../services/profile';
import { DataSubSellerInterfaces } from '../../interfaces';

// components
import MenuComponent from '../../components/Menu/MenuComponent';
import ButtonAuth from '../Auth/components/Button';
import ModalDefaultComponent from '../../components/ModalDefault/ModalDefaultComponent';

const FinancePage: React.FC = () => {
    // useSelector
    const infoSeller = useSelector((state: RootState) => state.seller.seller);

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [idScreen, setIdScreen] = useState(infoSeller?.typeAccount === 'business' ? 5 : 2);
    // eslint-disable-next-line max-len
    // const [idFilter, setIdFilter] = useState<'' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS'>('');
    const [idFilterTwo, setIdFilterTwo] = useState('');
    const [allHistory] = useState<Array<AllHistoryInterfaces>>([]);
    const [allHistoryEntryAndExit, setAllHistoryEntryAndExit] = useState<Array<
    AllHistoryInterfaces>>([]);
    const [sellerSelect, setSellerSelect] = useState(`${infoSeller?.typeAccount === 'sellers' ? infoSeller?.id : 'null'},sellers,Selecione uma loja`);
    const [allSellers, setAllSellers] = useState<Array<DataSubSellerInterfaces>>([]);
    const [inputs, setInputs] = useState<DataInputsInterfaces>(financeConstants.initialInputs);
    const [existAllHistory] = useState<boolean | null>(null);
    const [existHistoryEntryAndExit, setExistHistoryEntryAndExit] = useState<boolean | null>(null);
    const [documentPayment, setDocumentPayment] = useState({
        state: false,
        value: '',
    });

    // methods
    const GetHistoryRecharges = async () => {
        // const GetAllRecharges = await financeApi.GetRecharges(
        //     idFilter,
        //     infoSeller?.id,
        //     infoSeller?.type === 'admin' ? 'sellers' : infoSeller?.typeAccount,
        //     infoSeller?.token,
        // );
        // setAllHistory(GetAllRecharges?.result || []);
        // setExistAllHistory(GetAllRecharges?.result?.length > 0 || false);
        const GetAllSellers = await profile.GetInfoSeller('', '', 'sellers', infoSeller?.token);
        if (GetAllSellers.status === 200) {
            setAllSellers(GetAllSellers.result);
        }
    };

    const GetHistoryEntryAndExit = async (id: string, typeAccount: string) => {
        const GetAllHistory = await financeApi.HistoryEntryAndExit(
            idFilterTwo,
            id,
            typeAccount,
            infoSeller?.token,
        );
        setAllHistoryEntryAndExit(GetAllHistory?.result || []);
        setExistHistoryEntryAndExit(GetAllHistory?.result?.length > 0 || false);
    };

    const onChangeSellerLinkDeliveryMen = async (
        txt: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setSellerSelect(txt.target.value);
    };

    // renders
    const RenderScreen = () => {
        // methods
        function RenderInput(
            label: string,
            type: 'rechargeTicket' | 'rechargePix' | 'rechargeCard' | 'expiration' | 'numberCard' | 'validCard' | 'codCard' | 'nameCard',
            divideInput: string,
            disabled: boolean,
        ) {
            // methods
            const onChange = (txt: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = txt.target;
                if (['rechargeTicket', 'rechargePix', 'rechargeCard'].includes(type)) {
                    const extractNumbers = value.replace(/\D/g, '') || '0';
                    return setInputs({ ...inputs, [type]: functions.moneyMask(extractNumbers) });
                }
                if (type === 'expiration') {
                    return setInputs({
                        ...inputs,
                        [type]: functions.dateMask(inputs.expiration, value),
                    });
                }
                if (type === 'numberCard') {
                    return setInputs({ ...inputs, [type]: value.replace(/[^0-9\\.]+/g, '') });
                }
                if (type === 'validCard') {
                    return setInputs({
                        ...inputs,
                        [type]: functions.validCard(inputs.validCard, value),
                    });
                }
                if (type === 'codCard') {
                    return setInputs({ ...inputs, [type]: value.replace(/\D/g, '').substring(0, 3) });
                }
                if (type === 'nameCard') {
                    return setInputs({ ...inputs, [type]: value.toUpperCase() });
                }
                return setInputs({ ...inputs, [type]: value });
            };

            // main
            return (
                <ContainerFrame divideInput={divideInput}>
                    <TitleLabel>{label}</TitleLabel>
                    {type === 'numberCard' ? (
                        <InputMask
                            mask={inputs.maskNumberCard}
                            value={inputs.numberCard.replace(/\D/g, '')}
                            onChange={onChange}
                            style={financeConstants.stylesInputNumberCard}
                            maskPlaceholder={null}
                            disabled={disabled}
                        />
                    ) : (
                        <Input
                            value={inputs[type]}
                            onChange={onChange}
                            maxLength={64}
                            type="text"
                            disabled={disabled}
                        />
                    )}
                </ContainerFrame>
            );
        }

        // renders
        const RenderAllTabs = () => {
            const DecideAllTabs = infoSeller?.typeAccount === 'business' ? financeConstants.allTabs.slice(-1) : financeConstants.allTabs;

            return (
                DecideAllTabs.map((v) => {
                    const TradeIdTab = () => {
                        setIdScreen(v.id);
                    };
                    return (
                        <TabOption onClick={TradeIdTab} focus={idScreen === v.id}>
                            <ImgTab src={v.icon} />
                            <TextTabOption>{v.title}</TextTabOption>
                        </TabOption>
                    );
                })
            );
        };

        const RenderTabScreen = () => {
            const onPayment = async () => {
                // financeFunctions.ActionPayment(
                //     idScreen,
                //     inputs,
                //     setInputs,
                //     infoSeller,
                //     // GetHistoryRecharges,
                // );
                const GetFinance = await financeApi.ActionRecharges(
                    functions.priceUnmask(inputs.rechargePix),
                    infoSeller?.id,
                    infoSeller?.token,
                );
                if (GetFinance?.status === 201) {
                    setDocumentPayment({
                        state: true,
                        value: GetFinance?.result?.checkoutUrl,
                    });
                    setInputs({ ...inputs, rechargePix: '' });
                } else {
                    // eslint-disable-next-line no-alert
                    alert(GetFinance?.message);
                }
            };
            if (idScreen === 1) {
                return (
                    <>
                        <ContainerInfoTabs>
                            <TitlePayment>Informações para pagamento</TitlePayment>
                            {RenderInput('Valor da recarga', 'rechargeTicket', '100%', false)}
                            {RenderInput('Vencimento', 'expiration', '100%', false)}
                        </ContainerInfoTabs>
                        <ButtonAuth onClick={onPayment} label="GERAR BOLETO PARA RECARGA" colorButton={null} colorText={null} />
                    </>
                );
            }
            if (idScreen === 2) {
                return (
                    <>
                        <ContainerInfoTabs>
                            <TitlePayment>Informações para pagamento</TitlePayment>
                            {RenderInput('Valor da recarga', 'rechargePix', '100%', false)}
                            {/* <TitleLabel>
                                Atenção! Se o pedido de recarga via
                                PIX não for pago em até 30 minutos,
                                você precisa fazer uma nova solicitação.
                            </TitleLabel> */}
                        </ContainerInfoTabs>
                        {/* eslint-disable-next-line max-len */}
                        {/* <ButtonAuth onClick={onPayment} label="GERAR QR CODE PARA RECARGA" colorButton={null} colorText={null} /> */}
                        <ButtonAuth onClick={onPayment} label="SOLICITAR COBRANÇA" colorButton={null} colorText={null} />
                    </>
                );
            }
            if (idScreen === 3) {
                return (
                    <>
                        <ContainerInfoTabs>
                            <TitlePayment>Informações para pagamento</TitlePayment>
                            {RenderInput('Valor da recarga', 'rechargeCard', '100%', false)}
                            {RenderInput('Número do cartão', 'numberCard', '100%', false)}
                            <ContainerSeparatorInputs>
                                {RenderInput('Validade do cartão', 'validCard', '48%', false)}
                                {RenderInput('CVV', 'codCard', '48%', false)}
                            </ContainerSeparatorInputs>
                            {RenderInput('Nome completo do titular do cartão', 'nameCard', '100%', false)}
                        </ContainerInfoTabs>
                        <ButtonAuth onClick={onPayment} label="RECARREGAR AGORA" colorButton={null} colorText={null} />
                    </>
                );
            }
            if (idScreen === 4 || idScreen === 5) {
                return (
                    <ContainerInfoTabs width="100%">
                        {idScreen === 5 ? (
                            <ContainerFilters marginBottom="20px">
                                <TextDefault fontSize="10px" fontWeight="normal">Filtrar por:</TextDefault>
                                {financeConstants.allFiltersTwo.map((v) => {
                                    const ActiveFocusFilter = () => {
                                        setIdFilterTwo(v.id);
                                    };
                                    return (
                                        <ButtonFilters
                                            onClick={ActiveFocusFilter}
                                            focus={idFilterTwo === v.id}
                                        >
                                            <TextDefault fontWeight="normal" fontSize="10px" color={idFilterTwo === v.id ? colors.basic.white : colors.basic.black}>{v.title}</TextDefault>
                                        </ButtonFilters>
                                    );
                                })}
                            </ContainerFilters>
                        ) : null}
                        {infoSeller?.typeAccount === 'business' ? (
                            <ContainerSelect
                                value={sellerSelect}
                                onChange={onChangeSellerLinkDeliveryMen}
                                width="200px"
                            >
                                <Option value={`${infoSeller?.typeAccount === 'sellers' ? infoSeller?.id : 'null'},sellers,Selecione uma loja`}>Todas as lojas</Option>
                                <Option value={`${infoSeller?.id},business, Dono`}>Dono</Option>
                                {allSellers.map((allInfoSeller: any) => (
                                    <Option value={`${allInfoSeller.id},sellers,${allInfoSeller.name}`}>{allInfoSeller.name}</Option>
                                ))}
                            </ContainerSelect>
                        ) : null}
                        {/* eslint-disable-next-line max-len */}
                        {(idScreen === 4 && !allHistory.length && existAllHistory === false) || (idScreen === 5 && !allHistoryEntryAndExit.length && existHistoryEntryAndExit === false) ? (
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs} width="100%">Sem dados para serem exibidos.</TextDefault>
                        ) : null}
                        {/* eslint-disable-next-line max-len */}
                        {(allHistory.length && existAllHistory) || (allHistoryEntryAndExit.length && existHistoryEntryAndExit) ? (
                            <ContainerShadow>
                                <ContainerFilters height="50px" backgroundColor={colors.theme.backgroundContainerInfoTabs} borderBottom="2px solid rgba(0, 0, 0, 0.2)">
                                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>{idScreen === 4 ? 'Tipo de recarga' : 'Código'}</TextDefault>
                                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>{idScreen === 4 ? 'Status' : 'Valor'}</TextDefault>
                                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>{idScreen === 4 ? 'Código' : 'Status'}</TextDefault>
                                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>{idScreen === 4 ? 'Valor' : 'Data'}</TextDefault>
                                </ContainerFilters>
                                {/* eslint-disable-next-line max-len */}
                                {(idScreen === 4 ? allHistory : allHistoryEntryAndExit).map((v, i) => (
                                    <ContainerFilters
                                        height="50px"
                                        key={v.id}
                                        backgroundColor={colors.basic.white}
                                        borderBottom={allHistory?.lastIndexOf(allHistory[allHistory.length - 1]) !== i ? '1px solid rgba(0, 0, 0, 0.2)' : 'none'}
                                        borderRadius={allHistory?.lastIndexOf(allHistory[allHistory.length - 1]) !== i ? '5px 5px 0px 0px' : '5px 5px 5px 5px'}
                                    >
                                        <ContainerSeller justifyContent="center">
                                            <TextDefault fontWeight="bold" fontSize="12px" color={colors.theme.textTabs} width="100%">{idScreen === 4 ? financeFunctions.RenderType(v.type) : v.id}</TextDefault>
                                        </ContainerSeller>
                                        {idScreen === 4 ? (
                                            <ContainerSeller justifyContent="center">
                                                <TextDefault
                                                    backgroundColor={v.status === 'finished' ? colors.basic.green : colors.theme.buttonCrudUser}
                                                    fontWeight="bold"
                                                    fontSize="12px"
                                                    color={colors.basic.white}
                                                    padding="3px"
                                                >
                                                    {v.status === 'finished' ? 'Finalizado' : 'Rejeitado'}
                                                </TextDefault>
                                            </ContainerSeller>
                                        ) : (
                                            <ContainerSeller justifyContent="center">
                                                <TextDefault fontWeight="bold" fontSize="11px" color={colors.theme.textTabs} width="100%">{`R$ ${functions.toPrice(v.value, 2)}`}</TextDefault>
                                            </ContainerSeller>
                                        )}
                                        {idScreen === 4 ? (
                                            <ContainerSeller justifyContent="center">
                                                <TextDefault fontWeight="bold" fontSize="12px" color={colors.theme.textTabs} width="100%">{v.code}</TextDefault>
                                            </ContainerSeller>
                                        ) : (
                                            <ContainerSeller justifyContent="center">
                                                <TextDefault
                                                    // eslint-disable-next-line max-len
                                                    backgroundColor={v.value > 0 ? colors.basic.green : colors.theme.buttonCrudUser}
                                                    fontWeight="bold"
                                                    fontSize="12px"
                                                    color={colors.basic.white}
                                                    padding="3px"
                                                >
                                                    {v.value > 0 ? 'Entrada' : 'Saída'}
                                                </TextDefault>
                                            </ContainerSeller>
                                        )}
                                        {idScreen === 4 ? (
                                            <ContainerSeller justifyContent="center">
                                                <TextDefault fontWeight="bold" fontSize="11px" color={colors.theme.textTabs} width="100%">{`R$ ${functions.toPrice(v.value, 2)}`}</TextDefault>
                                            </ContainerSeller>
                                        ) : (
                                            <ContainerSeller justifyContent="center">
                                                <TextDefault fontWeight="bold" fontSize="11px" color={colors.theme.textTabs} width="100%">{lightFormat(new Date(v.created_at), 'dd/MM/yyyy')}</TextDefault>
                                            </ContainerSeller>
                                        )}
                                    </ContainerFilters>
                                ))}
                            </ContainerShadow>
                        ) : null}
                    </ContainerInfoTabs>
                );
            }

            return null;
        };

        return (
            <ContainerFinance visibleItem={visibleDrawer}>
                <TitleScreen>Financeiro</TitleScreen>
                <ContentFinance>
                    <ContainerInfo flexDirection="row" justifyContent="flex-start">
                        {RenderAllTabs()}
                    </ContainerInfo>
                    {RenderTabScreen()}
                </ContentFinance>
            </ContainerFinance>
        );
    };

    const ClosePayment = async () => {
        setDocumentPayment({
            ...documentPayment,
            state: false,
        });
    };

    const RenderDocumentPayment = () => (
        <ContainerAdjustDocument>
            <ContainerDocumentPayment
                src={documentPayment.value}
            />
            <ButtonAuth onClick={ClosePayment} label="JÁ PAGUEI" colorButton={null} colorText={null} />
        </ContainerAdjustDocument>
    );

    // useEffect
    useEffect(() => {
        setTimeout(() => {
            GetHistoryRecharges();
            const verificationSeller = infoSeller?.typeAccount === 'sellers' ? infoSeller?.id : '';
            GetHistoryEntryAndExit(sellerSelect.split(',')[2] !== 'Selecione uma loja' ? sellerSelect.split(',')[0] : verificationSeller, sellerSelect.split(',')[1]);
        }, 500);
    }, [idFilterTwo, sellerSelect]);

    useEffect(() => {
        const validation = financeConstants.validationCards.find((item) => item.regex.test(inputs.numberCard.replace(/\s/g, '')));
        if (validation) {
            const { type, mask } = validation;
            setInputs({ ...inputs, brandCard: type, maskNumberCard: mask });
        }
    }, [inputs.numberCard]);

    // main
    return (
        <Container>
            <Home>
                <ContainerInner>
                    {!documentPayment?.state ? (
                        <>
                            <MenuComponent
                                visibleDrawer={visibleDrawer}
                                setVisibleDrawer={setVisibleDrawer}
                            />
                            {RenderScreen()}
                        </>
                    ) : (
                        <ModalDefaultComponent
                            openModal={documentPayment?.state}
                            RenderBodyModal={RenderDocumentPayment}
                        />
                    )}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default FinancePage;
