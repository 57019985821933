// libraries
import React from 'react';

// js
import colors from '../../../../styles/colors';
import {
    ContainerFilters,
    TextDefault,
    ButtonFilters,
    ContainerShadow,
    ContainerSeller,
    PhotoSeller,
    ContainerInfo,
    ContainerHeader,
    ContainerFrame,
    TitleLabel,
    Input,
    ImgWarning,
    TextWarning,
    ContainerWarning,
    ContainerQueue,
    TitleBoxQueue,
    ButtonStatus,
    TxtStatus,
    ContainerOnlyQueue,
    ContainerMore,
    ContainerEvaluation,
    ContainerMotoboy,
    TextSeller,
} from './historyDeliveryStyles';
import { helmetMotoboy, logoAuth, warning } from '../../../../assets/index';
import masks from '../../../../utils/functions';
import { DataOrdersInterfaces, DataSellerInterface } from '../../../../interfaces';
import { updateHistoryRoutes } from '../../../../store/modules/seller/actions';
import delivery from '../../../../services/delivery';

// interfaces
interface AllFiltersInterfaces {
    title: string,
    id: '' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS',
}

const HistoryDelivery = (
    idFilter: string,
    setIdFilter: (v: '' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS') => void,
    allOrders: Array<DataOrdersInterfaces>,
    oneOrderHistory: Array<any>,
    setOneOrderHistory: (v: Array<DataOrdersInterfaces>) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    historyRoutes: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dispatch: any,
    openModalEvaluation: boolean,
    setOpenModalEvaluation: (v: boolean) => void,
    infoSeller: DataSellerInterface,
    existQueue: boolean,
    setExistQueue: (v: boolean) => void,
    setIdOrderMain: (v: number) => void,
): React.ReactElement => {
    const allFilters: Array<AllFiltersInterfaces> = [
        {
            id: '',
            title: 'Todas as Entregas',
        },
        {
            id: 'LAST_7_DAYS',
            title: 'Últimos 7 dias',
        },
        {
            id: 'LAST_15_DAYS',
            title: 'Últimos 15 dias',
        },
        {
            id: 'LAST_30_DAYS',
            title: 'Últimos 30 dias',
        },
    ];

    // methods
    const openEvaluation = () => {
        setOpenModalEvaluation(true);
    };

    // renders
    function RenderInput(label: string, value: string) {
        return (
            <ContainerFrame>
                <TitleLabel>{label}</TitleLabel>
                <Input
                    value={value}
                    disabled
                />
            </ContainerFrame>
        );
    }

    if (allOrders?.length && !oneOrderHistory.length) {
        return (
            <>
                <ContainerFilters marginBottom="20px">
                    <TextDefault fontSize="10px">Filtrar por:</TextDefault>
                    {allFilters.map((v) => {
                        const ActiveFocusFilter = () => {
                            setIdFilter(v.id);
                        };
                        return (
                            <ButtonFilters
                                onClick={ActiveFocusFilter}
                                focus={idFilter === v.id}
                            >
                                <TextDefault fontSize="10px" color={idFilter === v.id ? colors.basic.white : colors.basic.black}>{v.title}</TextDefault>
                            </ButtonFilters>
                        );
                    })}
                </ContainerFilters>
                <ContainerShadow>
                    <ContainerFilters height="50px" backgroundColor={colors.theme.backgroundContainerInfoTabs} borderBottom="2px solid rgba(0, 0, 0, 0.2)">
                        <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Entregador</TextDefault>
                        <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Pedido</TextDefault>
                        <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Status</TextDefault>
                        <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Valor Total</TextDefault>
                    </ContainerFilters>
                    {allOrders?.map((v, i) => (
                        <ContainerFilters
                            height="50px"
                            key={v.id}
                            onClick={async () => {
                                if (historyRoutes.slice(-1)[0] !== window.location.pathname) {
                                    dispatch(updateHistoryRoutes(
                                        [...historyRoutes, window.location.pathname],
                                    ));
                                }
                                const GetInfoDelivery = await delivery.GetDelivery(
                                    v.id,
                                    infoSeller?.id,
                                    infoSeller?.token,
                                );
                                setOneOrderHistory(GetInfoDelivery?.result);
                                setIdOrderMain(v.id);
                            }}
                            backgroundColor={colors.basic.white}
                            borderBottom={allOrders?.lastIndexOf(allOrders[allOrders.length - 1]) !== i ? '1px solid rgba(0, 0, 0, 0.2)' : 'none'}
                            borderRadius={allOrders?.lastIndexOf(allOrders[allOrders.length - 1]) !== i ? '5px 5px 0px 0px' : '5px 5px 5px 5px'}
                        >
                            <ContainerSeller>
                                <PhotoSeller src={v?.user?.photo || logoAuth} />
                                <TextDefault fontWeight="bold" fontSize="12px" color={colors.theme.textTabs}>{v?.user?.name}</TextDefault>
                            </ContainerSeller>
                            <ContainerSeller justifyContent="center">
                                <TextDefault fontWeight="bold" fontSize="12px" color={colors.theme.textTabs}>{`#${v.id}`}</TextDefault>
                            </ContainerSeller>
                            <ContainerSeller justifyContent="center">
                                <TextDefault
                                    backgroundColor={v.status === 'finished' ? colors.theme.backgroundStatusDeliveryMenValidate : colors.theme.buttonCrudUser}
                                    fontWeight="bold"
                                    fontSize="12px"
                                    color={colors.theme.colorStatusDeliveryMen}
                                    padding="3px"
                                >
                                    {v.status === 'finished' ? 'Finalizado' : 'Cancelado'}
                                </TextDefault>
                            </ContainerSeller>
                            <ContainerSeller justifyContent="center">
                                <TextDefault fontWeight="bold" fontSize="12px" color={colors.theme.textTabs}>{`R$ ${masks.toPrice(v?.allTotalPrice || 0, 2)}`}</TextDefault>
                            </ContainerSeller>
                        </ContainerFilters>
                    ))}
                </ContainerShadow>
            </>
        );
    }

    if (oneOrderHistory?.length) {
        return (
            <ContainerInfo>
                <ContainerHeader spaccing={40}>
                    <TextDefault fontSize="18px" fontWeight="bold">DETALHES DA ENTREGA</TextDefault>
                    <TextDefault fontSize="18px" fontWeight="bold">{`NÚMERO DO PEDIDO: #${oneOrderHistory[0].id}`}</TextDefault>
                </ContainerHeader>
                {RenderInput('Nome do cliente:', oneOrderHistory[0].name_client)}
                {RenderInput('Telefone do cliente:', oneOrderHistory[0].telephone_client)}
                {RenderInput('Haverá retorno:', oneOrderHistory[0].back ? 'Sim' : 'Não')}
                {RenderInput('Endereço de entrega:', oneOrderHistory[0].address_client)}
                {RenderInput('Observações:', oneOrderHistory[0].observation)}
                <ContainerMore>
                    {oneOrderHistory[0]?.nextQueue?.previewQueue?.length > 0 ? (
                        <ContainerQueue>
                            <TitleBoxQueue>Fila de Entregas</TitleBoxQueue>
                            {oneOrderHistory[0]?.nextQueue?.previewQueue.map((
                                v: DataOrdersInterfaces,
                                i: number,
                            ) => {
                                const RenderStatus = () => {
                                    if (['finished', 'canceled'].includes(v.status || '')) {
                                        return (
                                            // eslint-disable-next-line max-len
                                            <ButtonStatus backgroundColor={v.status === 'finished' ? colors.theme.backgroundTabs : colors.theme.buttonCrudUser}>
                                                <TxtStatus
                                                    color={v.status === 'finished' ? colors.basic.white : colors.theme.colorStatusDeliveryMen}
                                                    cursor="pointer"
                                                >
                                                    {v.status === 'finished' ? 'Ver detalhes' : 'Cancelado'}
                                                </TxtStatus>
                                            </ButtonStatus>
                                        );
                                    }
                                    if (v.status === 'run') {
                                        return (
                                            <ButtonStatus backgroundColor={colors.basic.yellow}>
                                                <TxtStatus
                                                    color={colors.basic.white}
                                                    cursor="pointer"
                                                >
                                                    Em andamento
                                                </TxtStatus>
                                            </ButtonStatus>
                                        );
                                    }
                                    if (v.status === 'waiting') {
                                        return (
                                            <ButtonStatus backgroundColor={colors.basic.yellow}>
                                                <TxtStatus
                                                    color={colors.basic.white}
                                                    cursor="pointer"
                                                >
                                                    Aguardando
                                                </TxtStatus>
                                            </ButtonStatus>
                                        );
                                    }
                                    return null;
                                };
                                const GoToDetailsOnlyQueue = () => {
                                    setOneOrderHistory([v]);
                                    setExistQueue(false);
                                };
                                return (
                                    <ContainerOnlyQueue key={v.id} onClick={GoToDetailsOnlyQueue}>
                                        <TxtStatus cursor="pointer" width="30%">{`${i + 1} - ${v.name_client}`}</TxtStatus>
                                        <TxtStatus cursor="pointer" width="30%">{`R$ ${masks.toPrice(v?.total_price, 2)}`}</TxtStatus>
                                        {RenderStatus()}
                                    </ContainerOnlyQueue>
                                );
                            })}
                        </ContainerQueue>
                    ) : null}
                    {/* eslint-disable-next-line max-len */}
                    {!oneOrderHistory[0]?.user?.orders_avaliations?.includes(oneOrderHistory[0]?.id) ? (
                        <ContainerEvaluation>
                            <ContainerMotoboy>
                                <TxtStatus width="70%">Como foi sua entrega?</TxtStatus>
                                <PhotoSeller src={helmetMotoboy} />
                            </ContainerMotoboy>
                            <ContainerMotoboy>
                                <ContainerSeller>
                                    <PhotoSeller
                                        src={oneOrderHistory[0]?.user?.photo || logoAuth}
                                    />
                                    <TextSeller>{oneOrderHistory[0]?.user?.name}</TextSeller>
                                </ContainerSeller>
                                <TxtStatus width="50%" cursor="pointer" onClick={openEvaluation}>Avaliar</TxtStatus>
                            </ContainerMotoboy>
                        </ContainerEvaluation>
                    ) : null}
                </ContainerMore>
            </ContainerInfo>
        );
    }

    return (
        <ContainerWarning>
            <ImgWarning src={warning} />
            <TextWarning>Você não possui histórico de entregas no momento.</TextWarning>
        </ContainerWarning>
    );
};

export default HistoryDelivery;
