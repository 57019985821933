// js
import {
    LOGOUT,
    UPDATE_SELLER,
    UPDATE_QTD_MOTOBOY_PEDING,
    UPDATE_QTD_DELIVERIES_WITH_RUN,
    UPDATE_TRANSFERABLE_BALANCE,
    UPDATE_HISTORY_ROUTES,
    UPDATE_EXEMPTION,
} from './constants';
import { AppDispatch } from '../../index';

// interfaces
import { DataSellerInterface } from '../../../interfaces';

// actions
export const logoutAuth: AppDispatch = () => (dispatch: AppDispatch) => {
    dispatch({
        type: LOGOUT,
    });
};

export const updateSeller: AppDispatch = (
    seller: DataSellerInterface,
) => (dispatch: AppDispatch) => {
    dispatch({
        type: UPDATE_SELLER,
        payload: seller,
    });
};

export const updateHistoryRoutes: AppDispatch = (
    historyRoutes: DataSellerInterface,
) => (dispatch: AppDispatch) => {
    dispatch({
        type: UPDATE_HISTORY_ROUTES,
        payload: historyRoutes,
    });
};

export const updateTransferableBalance: AppDispatch = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transferable_balance: any,
) => (dispatch: AppDispatch) => {
    dispatch({
        type: UPDATE_TRANSFERABLE_BALANCE,
        payload: transferable_balance,
    });
};

export const updateExemption: AppDispatch = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    exemption: any,
) => (dispatch: AppDispatch) => {
    dispatch({
        type: UPDATE_EXEMPTION,
        payload: exemption,
    });
};

export const updateQtdMotoboyPeding: AppDispatch = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    qtdMotoboyPeding: any,
) => (dispatch: AppDispatch) => {
    dispatch({
        type: UPDATE_QTD_MOTOBOY_PEDING,
        payload: qtdMotoboyPeding,
    });
};

export const updateQtdDeliveriesWithRun: AppDispatch = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    qtdDeliveriesWithRun: any,
) => (dispatch: AppDispatch) => {
    dispatch({
        type: UPDATE_QTD_DELIVERIES_WITH_RUN,
        payload: qtdDeliveriesWithRun,
    });
};
