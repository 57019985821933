// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { v4 as uuidv4 } from 'uuid';

// js
import {
    Container,
    Home,
    ContainerInner,
    ContainerDeliveryMen,
    TitleScreen,
    ContentDeliveryMen,
    ContainerHeader,
    TitleDefault,
    ContainerBoxDeliveryMen,
    ContainerDataPersonalDeliveryMen,
    PhotoArchives,
    ContainerBodyDeliveryMen,
    ContainerBox,
    ContainerBoxInfoDeliveryMen,
    ContainerSelect,
    Option,
    TitleResultStatistics,
    ContainerButtonAction,
    TitleDetailsUser,
    ContainerPointer,
    Logo,
    ContainerCamera,
    ImgCamera,
    BackgroundCheck,
    Checked,
    Input,
} from './deliveryManStyles';
import colors from '../../styles/colors';
import {
    DataDeliveryMen,
    ArchivesDeliveryMenInterfaces,
    InfoDeliveryMenInterfaces,
    DataSubSellerInterfaces,
} from '../../interfaces';
import components from './DeliveryManComponents';
import deliveryMan from '../../services/deliveryMan';
import profile from '../../services/profile';
import { RootState } from '../../store';
import { camera, check, logoAuth } from '../../assets';

// components
import MenuComponent from '../../components/Menu/MenuComponent';
import ButtonAuth from '../Auth/components/Button';
import { updateHistoryRoutes } from '../../store/modules/seller/actions';

const DeliveryManPage: React.FC = () => {
    // useSelector
    const infoSeller = useSelector((state: RootState) => state.seller.seller);
    const historyRoutes = useSelector((state: RootState) => state.seller.historyRoutes);

    // dispatch
    const dispatch = useDispatch();

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    // const [isCheckedAll, setIsCheckedAll] = useState(false);
    const [allDeliveryMen, setAllDeliveryMen] = useState<Array<DataDeliveryMen>>([]);
    const [idScreen, setIdScreen] = useState('list');
    const [infoDeliveryMen, setInfoDeliveryMen] = useState<Array<InfoDeliveryMenInterfaces>>([]);
    const [archivesDeliveryMen, setArchivesDeliveryMen] = useState<
    ArchivesDeliveryMenInterfaces | null>(null);
    const [qtdDropDawn, setQtdDropDawn] = useState(4);
    const [idActionDropDawn, setIdActionDropDawn] = useState<number | null>(null);
    const [checkedLicenseVehicle, setCheckedLicenseVehicle] = useState(false);
    const [checkedProofOfResidence, setCheckedProofOfResidence] = useState(false);
    const [allSellers, setAllSellers] = useState<Array<DataSubSellerInterfaces>>([]);
    const [sellerSelect, setSellerSelect] = useState('');
    const [validationExistDeliveryMan, setValidationExistDeliveryMan] = useState(true);
    const [search, setSearch] = useState('');
    const [uuidRefresh, setUuidRefresh] = useState('');
    const [existSearch, setExistSearch] = useState(false);

    // constants
    const {
        buttonProps,
        itemProps,
    } = useDropdownMenu(qtdDropDawn);

    // methods
    const onChangeSearch = (txt: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(txt.target.value);
        if (!txt.target.value.length) {
            setTimeout(() => {
                setExistSearch(false);
            }, 2000);
        } else {
            setExistSearch(true);
        }
    };

    const GetInfoScreen = async (noRefresh?: boolean) => {
        const GetInfoSellers = await deliveryMan.GetAllDeliveryMen(
            search || '',
            infoSeller?.id,
            infoSeller?.token,
        );
        const GetAllSellers = await profile.GetInfoSeller('', '', 'sellers', infoSeller?.token);
        if (GetInfoSellers.status === 200) {
            const InfoSellerActionFormat = GetInfoSellers?.result.map((v) => {
                if (v.id) {
                    return {
                        ...v,
                        checked: false,
                    };
                }

                return v;
            });
            setAllDeliveryMen(InfoSellerActionFormat || []);
            if (!InfoSellerActionFormat.length) {
                setValidationExistDeliveryMan(false);
            }
        }
        if (GetAllSellers.status === 200) {
            setAllSellers(GetAllSellers.result);
        }
        if (!noRefresh) {
            setCheckedProofOfResidence(false);
            setCheckedLicenseVehicle(false);
            setIdScreen('list');
            setSellerSelect('');
        }
    };

    // useEffect
    useEffect(() => {
        if (historyRoutes.slice(-1)[0] === window.location.pathname) {
            // eslint-disable-next-line max-len
            const removeLastItem = historyRoutes.length === 1 ? [] : historyRoutes.slice(0, historyRoutes.length - 1);
            dispatch(updateHistoryRoutes(removeLastItem));
        }
        GetInfoScreen();
    }, []);

    useEffect(() => {
        setInterval(() => {
            const newUuid = uuidv4();
            setUuidRefresh(newUuid);
        }, 2000);
    }, []);

    useEffect(() => {
        GetInfoScreen(true);
    }, [uuidRefresh]);

    // renders
    const RenderScreen = () => {
        if (idScreen === 'list') {
            return (
                <ContainerDeliveryMen visibleItem={visibleDrawer}>
                    <TitleScreen>Entregadores</TitleScreen>
                    <Input
                        value={search}
                        onChange={onChangeSearch}
                        placeholder="Busque por um entregador"
                        existSearch={existSearch}
                    />
                    <ContentDeliveryMen>
                        {allDeliveryMen.length ? (
                            <ContainerHeader padding="10px 0px 10px 0px" justifyContent="space-between">
                                <ContainerBodyDeliveryMen
                                    isOpen={idActionDropDawn !== null}
                                >
                                    <TitleDefault widthText="16%" marginLeft="5px" color={colors.theme.textTabs}>Nome do Entregador</TitleDefault>
                                    <TitleDefault widthText="16%" marginLeft="5px" color={colors.theme.textTabs}>E-mail</TitleDefault>
                                    <TitleDefault widthText="16%" marginLeft="5px" color={colors.theme.textTabs}>Taxa de Aceitação</TitleDefault>
                                    <TitleDefault widthText="16%" marginLeft="5px" color={colors.theme.textTabs}>Status</TitleDefault>
                                    <TitleDefault widthText="16%" marginLeft="5px" color={colors.theme.textTabs}>Ranking</TitleDefault>
                                </ContainerBodyDeliveryMen>
                                <TitleDefault widthText="20px" marginRight="5px" />
                            </ContainerHeader>
                        ) : null}
                        {!allDeliveryMen.length && validationExistDeliveryMan === false ? (
                            <TitleDefault fontWeight="bold" fontSize="15px">Sem entregadores para serem exibidos.</TitleDefault>
                        ) : null}
                        {components.RenderAllSellers(
                            allDeliveryMen,
                            setAllDeliveryMen,
                            infoSeller,
                            GetInfoScreen,
                            setIdScreen,
                            buttonProps,
                            itemProps,
                            idActionDropDawn,
                            setIdActionDropDawn,
                            setQtdDropDawn,
                            setInfoDeliveryMen,
                            setArchivesDeliveryMen,
                            historyRoutes,
                            dispatch,
                        )}
                    </ContentDeliveryMen>
                </ContainerDeliveryMen>
            );
        }

        if (idScreen === 'validationDeliveryMen' || idScreen === 'viewDeliveryMen') {
            // methods
            const onCheckedLicenseVehicle = () => {
                setCheckedLicenseVehicle(!checkedLicenseVehicle);
            };
            const onCheckedProofOfResidence = () => {
                setCheckedProofOfResidence(!checkedProofOfResidence);
            };
            const onRejectDeliveryMen = async () => {
                await deliveryMan.ValidateOrRejectDeliveryMen(
                    String(archivesDeliveryMen?.id),
                    null,
                    infoSeller?.token,
                );
                GetInfoScreen();
            };
            const GoToList = async () => {
                setIdScreen('list');
            };
            const onValidateDeliveryMen = async () => {
                if (checkedProofOfResidence && checkedLicenseVehicle) {
                    await deliveryMan.ValidateOrRejectDeliveryMen(
                        String(archivesDeliveryMen?.id),
                        true,
                        infoSeller?.token,
                    );
                    GetInfoScreen();
                }
            };

            // renders
            const RenderForm = () => (
                infoDeliveryMen.slice(0, 9).map((v) => {
                    if (v.value && v.label !== 'Complemento:') {
                        return (
                            <>
                                <TitleDefault fontWeight="bold" marginBottom="10px">
                                    {v.label}
                                    <TitleDefault fontWeight="normal" marginLeft="5px">{v.value}</TitleDefault>
                                </TitleDefault>
                            </>
                        );
                    }
                    if (v.label === 'Complemento:') {
                        return (
                            <TitleDefault fontWeight="bold" marginBottom="10px">
                                {String(v.value).split('/')[0] ? (
                                    <>
                                        Número:
                                        <TitleDefault fontWeight="normal" marginLeft="5px">{String(v.value).split('/')[0]}</TitleDefault>
                                    </>
                                ) : null}
                                {String(v.value).split('/')[1] ? (
                                    <>
                                        <TitleDefault fontWeight="bold" marginLeft="20px">Complemento:</TitleDefault>
                                        <TitleDefault fontWeight="normal" marginLeft="5px">{String(v.value).split('/')[1]}</TitleDefault>
                                    </>
                                ) : null}
                            </TitleDefault>
                        );
                    }

                    return null;
                })
            );
            const RenderFormStatistics = () => (
                infoDeliveryMen.slice(-6).map((v) => {
                    const onChangeSellerLinkDeliveryMen = async (
                        txt: React.ChangeEvent<HTMLSelectElement>,
                    ) => {
                        const FormatSplit = txt.target.value.split(',');
                        setSellerSelect(txt.target.value);
                        deliveryMan.ChangedSellerLinkDeliveryMen(
                            String(archivesDeliveryMen?.id),
                            FormatSplit[0] || '',
                            infoSeller?.token,
                        );
                    };
                    if (v.label === 'Estabelecimento:') {
                        return (
                            <ContentDeliveryMen flexDirection="row" marginTop="10px">
                                <TitleDefault fontWeight="bold" fontSize="11px">Estabelecimento</TitleDefault>
                                <ContainerSelect
                                    value={sellerSelect || String(v.value)}
                                    onChange={onChangeSellerLinkDeliveryMen}
                                >
                                    <Option value={`${0},TODOS`}>TODOS</Option>
                                    {allSellers.map((allInfoSeller) => (
                                        <Option value={`${allInfoSeller.id},${allInfoSeller.name}`}>{allInfoSeller.name}</Option>
                                    ))}
                                </ContainerSelect>
                            </ContentDeliveryMen>
                        );
                    }
                    return (
                        <ContentDeliveryMen flexDirection="row" marginTop="10px">
                            <TitleDefault fontWeight="bold" fontSize="11px">{v.label}</TitleDefault>
                            <ContainerBoxInfoDeliveryMen>
                                <TitleResultStatistics>{v.value}</TitleResultStatistics>
                            </ContainerBoxInfoDeliveryMen>
                        </ContentDeliveryMen>
                    );
                })
            );
            const RenderButtons = () => {
                if (idScreen === 'validationDeliveryMen') {
                    const RenderBackground = () => {
                        if (checkedProofOfResidence && checkedLicenseVehicle) {
                            return colors.basic.green;
                        }

                        return colors.theme.grayIntense;
                    };
                    return (
                        <ContainerButtonAction>
                            <ButtonAuth onClick={onValidateDeliveryMen} label="VALIDAR USUÁRIO" colorButton={RenderBackground()} colorText={colors.theme.backgroundDrawer} />
                            <ButtonAuth onClick={onRejectDeliveryMen} label="REJEITAR USUÁRIO" colorButton={colors.theme.backgroundButtonLogin} colorText={colors.basic.white} />
                            <ButtonAuth onClick={GoToList} label="VOLTAR" colorButton={null} colorText={null} />
                        </ContainerButtonAction>
                    );
                }
                if (idScreen === 'viewDeliveryMen') {
                    return (
                        <ContainerBoxDeliveryMen padding="5px 5px 5px 5px" width="100%">
                            <TitleDefault fontWeight="bold">Estatísticas do Entregador</TitleDefault>
                            {RenderFormStatistics()}
                        </ContainerBoxDeliveryMen>
                    );
                }
                return null;
            };
            return (
                <ContainerDeliveryMen visibleItem={visibleDrawer}>
                    <TitleScreen>{idScreen === 'viewDeliveryMen' ? `Entregador - ${infoDeliveryMen[0].value}` : 'Validar - Usuário'}</TitleScreen>
                    {idScreen !== 'viewDeliveryMen' ? (
                        <ContainerBox justifyContent="flex-end">
                            {RenderButtons()}
                        </ContainerBox>
                    ) : null}
                    <ContentDeliveryMen flexDirection="row">
                        <ContainerBoxDeliveryMen>
                            <ContainerPointer
                                existPhoto={archivesDeliveryMen?.photo || logoAuth}
                            >
                                <Logo background={archivesDeliveryMen?.photo || logoAuth}>
                                    <ContainerCamera
                                        existPhoto={archivesDeliveryMen?.photo || logoAuth}
                                    >
                                        <ImgCamera src={camera} />
                                    </ContainerCamera>
                                </Logo>
                            </ContainerPointer>
                            <TitleDetailsUser>Detalhes do usuário</TitleDetailsUser>
                            {RenderForm()}
                        </ContainerBoxDeliveryMen>
                        <ContainerBoxDeliveryMen width={idScreen === 'viewDeliveryMen' ? '34%' : '58%'}>
                            <ContainerDataPersonalDeliveryMen justifyContent="space-evenly">
                                <PhotoArchives
                                    background={archivesDeliveryMen?.proof_of_residence}
                                    onClick={onCheckedProofOfResidence}
                                >
                                    {checkedProofOfResidence ? (
                                        <BackgroundCheck>
                                            <Checked src={check} />
                                        </BackgroundCheck>
                                    ) : null}
                                </PhotoArchives>
                                <PhotoArchives
                                    background={archivesDeliveryMen?.license_vehicle}
                                    onClick={onCheckedLicenseVehicle}
                                >
                                    {checkedLicenseVehicle ? (
                                        <BackgroundCheck>
                                            <Checked src={check} />
                                        </BackgroundCheck>
                                    ) : null}
                                </PhotoArchives>
                            </ContainerDataPersonalDeliveryMen>
                        </ContainerBoxDeliveryMen>
                        {idScreen === 'viewDeliveryMen' ? (
                            <ContainerBox justifyContent="flex-start">
                                {RenderButtons()}
                            </ContainerBox>
                        ) : null}
                    </ContentDeliveryMen>
                </ContainerDeliveryMen>
            );
        }

        return null;
    };

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent
                        visibleDrawer={visibleDrawer}
                        setVisibleDrawer={setVisibleDrawer}
                    />
                    {RenderScreen()}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default DeliveryManPage;
