/* eslint-disable max-len */
// libraries
import React from 'react';
import { addHours, lightFormat } from 'date-fns';
import { GoogleMap, Marker } from '@react-google-maps/api';

// js
import colors from '../../../../styles/colors';
import {
    ContainerShadow,
    ContainerFilters,
    TextDefault,
    ContainerSeller,
    PhotoSeller,
    // ContainerEmpty,
    ButtonStatus,
    // ContainerInfo,
    // ContainerHeader,
    // TextHeaderInProgress,
    ContainerFrame,
    TitleLabel,
    Input,
    // Button,
    ContainerFrameMotoboy,
    ContainerInputsMotoboy,
    PhotoMotoboy,
    ContainerQueue,
    ContainerBodyQueue,
    TitleHeader,
    // HeaderQueue,
    ContainerChecked,
    Checked,
    ContainerWarning,
    ImgWarning,
    TextWarning,
    Container,
    ContainerAdjust,
    TitleDetailsUser,
    ContainerAdjustButtons,
    SeparatorInfos,
} from './deliveryInProgressStyles';
import { logoAuth, warning } from '../../../../assets/index';
import deliveryApi from '../../../../services/delivery';
import deliveryFunctions from '../../deliveryFunctions';

// interfaces
import { DataOrdersInterfaces, DataSellerInterface } from '../../../../interfaces';
import { DeliveryInterfaces } from '../../deliveryInterfaces';
import ButtonAuth from '../../../Auth/components/Button';
import { updateHistoryRoutes } from '../../../../store/modules/seller/actions';

const DeliveryInProgress = (
    idFilter: '' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS',
    allDeliveryInProgress: Array<DataOrdersInterfaces>,
    oneOrder: Array<DataOrdersInterfaces>,
    setOneOrder: (v: Array<DataOrdersInterfaces>) => void,
    setIdTab: (v: number) => void,
    isLoaded: boolean,
    infoSeller: DataSellerInterface,
    setInputs: (v: DeliveryInterfaces) => void,
    setPickers: (v: { back: string, formOfPayment: string }) => void,
    setSwitchs: (v: { savedRegister: boolean, confirmOrder: boolean }) => void,
    distanceMotoboyBetweenSeller: number,
    idQueue: number,
    setIdQueue: (v: number) => void,
    setModalIsOpen: (v: boolean) => void,
    setQueueActual: (v: DataOrdersInterfaces) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    historyRoutes: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dispatch: any,
): React.ReactElement => {
    // api
    async function onAcceptOrder() {
        const TradeStatus = await deliveryApi.EditStatusOrders(
            'delivering',
            oneOrder[0].id,
            infoSeller?.token,
        );
        if (TradeStatus.status === 200) {
            deliveryFunctions.GetInfoOrder(
                oneOrder[0].id,
                idFilter,
                infoSeller,
                setOneOrder,
                setIdQueue,
            );
            // FIXME - Adicionar modal de exibição de avisos.
            // eslint-disable-next-line no-alert
            alert('Pedido liberado!');
        }
    }

    async function onFinishRun() {
        // finalizar corrida
        const CancelRun = await deliveryApi.FinishRun('finished', oneOrder[0].id, infoSeller?.token);
        if (CancelRun.status === 200) {
            // FIXME - Adicionar modal de exibição de avisos.
            // eslint-disable-next-line no-alert
            alert('Corrida finalizada!');
        }
    }

    async function onCancelOrder(subStatus?: string) {
        // cancelar corrida
        const CancelRun = await deliveryApi.FinishRun('waiting', oneOrder[0].id, infoSeller?.token);
        if (CancelRun.status === 200) {
            if (subStatus === 'delivering') {
                // eslint-disable-next-line no-alert
                alert('Corrida pré-cancelada! Aguarde o motoboy chegar até seu estabelecimento e finalize a corrida.');
            } else {
                // setOneOrder([]);
                // FIXME - Adicionar modal de exibição de avisos.
                // eslint-disable-next-line no-alert
                alert('Corrida cancelada!');
            }
        }
    }

    // methods
    function getLabelStatus(subStatus: 'seeking' | 'delivering' | 'returning' | 'devolution') {
        if (subStatus === 'seeking') {
            return 'Buscando Pedido';
        }
        if (subStatus === 'delivering') {
            return 'Realizando Entrega';
        }
        if (subStatus === 'devolution') {
            return 'Em devolução';
        }
        return 'Voltando para Loja';
    }

    // renders
    function RenderInput(label: string, value: string) {
        return (
            <ContainerFrame>
                <TitleLabel>{label}</TitleLabel>
                <Input
                    value={value}
                    disabled
                />
            </ContainerFrame>
        );
    }

    const RenderStatus = (subStatus: 'run' | 'waiting' | 'seeking' | 'delivering' | 'returning' | 'devolution') => {
        if (subStatus === 'seeking') {
            return (
                <ButtonStatus backgroundColor={colors.theme.backgroundStatusSeeking}>
                    <TextDefault fontWeight="bold" color={colors.basic.yellow} fontSize="10px">{getLabelStatus(subStatus)}</TextDefault>
                </ButtonStatus>
            );
        }

        if (subStatus === 'delivering') {
            return (
                <ButtonStatus backgroundColor={colors.theme.backgroundStatusProgress}>
                    <TextDefault fontWeight="bold" color={colors.basic.blueLight} fontSize="10px">{getLabelStatus(subStatus)}</TextDefault>
                </ButtonStatus>
            );
        }

        if (subStatus === 'returning') {
            return (
                <ButtonStatus backgroundColor={colors.theme.backgroundStatusProgress}>
                    <TextDefault fontWeight="bold" color={colors.basic.blueLight} fontSize="10px">{getLabelStatus(subStatus)}</TextDefault>
                </ButtonStatus>
            );
        }

        if (subStatus === 'devolution') {
            return (
                <ButtonStatus backgroundColor={colors.theme.backgroundStatusProgress}>
                    <TextDefault fontWeight="bold" color={colors.basic.blueLight} fontSize="10px">{getLabelStatus(subStatus)}</TextDefault>
                </ButtonStatus>
            );
        }

        return (
            <ButtonStatus backgroundColor={colors.theme.backgroundStatusProgress}>
                <TextDefault fontWeight="bold" color={colors.basic.blueLight} fontSize="10px">Aguardando um entregador</TextDefault>
            </ButtonStatus>
        );
    };

    if (allDeliveryInProgress?.length && !oneOrder.length) {
        return (
            <ContainerShadow>
                <ContainerFilters height="50px" backgroundColor={colors.theme.backgroundContainerInfoTabs} borderBottom="2px solid rgba(0, 0, 0, 0.2)">
                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Nome do Cliente</TextDefault>
                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Bairro de Entrega</TextDefault>
                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Entregador</TextDefault>
                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Status</TextDefault>
                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Data</TextDefault>
                    <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Horário</TextDefault>
                </ContainerFilters>
                {allDeliveryInProgress.map((v, i) => {
                    const GetOrderActual = () => {
                        if (historyRoutes.slice(-1)[0] !== window.location.pathname) {
                            dispatch(updateHistoryRoutes(
                                [...historyRoutes, window.location.pathname],
                            ));
                        }
                        deliveryFunctions.GetInfoOrder(
                            v.id,
                            idFilter,
                            infoSeller,
                            setOneOrder,
                            setIdQueue,
                        );
                    };
                    return (
                        <ContainerFilters
                            height="50px"
                            key={v.id}
                            backgroundColor={colors.basic.white}
                            cursorActive
                            onClick={GetOrderActual}
                            borderBottom={allDeliveryInProgress?.lastIndexOf(allDeliveryInProgress[allDeliveryInProgress.length - 1]) !== i ? '1px solid rgba(0, 0, 0, 0.2)' : 'none'}
                            borderRadius={allDeliveryInProgress?.lastIndexOf(allDeliveryInProgress[allDeliveryInProgress.length - 1]) !== i ? '5px 5px 0px 0px' : '5px 5px 5px 5px'}
                        >
                            <ContainerSeller>
                                <TextDefault fontWeight="bold" fontSize="10px" color={colors.basic.black}>{v.name_client}</TextDefault>
                            </ContainerSeller>
                            <ContainerSeller>
                                <TextDefault fontWeight="bold" fontSize="10px" color={colors.theme.textTabs}>{v.neighborhood_client}</TextDefault>
                            </ContainerSeller>
                            <ContainerSeller>
                                <PhotoSeller src={v.user?.photo || logoAuth} />
                                <TextDefault fontWeight="bold" fontSize="10px" color={colors.theme.textTabs}>{v.user?.name || 'Entregador'}</TextDefault>
                            </ContainerSeller>
                            {RenderStatus(v.sub_status || v.status)}
                            <ContainerSeller>
                                <TextDefault fontWeight="bold" fontSize="10px" color={colors.theme.textTabs}>
                                    {`${lightFormat(addHours(new Date(v.created_at).getTime(), 3), 'dd.MM.yyyy')}`}
                                </TextDefault>
                            </ContainerSeller>
                            <ContainerSeller>
                                <TextDefault fontWeight="bold" fontSize="10px" color={colors.theme.textTabs}>
                                    {`${lightFormat(new Date(v.created_at).getTime(), 'HH:mm:ss')}`}
                                </TextDefault>
                            </ContainerSeller>
                        </ContainerFilters>
                    );
                })}
            </ContainerShadow>
        );
    }

    if (allDeliveryInProgress?.length && oneOrder.length && isLoaded) {
        const locationFocus = {
            lat: oneOrder[0].sub_status ? Number(oneOrder[0].user.latitude) : null,
            lng: oneOrder[0].sub_status ? Number(oneOrder[0].user.longitude) : null,
        };
        const styleMap = { minHeight: 300, width: '100%' };
        const AutoCompleteDelivery = async (type: 'trade' | 'add') => {
            if (type === 'trade') {
                await onCancelOrder(oneOrder[0].sub_status);
            }
            setIdTab(1);
            setInputs({
                cpfClient: oneOrder[0].cpf_client,
                nameClient: oneOrder[0].name_client,
                telephoneClient: oneOrder[0].telephone_client,
                cepClient: oneOrder[0].cep_client,
                cityClient: '',
                streetClient: '',
                neighborhoodClient: '',
                numberClient: oneOrder[0].number_client,
                complementClient: oneOrder[0].complement_client,
                referencePointClient: oneOrder[0].reference_point_client,
                observation: oneOrder[0].observation,
                distance: Number(oneOrder[0].distance) || 0,
                latitude: oneOrder[0].latitude,
                longitude: oneOrder[0].longitude,
                rateMotoboy: '',
                rateSystem: '',
                total: '',
                id: type === 'trade' ? null : oneOrder[0].id,
            });
            setPickers({
                back: oneOrder[0].back ? 'Sim' : 'Não',
                formOfPayment: oneOrder[0].form_of_payment,
            });
            setSwitchs({
                confirmOrder: false,
                savedRegister: oneOrder[0].saved_register_client,
            });
        };
        const AutoCompleteTradeDelivery = async () => {
            AutoCompleteDelivery('trade');
        };
        const AutoCompleteAddDelivery = async () => {
            AutoCompleteDelivery('add');
        };
        const OpenModalInfoDelivery = async () => {
            setModalIsOpen(true);
            const getOnlyQueue = oneOrder[0].queue?.find((v) => v.id === idQueue);
            setQueueActual(getOnlyQueue);
        };
        const DeleteOneDelivery = async () => {
            const ActionDelete = await deliveryApi.DeleteOneQueue(idQueue, infoSeller?.token);
            if (ActionDelete.status === 200) {
                // eslint-disable-next-line no-alert
                alert(ActionDelete?.message);
            }
        };
        return (
            <ContainerAdjust>
                <Container>
                    <TitleDetailsUser>Detalhes da entrega</TitleDetailsUser>
                    {RenderInput('Nome do cliente', oneOrder[0].name_client)}
                    {RenderInput('Telefone do cliente', oneOrder[0].telephone_client)}
                    {RenderInput('Haverá retorno', oneOrder[0].back ? 'Sim' : 'Não')}
                    {RenderInput('Endereço de entrega', oneOrder[0].address_client)}
                    {RenderInput('Observações', oneOrder[0].observation)}
                    <ContainerAdjustButtons>
                        {oneOrder[0].sub_status !== 'devolution' ? (
                            <>
                                <ButtonAuth onClick={AutoCompleteTradeDelivery} label="ALTERAR PEDIDO" colorButton={null} colorText={null} />
                                <ButtonAuth onClick={() => onCancelOrder(oneOrder[0].sub_status)} label="CANCELAR CORRIDA" colorButton={null} colorText={null} />
                            </>
                        ) : null}
                        {oneOrder[0].status === 'waiting' || (oneOrder[0].status === 'run' && oneOrder[0].sub_status === 'seeking') ? (
                            <ButtonAuth onClick={AutoCompleteAddDelivery} label="ADICIONAR OUTRO PEDIDO À ROTA" colorButton={null} colorText={null} />
                        ) : null}
                        {oneOrder[0].sub_status === 'devolution' && distanceMotoboyBetweenSeller <= 100 ? (
                            <ButtonAuth onClick={() => onCancelOrder()} label="FINALIZAR CANCELAMENTO" colorButton={null} colorText={null} />
                        ) : null}
                        {oneOrder[0].sub_status ? (
                            <>
                                {oneOrder[0].sub_status === 'seeking' && distanceMotoboyBetweenSeller <= 100 ? (
                                    <ButtonAuth onClick={onAcceptOrder} label="LIBERAR PARA ENTREGA" colorButton={null} colorText={null} />
                                ) : null}
                                {oneOrder[0].back && oneOrder[0].sub_status === 'returning' && distanceMotoboyBetweenSeller <= 100 ? (
                                    <ButtonAuth onClick={onFinishRun} label="FINALIZAR CORRIDA" colorButton={null} colorText={null} />
                                ) : null}
                            </>
                        ) : null}
                    </ContainerAdjustButtons>
                </Container>
                {oneOrder[0].sub_status || oneOrder[0].queue?.length ? (
                    <Container backgroundColor={colors.basic.white} padding="0px 0px 0px 0px">
                        {oneOrder[0].sub_status ? (
                            <>
                                <SeparatorInfos>
                                    <GoogleMap
                                        center={locationFocus}
                                        zoom={15}
                                        mapContainerStyle={styleMap}
                                    >
                                        <Marker position={locationFocus} />
                                    </GoogleMap>
                                </SeparatorInfos>
                                <SeparatorInfos padding="10px 10px 10px 10px">
                                    <TitleDetailsUser>Detalhes da entrega</TitleDetailsUser>
                                    <ContainerFrameMotoboy>
                                        <PhotoMotoboy src={oneOrder[0].user.photo || logoAuth} />
                                        <ContainerInputsMotoboy>
                                            {RenderInput('Nome do entregador:', oneOrder[0].user.name)}
                                            {RenderInput('Telefone do entregador:', oneOrder[0].user.telephone)}
                                            {RenderInput('Status do pedido:', getLabelStatus(oneOrder[0].sub_status))}
                                            {RenderInput('Ranking:', oneOrder[0].user.rank)}
                                            {RenderInput('Avaliação:', String(oneOrder[0].user.avaliation))}
                                        </ContainerInputsMotoboy>
                                    </ContainerFrameMotoboy>
                                </SeparatorInfos>
                            </>
                        ) : null}
                        {oneOrder[0].queue?.length ? (
                            <SeparatorInfos>
                                <ContainerQueue>
                                    <TitleDetailsUser>Fila de pedidos</TitleDetailsUser>
                                    <ContainerBodyQueue>
                                        <TitleHeader color={colors.basic.gray}>
                                            Nome do Cliente
                                        </TitleHeader>
                                        <TitleHeader color={colors.basic.gray}>Horário</TitleHeader>
                                        <TitleHeader color={colors.basic.gray}>Status</TitleHeader>
                                        <TitleHeader color={colors.basic.gray}>
                                            Bairro de Entrega
                                        </TitleHeader>
                                        <TitleHeader />
                                    </ContainerBodyQueue>
                                    {oneOrder[0].queue?.map((v: any) => {
                                        const formatData = lightFormat(new Date(v.created_at), 'HH:mm');
                                        const ValidationStatus = () => {
                                            if (v?.already_canceled) {
                                                return 'Pré-Cancelado';
                                            }
                                            if (v?.finished_at) {
                                                return 'Concluído';
                                            }
                                            const [positionQueue] = oneOrder[0].queue.filter((q) => !q.finished_at && !q.already_canceled);
                                            if (v.status === 'run' && positionQueue?.id === v.id) {
                                                return 'Em andamento';
                                            }

                                            return 'Aguardando a fila';
                                        };
                                        return (
                                            <ContainerBodyQueue>
                                                <TitleHeader>{v?.name_client}</TitleHeader>
                                                <TitleHeader>{formatData}</TitleHeader>
                                                <TitleHeader>
                                                    {ValidationStatus()}
                                                </TitleHeader>
                                                <TitleHeader>{v?.neighborhood_client}</TitleHeader>
                                                {v?.finished_at || v?.sub_status === 'devolution' ? (
                                                    <TitleHeader />
                                                ) : (
                                                    <TitleHeader justifyContent="center">
                                                        <ContainerChecked
                                                            onClick={() => setIdQueue(v?.id)}
                                                        >
                                                            {/* eslint-disable-next-line max-len */}
                                                            <Checked color={idQueue === v?.id ? colors.basic.red : colors.basic.white} />
                                                        </ContainerChecked>
                                                    </TitleHeader>
                                                )}
                                            </ContainerBodyQueue>
                                        );
                                    })}
                                </ContainerQueue>
                                {oneOrder[0].queue?.filter((v) => !v?.finished_at && v?.sub_status !== 'devolution')?.length ? (
                                    <ContainerAdjustButtons>
                                        <ButtonAuth onClick={OpenModalInfoDelivery} label="VISUALIZAR PEDIDO" colorButton={null} colorText={null} />
                                        <ButtonAuth onClick={DeleteOneDelivery} label="EXCLUIR PEDIDO" colorButton={null} colorText={null} />
                                    </ContainerAdjustButtons>
                                ) : null}
                            </SeparatorInfos>
                        ) : null}
                    </Container>
                ) : null}
            </ContainerAdjust>
        );
    }

    return (
        <ContainerWarning>
            <ImgWarning src={warning} />
            <TextWarning>Você não possui entregas em andamento no momento.</TextWarning>
        </ContainerWarning>
    );
};

export default DeliveryInProgress;
