// js
import { DeliveryInterfaces } from './deliveryInterfaces';
import consts from '../../utils/consts';

const allTabs = [
    {
        id: 1,
        title: 'Nova entrega',
    },
    {
        id: 2,
        title: 'Em andamento',
    },
    {
        id: 3,
        title: 'Histórico de entregas',
    },
];

const initialInputs: DeliveryInterfaces = {
    cpfClient: '',
    nameClient: '',
    telephoneClient: '',
    cepClient: '',
    streetClient: '',
    cityClient: '',
    numberClient: '',
    complementClient: '',
    referencePointClient: '',
    neighborhoodClient: '',
    observation: '',
    // price: '',
    // defaults
    rateMotoboy: '',
    rateSystem: '',
    total: '',
    distance: 0,
    latitude: 0,
    longitude: 0,
};

const initialPickers = {
    back: 'Não',
    formOfPayment: 'Crédito',
};

const initialSwitchs = {
    savedRegister: false,
    confirmOrder: false,
};

const { baseUrl } = consts;

export default {
    allTabs,
    initialInputs,
    initialPickers,
    initialSwitchs,
    baseUrl,
};
