// libraries
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
// import px2vw from '../../../../utils/functions';

// js
import colors from '../../../../styles/colors';

// interfaces
interface ContainerPhoto {
    background: string,
}

interface TextActionNavigationInterface {
    marginTop?: string,
    color?: string,
    fontWeight?: string,
    fontStyle?: string,
    cursor?: boolean
}

export const Logo = styled.img`
    height: 75px;
    width: 128px;
    margin-left: 20px;
`;

export const Header = styled.text`
    color: ${colors.basic.white};
    font-size: 36px;
    font-weight: 900;
`;

export const ContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 100%;
    @media (max-width: 800px) {
        align-items: center;
        justify-content: center;
        margin-left: 0px;
    }
`;

export const TextInfo = styled.div`
    color: ${colors.basic.white};
    font-size: 15px;
    font-weight: normal;
    margin-top: 10px;
`;

export const ContainerInfoHeader = styled.div`
    align-items: center;
    display: flex;
    margin-top: 10px;
    @media (max-width: 800px) {
        justify-content: space-evenly;
        width: 100%;
    }
`;

export const ContainerPhoto = styled.div<ContainerPhoto>`
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    height: 100vh;
    width: 100vw;
`;

export const Container = styled.div`
    background: ${colors.basic.black};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Home = styled.div`
    align-items: center;
    background-color: ${colors.basic.black};
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    min-height: ${isMobile ? '-webkit-fill-available' : '100vh'};
    min-width: 100vw;
`;

export const TextActionNavigation = styled.h4<TextActionNavigationInterface>`
    color: ${(props) => (props.color || colors.basic.white)};
    cursor: ${(props) => (props.cursor ? 'pointer' : 'auto')};
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    font-style: ${(props) => (props.fontStyle || 'normal')};
`;

export const TextLinkingNavigation = styled.a`
    color: ${colors.theme.gray};
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px;
`;
export const ContainerBoxAuth = styled.div`
    background-color: ${colors.theme.backgroundBoxAuth};
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
    width: 30%;
    @media (max-width: 800px) {
        width: 80%;
    }
`;

export const HeaderInput = styled.text`
    color: ${colors.basic.white};
    margin-top: 10px;
    font-weight: normal;
    font-size: 12px;
`;

export const InputAuthStyles = styled.input`
    color: ${colors.basic.black};
    background-color: ${colors.basic.white};
    border: none;
    border-radius: 5px;
    min-height: 40px;
    padding-left: 15px;
    width: 100%;
    margin-top: 10px;
`;

export const TextButtonAuthStyles = styled.h4`
    color: ${(props) => (props.color || colors.basic.white)};
    font-weight: 500;
    font-size: 14px;
    @media (max-width: 800px) {
        font-size: 13px;
    }
`;

export const ButtonAuthStyles = styled.button`
    cursor: pointer;
    background-color: ${(props) => (props.color || colors.theme.backgroundButtonLogin)};
    border-radius: 4px;
    border: none;
    margin-top: 10px;
    min-height: 64px;
    margin-right: 10px;
    transition-duration: 400ms;
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    @media (max-width: 800px) {
        min-height: 50px;
        margin-right: 0px;
    }
`;
