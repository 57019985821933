// libraries
import React from 'react';

// js
import { InputAuthStyles } from '../screens/Login/loginStyles';

// interfaces
interface Props {
    // eslint-disable-next-line react/require-default-props
    actionButton?: any,
    placeholder: string,
    value: string,
    type: string,
    onChange: (txt: React.ChangeEvent<HTMLInputElement>) => void,
}

const InputAuth: React.FC<Props> = ({
    actionButton,
    placeholder,
    value,
    type,
    onChange,
}: Props) => {
    const onChangeKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13 && actionButton) {
            actionButton();
        }
    };

    return (
        <InputAuthStyles
            placeholder={placeholder}
            value={value}
            type={type}
            onChange={onChange}
            onKeyUp={onChangeKeyUp}
        />
    );
};

export default InputAuth;
