// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';
import getPreciseDistance from 'geolib/es/getPreciseDistance';

// js
import {
    Container,
    ContainerInner,
    Home,
    TitleScreen,
    ContentDelivery,
    ContainerDelivery,
    ContainerTabs,
    TabOption,
    TextTabOption,
    ContainerInfoTabs,
    ContainerAdjust,
} from './deliveryStyles';
import { DataOrdersInterfaces } from '../../interfaces';
import { RootState } from '../../store';
import deliveryFunctions from './deliveryFunctions';
import deliveryConstants from './deliveryConstants';
import DeliveryComponents from './DeliveryComponents';
import functions from '../../utils/functions';
import deliveryApi from '../../services/delivery';
import { updateHistoryRoutes } from '../../store/modules/seller/actions';

// components
import MenuComponent from '../../components/Menu/MenuComponent';
import NewDelivery from './components/NewDelivery/NewDelivery';
import HistoryDelivery from './components/HistoryDelivery/HistoryDelivery';
import DeliveryInProgress from './components/DeliveryInProgress/DeliveryInProgress';
import ModalComponent from '../../components/Modal/ModalComponent';
import ButtonAuth from '../Auth/components/Button';
import LoadingComponent from '../../components/Loading/LoadingComponent';

const DeliveryPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const infoSeller = useSelector((state: RootState) => state.seller.seller);
    const infoExemption = useSelector((state: RootState) => state.seller.exemption);
    const historyRoutes = useSelector((state: RootState) => state.seller.historyRoutes);
    const qtdDeliveriesWithRun = useSelector(
        (state: RootState) => state.seller.qtdDeliveriesWithRun,
    );

    // constants
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyBzbROLngDf4N3410d6EWva7050d2q9-Y8',
    });

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [idTab, setIdTab] = useState(1);
    const [idFilter, setIdFilter] = useState<'' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS'>('');
    const [allOrders, setAllOrders] = useState<Array<DataOrdersInterfaces>>([]);
    const [allDeliveryInProgress, setAllDeliveryInProgress] = useState<any>(qtdDeliveriesWithRun);
    const [oneOrder, setOneOrder] = useState<any>([]);
    const [oneOrderHistory, setOneOrderHistory] = useState<Array<DataOrdersInterfaces>>([]);
    const [distanceMotoboyBetweenSeller, setDistanceMotoboyBetweenSeller] = useState<number>(0);
    const [inputs, setInputs] = useState(deliveryConstants.initialInputs);
    const [pickers, setPickers] = useState(deliveryConstants.initialPickers);
    const [switchs, setSwitchs] = useState(deliveryConstants.initialSwitchs);
    const [idQueue, setIdQueue] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [queueActual, setQueueActual] = useState<DataOrdersInterfaces | null>(null);
    const [openModalEvaluation, setOpenModalEvaluation] = useState(false);
    const [starSelected, setStarSelected] = useState(0);
    const [idOrderMain, setIdOrderMain] = useState(0);
    const [existQueue, setExistQueue] = useState(true);
    const [loading, setLoading] = useState(false);

    // methods
    function getTypeWithLabel(label: string) {
        if (label === 'Crédito') {
            return 'credit';
        }
        if (label === 'Débito') {
            return 'debit';
        }
        if (label === 'Dinheiro') {
            return 'money';
        }
        return 'paid'; // 'Já está pago'
    }
    async function onCreateOrder() {
        if (!switchs.confirmOrder) {
            return;
        }

        const dataCreate = {
            ...inputs,
            addressClient: `${inputs.streetClient}, ${inputs.numberClient} - ${inputs.neighborhoodClient}, ${inputs.cityClient}`,
            back: pickers.back === 'Sim',
            rateMotoboy: functions.priceUnmask(inputs.rateMotoboy),
            total: functions.priceUnmask(inputs.total),
            cpfClient: inputs.cpfClient.replace(/[^0-9]/g, ''),
            savedRegisterClient: switchs.savedRegister,
            formOfPayment: getTypeWithLabel(pickers.formOfPayment),
        };
        const result = await deliveryApi.CreateOrders(
            dataCreate,
            infoSeller?.typeAccount === 'sub_sellers' ? infoSeller?.id_operator : infoSeller?.id,
            infoSeller?.typeAccount,
            infoSeller?.token,
        );
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        alert(result.message);
        if (result.status === 201) {
            setInputs(deliveryConstants.initialInputs);
            setPickers(deliveryConstants.initialPickers);
            setSwitchs(deliveryConstants.initialSwitchs);
        }
    }

    // useEffect
    useEffect(() => {
        setTimeout(() => {
            deliveryFunctions.GetInfoScreen(
                idFilter,
                setAllOrders,
                infoSeller,
            );
        }, 500);
    }, [idFilter]);

    useEffect(() => {
        setAllDeliveryInProgress(qtdDeliveriesWithRun);
    }, [qtdDeliveriesWithRun]);

    useEffect(() => {
        if (oneOrder.length) {
            const GetOrderId = (allDeliveryInProgress || []).filter(
                (v: any) => Number(v.nextQueue.order.id) === Number(
                    oneOrder[0].id,
                ),
            );
            if (GetOrderId.length) {
                setOneOrder([GetOrderId[0]?.nextQueue?.order] || []);
                if (
                    GetOrderId[0]?.nextQueue?.user?.latitude
                    && GetOrderId[0]?.nextQueue?.user?.longitude
                ) {
                    const distance = getPreciseDistance(
                        {
                            latitude: GetOrderId[0]?.nextQueue?.user?.latitude,
                            longitude: GetOrderId[0]?.nextQueue?.user?.longitude,
                        },
                        { latitude: infoSeller?.latitude, longitude: infoSeller?.longitude },
                    );
                    setDistanceMotoboyBetweenSeller(Number(distance?.toFixed(1) || 0));
                }
            } else {
                setOneOrder([]);
            }
        }
    }, [allDeliveryInProgress]);

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            if (inputs.cityClient && inputs.streetClient && inputs.numberClient) {
                setLoading(true);
                await deliveryFunctions.getDistanceForCalc(
                    inputs,
                    setInputs,
                    pickers,
                    infoSeller,
                    infoExemption,
                );
                setLoading(false);
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [inputs.cityClient, inputs.streetClient, inputs.numberClient, pickers.back]);

    useEffect(() => {
        if (inputs.cepClient.length === 9) {
            // https://viacep.com.br/
            const cepFormat = inputs.cepClient.replace('-', '');
            const url = `https://viacep.com.br/ws/${cepFormat}/json/`;
            // eslint-disable-next-line no-undef
            const ajax = new XMLHttpRequest();
            ajax.open('GET', url, true);
            ajax.send();
            ajax.onreadystatechange = () => {
                if (ajax.readyState === 4 && ajax.status === 200 && ajax.responseText) {
                    const data = JSON.parse(ajax.responseText);
                    if (data?.logradouro && data?.bairro) {
                        setInputs({
                            ...inputs,
                            streetClient: data.logradouro,
                            neighborhoodClient: data.bairro,
                            cityClient: data.localidade,
                        });
                    }
                }
            };
        }
    }, [inputs.cepClient]);

    useEffect(() => {
        if (inputs.cpfClient.length === 14) {
            setTimeout(() => {
                deliveryFunctions.ActionGetForOrder(
                    inputs,
                    setInputs,
                    infoSeller,
                );
            }, 500);
        }
    }, [inputs.cpfClient]);

    useEffect(() => {
        if (historyRoutes.slice(-1)[0] === window.location.pathname) {
            // eslint-disable-next-line max-len
            const removeLastItem = historyRoutes.length === 1 ? [] : historyRoutes.slice(0, historyRoutes.length - 1);
            dispatch(updateHistoryRoutes(removeLastItem));
        }
        setInterval(() => {
            deliveryFunctions.GetInfoScreen(
                idFilter,
                setAllOrders,
                infoSeller,
            );
        }, 5000);
    }, []);

    // renders
    const RenderAllTabs = () => (
        deliveryConstants.allTabs.map((v) => {
            const TradeIdTab = () => {
                if (v.id === 1 || v.id === 3) {
                    setOneOrder([]);
                    setOneOrderHistory([]);
                }
                if (v.id === 2 && oneOrder?.length) {
                    setOneOrder([]);
                }
                setExistQueue(true);
                setIdTab(v.id);
            };
            return (
                <TabOption onClick={TradeIdTab} focus={idTab === v.id}>
                    <TextTabOption>{v.title}</TextTabOption>
                </TabOption>
            );
        })
    );

    const RenderInfoTabs = () => {
        if (idTab === 1) {
            return (
                <>
                    <ContainerAdjust>
                        {NewDelivery(
                            inputs,
                            pickers,
                            switchs,
                            setInputs,
                            setPickers,
                            setSwitchs,
                        )}
                    </ContainerAdjust>
                    <ButtonAuth onClick={onCreateOrder} label="CRIAR PEDIDO" colorButton={null} colorText={null} />
                </>
            );
        }
        if (idTab === 2) {
            return DeliveryInProgress(
                idFilter,
                allDeliveryInProgress,
                oneOrder,
                setOneOrder,
                setIdTab,
                isLoaded,
                infoSeller,
                setInputs,
                setPickers,
                setSwitchs,
                distanceMotoboyBetweenSeller,
                idQueue,
                setIdQueue,
                setModalIsOpen,
                setQueueActual,
                historyRoutes,
                dispatch,
            );
        }
        if (idTab === 3) {
            return HistoryDelivery(
                idFilter,
                setIdFilter,
                allOrders,
                oneOrderHistory,
                setOneOrderHistory,
                historyRoutes,
                dispatch,
                openModalEvaluation,
                setOpenModalEvaluation,
                infoSeller,
                existQueue,
                setExistQueue,
                setIdOrderMain,
            );
        }

        return null;
    };

    const RenderScreen = () => (
        <ContainerDelivery visibleItem={visibleDrawer}>
            <TitleScreen>{oneOrder[0]?.id ? `Entregas - #${oneOrder[0].id}` : 'Entregas'}</TitleScreen>
            <ContentDelivery>
                <ContainerTabs>
                    {RenderAllTabs()}
                </ContainerTabs>
                <ContainerInfoTabs>
                    {RenderInfoTabs()}
                </ContainerInfoTabs>
            </ContentDelivery>
        </ContainerDelivery>
    );

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent
                        visibleDrawer={visibleDrawer}
                        setVisibleDrawer={setVisibleDrawer}
                    />
                    {RenderScreen()}
                </ContainerInner>
                <ModalComponent
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    RenderBodyModal={() => DeliveryComponents.RenderBodyModal(queueActual)}
                />
                <ModalComponent
                    modalIsOpen={openModalEvaluation}
                    setModalIsOpen={setOpenModalEvaluation}
                    // eslint-disable-next-line max-len
                    RenderBodyModal={() => DeliveryComponents.RenderEvaluationBodyModal(idOrderMain, setExistQueue, oneOrderHistory, setOneOrderHistory, starSelected, setStarSelected, infoSeller, setOpenModalEvaluation)}
                />
                <LoadingComponent open={loading} setOpen={setLoading} label="Calculando valores da entrega..." />
            </Home>
        </Container>
    );
};

export default DeliveryPage;
