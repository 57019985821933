// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

export const TextDefault = styled.text`
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
    color: ${colors.basic.black};
`;

export const ContainerAdjustLoading = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
`;
