// FIXME Função para redimensionar os textos, caso precise
const px2vw = (size: number, width = 1440): string => `${(size / width) * 100}vw`;

const toPrice = (price: number, scale?: number): string => (
    String(((price * 100) / 100).toFixed(scale)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
);

const moneyMask = (value: string): string => {
    const valueFormat = value.replace('.', '').replace(',', '').replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(valueFormat) / 100,
    );

    return `R$ ${result}`;
};

const priceMask = (price: number): string => {
    const mask = `R$ ${String(((price * 100) / 100).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
    return mask;
};

const dateMask = (input: string, v: string): string => {
    const allFormat = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/', ''];
    let value = v;
    if (v.length >= 0 && v.length < 5) {
        if (input.length === 3 && v.length === 2) {
            value = v;
        } else if (v.length === 2) {
            value = `${v}/`;
        } else if (v.length === 3 && input.length === 2) {
            value = `${input}/${v.slice(-1)}`;
        }
    } else if (v.length >= 5) {
        if (input.length === 6 && v.length === 5) {
            value = v;
        } else if (v.length === 5) {
            value = `${v}/`;
        } else if (v.length === 6 && input.length === 5) {
            value = `${input}/${v.slice(-1)}`;
        }
    }

    return allFormat.includes(v.slice(-1)) ? value.substring(0, 10) : input;
};

const validCard = (input: string, v: string): string => {
    const allFormat = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/', ''];
    let value = v;
    if (input.length === 3 && v.length === 2) {
        value = v;
    } else if (v.length === 2) {
        value = `${v}/`;
    } else if (v.length === 3 && input.length === 2) {
        value = `${input}/${v.slice(-1)}`;
    }
    return allFormat.includes(v.slice(-1)) ? value.substring(0, 7) : input;
};

const priceUnmask = (value: string): number => {
    const valueFormat = Number(value.replace('R$', '').replace(/[.]/g, '').replace(',', '.'));
    return valueFormat;
};

const MaskPhone = (v: string): string => {
    let valueInitial = v?.replace(/\D/g, '').replace(/^0/, '') || '';
    if (valueInitial.length > 11) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (valueInitial.length > 7) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (valueInitial.length > 2) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (v?.trim() !== '') {
        valueInitial = valueInitial.replace(/^(\d*)/, '($1');
    }
    return valueInitial;
};

const MaskCep = (v: string): string => (
    v.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2').replace(/(-\d{3})\d+?$/, '$1')
);

const MaskIdentifier = (v: string): string => {
    const cpfFormatted = v?.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1-$2');
    return cpfFormatted;
};

const MaskCNPJ = (v: string): string => {
    const cnpjFormatted = v.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    return cnpjFormatted;
};

const GlobalRenderTickFormatX = (v: number): string => {
    if (v === 0) {
        return 'Janeiro';
    }
    if (v === 1) {
        return 'Fevereiro';
    }
    if (v === 2) {
        return 'Março';
    }
    if (v === 3) {
        return 'Abril';
    }
    if (v === 4) {
        return 'Maio';
    }
    if (v === 5) {
        return 'Junho';
    }
    if (v === 6) {
        return 'Julho';
    }
    if (v === 7) {
        return 'Agosto';
    }
    if (v === 8) {
        return 'Setembro';
    }
    if (v === 9) {
        return 'Outubro';
    }
    if (v === 10) {
        return 'Novembro';
    }
    if (v === 11) {
        return 'Dezembro';
    }
    return '';
};

export default {
    px2vw,
    toPrice,
    moneyMask,
    priceMask,
    priceUnmask,
    MaskPhone,
    MaskCep,
    MaskIdentifier,
    MaskCNPJ,
    GlobalRenderTickFormatX,
    dateMask,
    validCard,
};
