// libraries
import React from 'react';
import Modal from 'react-modal';

// js
import {
    ContainerModal,
    ContainerBoxModal,
    TitleScreen,
    ContainerShadow,
    ContainerFilters,
    TextDefault,
    ContainerSeller,
    PhotoSeller,
    TextDisabledFixed,
    ContainerClosedModal,
    TextClosedModal,
    ContainerHeaderModal,
} from '../establishmentStyles';
import { logoAuth } from '../../../assets/index';
import colors from '../../../styles/colors';
import sellers from '../../../services/sellers';
import { DataSellerInterface } from '../../../interfaces';

const ModalDeliveryManComponent = (
    isOpen: boolean,
    listMotoboyFixed: Array<any>,
    infoSeller: DataSellerInterface,
    GetListMotoboy: () => void,
    setModalDeliveryMan: (v: boolean) => void,
    setIdSellerSelect: (v: number) => void,
): React.ReactElement => {
    // constants
    const modalStyles = {
        overlay: {
            backgroundColor: colors.theme.backgroundInputMedium,
        },
        content: {
            alignItems: 'center',
            backgroundColor: 'transparent',
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
        },
    };

    // methods
    const ClosedModal = () => {
        setModalDeliveryMan(false);
        setIdSellerSelect(0);
    };

    return (
        <Modal isOpen={isOpen} style={modalStyles}>
            <ContainerModal>
                <ContainerBoxModal>
                    <ContainerHeaderModal>
                        <TitleScreen>Mc Donalds - Entregadores Fixados</TitleScreen>
                        <ContainerClosedModal onClick={ClosedModal}>
                            <TextClosedModal>X</TextClosedModal>
                        </ContainerClosedModal>
                    </ContainerHeaderModal>
                    <ContainerShadow>
                        <ContainerFilters height="50px" backgroundColor={colors.theme.backgroundContainerInfoTabs} borderBottom="2px solid rgba(0, 0, 0, 0.2)">
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Entregador</TextDefault>
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Avaliação</TextDefault>
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Entregas Feitas</TextDefault>
                            <TextDefault fontWeight="bold" color={colors.theme.textTabs}>Desfixar</TextDefault>
                        </ContainerFilters>
                        {listMotoboyFixed.map((v, i) => {
                            // methods
                            const DisabledFixed = async () => {
                                const ActionDisabledFixed = await sellers.DisabledMotoboyFixed(
                                    v.id,
                                    infoSeller?.token,
                                );
                                if (ActionDisabledFixed.status === 200) {
                                    GetListMotoboy();
                                    // eslint-disable-next-line no-alert
                                    alert(ActionDisabledFixed?.message);
                                } else {
                                    // eslint-disable-next-line no-alert
                                    alert(ActionDisabledFixed?.message);
                                }
                            };
                            return (
                                <ContainerFilters
                                    height="50px"
                                    key={v.id}
                                    backgroundColor={colors.basic.white}
                                    borderBottom={listMotoboyFixed?.lastIndexOf(listMotoboyFixed[listMotoboyFixed.length - 1]) !== i ? '1px solid rgba(0, 0, 0, 0.2)' : 'none'}
                                    borderRadius={listMotoboyFixed?.lastIndexOf(listMotoboyFixed[listMotoboyFixed.length - 1]) !== i ? '5px 5px 0px 0px' : '5px 5px 5px 5px'}
                                >
                                    <ContainerSeller>
                                        <PhotoSeller src={v?.photo || logoAuth} />
                                        <TextDefault fontWeight="bold" fontSize="12px" color={colors.theme.textTabs}>{v?.name}</TextDefault>
                                    </ContainerSeller>
                                    <ContainerSeller justifyContent="center">
                                        <TextDefault fontWeight="bold" fontSize="12px" color={colors.theme.textTabs}>5 estrelas</TextDefault>
                                    </ContainerSeller>
                                    <ContainerSeller justifyContent="center">
                                        <TextDefault fontWeight="bold" fontSize="12px" color={colors.theme.textTabs}>{`${Number(v.qtdDeliveries)} ${Number(v.qtdDeliveries) > 1 ? 'entregas' : 'entrega'}`}</TextDefault>
                                    </ContainerSeller>
                                    <ContainerSeller justifyContent="center" onClick={DisabledFixed}>
                                        <TextDisabledFixed>Desfixar</TextDisabledFixed>
                                    </ContainerSeller>
                                </ContainerFilters>
                            );
                        })}
                    </ContainerShadow>
                </ContainerBoxModal>
            </ContainerModal>
        </Modal>
    );
};

export default ModalDeliveryManComponent;
