// libraries
import styled from 'styled-components';

// js
import colors from '../../../../styles/colors';

// interfaces
interface LabelInterface {
    size?: number,
    textAlign?: string,
    marginBottom?: string,
}

interface ContainerFrameInterfaces {
    divideInput: string,
}

interface ContainerInterfaces {
    height?: string,
}

// styles
export const Container = styled.div<ContainerInterfaces>`
    display: flex;
    flex-direction: column;
    width: 49%;
    height: ${(props) => (props.height || 'auto')};
    background-color: ${colors.theme.backgroundBoxCreateSeller};
    padding: 0px 10px 10px 10px;
    /* height: 60%;
    overflow-y: scroll; */
`;

export const ContainerSeparatorInputs = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const ContainerFrame = styled.div<ContainerFrameInterfaces>`
    flex-direction: column;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    width: ${(props) => (props.divideInput || '100%')};
`;

export const Input = styled.input`
    color: ${colors.theme.backgroundDrawer};
    background-color: ${colors.basic.white};
    border: none;
    border-radius: 5px;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    @media (max-width: 1200px) {
        font-size: 11px;
        height: 30px;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        height: 20px;
    }
`;

export const TitleLabel = styled.text<LabelInterface>`
    text-align: ${(props) => (props?.textAlign || 'start')};
    color: ${colors.theme.labelInputsCreateSeller};
    font-size: 15px;
    margin-bottom: ${(props) => (props.marginBottom || '5px')};

    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const Select = styled.select`
    color: ${colors.theme.backgroundDrawer};
    background-color: ${colors.basic.white};
    border: none;
    border-radius: 5px;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    @media (max-width: 1200px) {
        font-size: 11px;
        height: 30px;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        height: 20px;
    }
`;

export const Option = styled.option``;

export const TitleDetailsUser = styled.text`
    color: ${colors.basic.black};
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
        font-size: 18px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

export const ContainerSelectOptions = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    @media (max-width: 1200px) {
        height: 30px;
    }
    @media (max-width: 600px) {
        height: 20px;
    }
`;

export const SelectRadius = styled.div`
    border-radius: 10px;
    display: flex;
    cursor: pointer;
    background-color: ${colors.basic.white};
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-left: 10px;
    height: 20px;
    width: 20px;
`;

export const Checked = styled.div`
    background-color: ${colors.theme.backgroundButtonLogin};
    border-radius: 6px;
    cursor: pointer;
    height: 12px;
    width: 12px;
`;
