// js
import consts from '../utils/consts';
import { DataSellerInterface } from '../interfaces';

// constants
const { baseUrl } = consts;
const headerApplicationJson = {
    'Content-Type': 'application/json',
};

// interfaces
interface AuthInterfaces {
    status: number,
    result: { account: DataSellerInterface | null, token: string, tokenRefresh: string };
    message: string,
}

async function RecoverPasswordUsers(identifier: string): Promise<AuthInterfaces> {
    try {
        const data = {
            identifier,
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users/recoverPassword`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { account: null, token: '', tokenRefresh: '' },
            status: 500,
            message: '',
        };
    }
}

export default {
    RecoverPasswordUsers,
};
