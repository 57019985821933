// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface TabOptionInterfaces {
    focus: boolean,
}

interface ContainerDeliveryInterfaces {
    visibleItem?: boolean,
}

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreenApp};
`;

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const ContainerInner = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    /* background-color: green; */
    width: 100%;
`;

export const ContainerDelivery = styled.div<ContainerDeliveryInterfaces>`
    position: absolute;
    flex-direction: column;
    transition-duration: 400ms;
    display: flex;
    width: 100%;
    padding-left: ${(props) => (props.visibleItem ? '130px' : '60px')};
    top: 70px;
    max-height: 85%;
    overflow-y: auto;
`;

export const TitleScreen = styled.div`
    color: ${colors.basic.black};
    font-weight: bold;
    font-size: 24px;
`;

export const ContentDelivery = styled.div`
    width: 100%;
    margin-top: 30px;
`;

export const ContainerInfoTabs = styled.div`
    align-items: center;
    /* background-color: ${colors.theme.backgroundScreenContainerInfoTabs}; */
    /* box-shadow: 0.5px 2px 2px 1px rgba(0, 0, 0, 0.2); */
    border-radius: 10px;
    flex-direction: column;
    padding: 15px 15px 15px 15px;
    margin-bottom: 10px;
`;

export const ContainerAdjust = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${colors.basic.white};
    width: 100%;
`;

export const ContainerTabs = styled.div`
    /* background-color: ${colors.basic.white}; */
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 20px;
    padding-bottom: 10px;
`;

export const TabOption = styled.div<TabOptionInterfaces>`
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => (props.focus ? colors.theme.backgroundTabs : colors.theme.backgroundContainerInfoTabs)};
    border-radius: 4px;
    display: flex;
    margin-right: 10px;
    justify-content: center;
    height: 50px;
    width: 20%;
    @media (max-width: 1200px) {
        height: 40px;
    }
    @media (max-width: 500px) {
        height: 30px;
    }
`;

export const TextTabOption = styled.text`
    color: ${colors.basic.black};
    font-size: 18px;
    text-align: center;
    @media (max-width: 1200px) {
        font-size: 12px;
    }
    @media (max-width: 500px) {
        font-size: 8px;
    }
`;
