// libraries
import React from 'react';

// js
import {
    Container,
    ContainerFrame,
    TitleLabel,
    Input,
    Select,
    Option,
    TitleDetailsUser,
    ContainerSeparatorInputs,
    ContainerSelectOptions,
    SelectRadius,
    Checked,
} from './newDeliveryStyles';
import functions from '../../../../utils/functions';
import { DeliveryInterfaces } from '../../deliveryInterfaces';

const NewDelivery = (
    inputs: DeliveryInterfaces,
    pickers: { back: string, formOfPayment: string },
    switchs: { savedRegister: boolean, confirmOrder: boolean },
    setInputs: (v: DeliveryInterfaces) => void,
    setPickers: (v: { back: string, formOfPayment: string }) => void,
    setSwitchs: (v: { savedRegister: boolean, confirmOrder: boolean }) => void,
): React.ReactElement => {
    // methods
    function getPropertiesSwitch(type: 'savedRegister' | 'confirmOrder') {
        if (type === 'savedRegister') {
            return {
                title: 'Salvar cadastro do cliente',
            };
        }
        return {
            title: 'Clique aqui para confirmar',
        };
    }

    // renders
    function RenderInput(
        label: string,
        type: 'cpfClient' | 'nameClient' | 'telephoneClient' | 'cepClient' | 'streetClient' |
            'cityClient' | 'numberClient' | 'complementClient' | 'referencePointClient' | 'neighborhoodClient' | 'observation' |
            'rateMotoboy' | 'rateSystem' | 'total',
        divideInput: string,
        disabled: boolean,
    ) {
        // methods
        const onChange = (txt: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = txt.target;
            if (type === 'telephoneClient') {
                return setInputs({ ...inputs, [type]: functions.MaskPhone(value.replace(/[^0-9]/g, '')) });
            }
            if (type === 'cepClient') {
                return setInputs({ ...inputs, [type]: functions.MaskCep(value) });
            }
            if (type === 'cpfClient') {
                return setInputs({ ...inputs, [type]: functions.MaskIdentifier(value) });
            }
            if (type === 'numberClient') {
                return setInputs({ ...inputs, [type]: value.replace(/[^0-9]/g, '') });
            }
            return setInputs({ ...inputs, [type]: value });
        };

        // main
        return (
            <ContainerFrame divideInput={divideInput}>
                <TitleLabel>{label}</TitleLabel>
                <Input
                    value={inputs[type]}
                    onChange={onChange}
                    maxLength={64}
                    type="text"
                    disabled={disabled}
                />
            </ContainerFrame>
        );
    }

    function RenderSelect(label: string, type: 'back' | 'formOfPayment', values: Array<string>, divideInput: string) {
        // methods
        const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
            const { id, value } = e.target;
            setPickers({ ...pickers, [id]: value });
        };

        const TradeTypeUser = () => {
            setPickers({ ...pickers, back: pickers.back === 'Não' ? 'Sim' : 'Não' });
        };

        // renders
        const SelectOption = (value: string) => (
            <Option selected value={value}>
                {value}
            </Option>
        );

        // main
        return (
            <ContainerFrame divideInput={divideInput}>
                <TitleLabel>{label}</TitleLabel>
                {type === 'back' ? (
                    <ContainerSelectOptions>
                        <SelectRadius onClick={TradeTypeUser}>
                            {pickers.back === 'Sim' ? (
                                <Checked />
                            ) : null}
                        </SelectRadius>
                        <TitleLabel>Sim</TitleLabel>
                        <SelectRadius onClick={TradeTypeUser}>
                            {pickers.back === 'Não' ? (
                                <Checked />
                            ) : null}
                        </SelectRadius>
                        <TitleLabel>Não</TitleLabel>
                    </ContainerSelectOptions>
                ) : (
                    <Select
                        id={type}
                        value={pickers[type]}
                        onChange={onChangeSelect}
                    >
                        {values.map((value) => SelectOption(value))}
                    </Select>
                )}
            </ContainerFrame>
        );
    }

    function RenderSwitch(type: 'savedRegister' | 'confirmOrder') {
        const { title } = getPropertiesSwitch(type);

        const TradeTypeUser = () => {
            setSwitchs({ ...switchs, [type]: !switchs[type] });
        };

        return (
            <ContainerSelectOptions>
                <SelectRadius onClick={TradeTypeUser}>
                    {switchs[type] ? (
                        <Checked />
                    ) : null}
                </SelectRadius>
                <TitleLabel marginBottom="0px">{title}</TitleLabel>
            </ContainerSelectOptions>
        );
    }

    // main
    return (
        <>
            <Container>
                <TitleDetailsUser>Detalhes da entrega</TitleDetailsUser>
                {RenderInput('CPF', 'cpfClient', '100%', false)}
                <ContainerSeparatorInputs>
                    {RenderInput('Telefone do cliente', 'telephoneClient', '48%', false)}
                    {RenderSelect('Haverá retorno?', 'back', ['Não', 'Sim'], '48%')}
                </ContainerSeparatorInputs>
                {RenderInput('Nome do cliente', 'nameClient', '100%', false)}
                {RenderInput('CEP de entrega', 'cepClient', '100%', false)}
                {RenderInput('Rua', 'streetClient', '100%', true)}
                {RenderInput('Cidade', 'cityClient', '100%', true)}
                <ContainerSeparatorInputs>
                    {RenderInput('Bairro', 'neighborhoodClient', '32%', true)}
                    {RenderInput('Número', 'numberClient', '32%', false)}
                    {RenderInput('Complemento', 'complementClient', '32%', false)}
                </ContainerSeparatorInputs>
                {RenderInput('Ponto de referência', 'referencePointClient', '100%', false)}
                {RenderInput('Observações de entrega', 'observation', '100%', false)}
                <ContainerSeparatorInputs>
                    {RenderSelect('Forma de pagamento', 'formOfPayment', ['Crédito', 'Débito', 'Dinheiro', 'Já está pago'], '48%')}
                    {RenderSwitch('savedRegister')}
                </ContainerSeparatorInputs>
            </Container>
            <Container height="400px">
                <TitleDetailsUser>Resumo financeiro do pedido</TitleDetailsUser>
                {RenderInput('Taxa de Entregador', 'rateMotoboy', '100%', true)}
                {RenderInput('Taxa do Sistema', 'rateSystem', '100%', true)}
                {RenderInput('Total', 'total', '100%', true)}
                <TitleLabel>Confirme para criar o pedido</TitleLabel>
                {RenderSwitch('confirmOrder')}
            </Container>
        </>
    );
};

export default NewDelivery;
