/* eslint-disable react/require-default-props */
// libraries
import React from 'react';
import Modal from 'react-modal';
import Loader from 'react-loader-spinner';

// js
import colors from '../../styles/colors';
import { TextDefault, ContainerAdjustLoading } from './loadingStyles';

// interfaces
interface Props {
    open: boolean,
    setOpen: (v: boolean) => void,
    label?: string,
}

const LoadingComponent: React.FC<Props> = (props: Props) => {
    // constants
    const {
        open,
        setOpen,
        label,
    } = props;

    // styles modal
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: '50%',
            width: 400,
            height: 200,
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };

    // methods
    function handleCloseModal() {
        setOpen(false);
    }

    return (
        <Modal
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            style={customStyles}
            isOpen={open}
            onRequestClose={handleCloseModal}
        >
            <ContainerAdjustLoading>
                <Loader
                    visible={open}
                    type="TailSpin"
                    color={colors.theme.buttonCrudUser}
                    height={80}
                    width={80}
                />
                <TextDefault>{label || 'Carregando...'}</TextDefault>
            </ContainerAdjustLoading>
        </Modal>
    );
};

export default LoadingComponent;
