// js
import React from 'react';
import { subDays } from 'date-fns';

// js
import colors from '../../styles/colors';
import { formDeliveryInterfaces } from '../../interfaces';

// interfaces
interface stylesAxisInterfaces {
    text: React.CSSProperties,
    line: React.CSSProperties,
    ticks: React.CSSProperties,
}

const lineChart = [
    { x: 1, y: 11 },
    { x: 2, y: 2 },
    { x: 3, y: 12 },
    { x: 4, y: 1 },
    { x: 5, y: 11 },
    { x: 6, y: 3 },
    { x: 7, y: 10 },
];

const stylesXAxis = (widthActual: number, isMobile: boolean): stylesAxisInterfaces => ({
    text: {
        stroke: 'none',
        fill: colors.basic.grayUltra,
        fontWeight: 400,
        fontSize: isMobile ? widthActual / 40 : widthActual / 70,
    },
    line: { stroke: colors.theme.lineChart },
    ticks: { stroke: colors.theme.lineChart },
});

const stylesLineSeries = { strokeWidth: 3, fill: 'transparent' };

const stylesGridVertical = { stroke: colors.theme.lineChart };

const marginXYPlot = { left: 30, right: 30 };

const formDelivery: formDeliveryInterfaces = {
    companySelect: '',
    status: 'nothing,TODOS',
    dataInitial: subDays(new Date(), 1),
    dataFinal: new Date(),
};

export default {
    stylesLineSeries,
    stylesXAxis,
    stylesGridVertical,
    marginXYPlot,
    lineChart,
    formDelivery,
};
