// js
import sellers from '../../services/sellers';
import uploads from '../../services/uploads';
import usersConstants from './usersConstants';
import { DataInputsInterfaces } from './usersInterfaces';
import { DataSellerInterface, DataSubSellerInterfaces } from '../../interfaces';

const CreateSubSeller = async (
    inputs: DataInputsInterfaces,
    setInputs: (v: DataInputsInterfaces) => void,
    idScreen: string | boolean | null,
    setIdScreen: (v: string | boolean | null) => void,
    infoSeller: DataSellerInterface,
    GetInfoScreen: () => void,
): Promise<void> => {
    if (inputs.passwordClient === inputs.confirmPasswordClient) {
        const uploadPhoto = typeof inputs.photo !== 'string' ? await uploads.UploadFile(inputs.photo.eventFile) : inputs.photo;
        const formatInputs = {
            ...inputs,
            photo: uploadPhoto,
        };
        const createAction = idScreen === 'create' ? await sellers.CreateOneSubSeller(
            formatInputs,
            infoSeller?.id,
            infoSeller?.token,
        ) : await sellers.EditOneSubSeller(
            formatInputs,
            inputs?.id || 0,
            infoSeller?.token,
        );
        if (createAction.status === 201 || createAction.status === 200) {
            GetInfoScreen();
            setInputs(usersConstants.initialInputs);
            if (idScreen === 'editSubSeller') {
                setIdScreen(null);
            } else {
                setIdScreen('list');
            }
        }
        // eslint-disable-next-line no-alert
        alert(createAction.message);
    } else {
        // eslint-disable-next-line no-alert
        alert('As senhas precisam ser iguais!');
    }
};

const DeleteAllUsersSelect = async (
    allSellers: Array<DataSubSellerInterfaces>,
    infoSeller: DataSellerInterface,
    GetInfoScreen: () => void,
): Promise<void> => {
    const getAllSellersSelected = allSellers.filter((v) => v.checked === true).map((v) => v.id);
    const deleteAction = await sellers.DeleteOneOrAllSubSellers(
        null,
        null,
        getAllSellersSelected,
        infoSeller?.token,
    );
    if (deleteAction.status === 200) {
        GetInfoScreen();
    }
    // eslint-disable-next-line no-alert
    alert(deleteAction.message);
};

export default {
    CreateSubSeller,
    DeleteAllUsersSelect,
};
