// js
import consts from '../utils/consts';
import { DataDeliveryMen } from '../interfaces';

// constants
const { baseUrl } = consts;

// interfaces
export interface DataResultDeliveryMen {
    result: Array<DataDeliveryMen>,
    status: number,
    message: string,
}

// api
async function GetAllDeliveryMen(
    searchTxt: string,
    idBusiness: number,
    token: string,
): Promise<DataResultDeliveryMen> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/business/listUsers?idBusiness=${idBusiness}&searchTxt=${searchTxt}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function FixDeliveryMen(
    idBusiness: number,
    idUser: number,
    fix: boolean | null,
    token: string,
): Promise<DataResultDeliveryMen> {
    try {
        const method = 'PUT';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const validationPath = fix ? `${baseUrl}/business/favoriteUser?idBusiness=${idBusiness}&idUser=${idUser}&fix=${fix}` : `${baseUrl}/business/favoriteUser?idBusiness=${idBusiness}&idUser=${idUser}`;
        const response = await fetch(validationPath, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function allowChangePhotoDeliveryMan(
    allowChangePhoto: boolean,
    idUser: number,
    token: string,
): Promise<DataResultDeliveryMen> {
    try {
        const data = {
            allowChangePhoto,
        };
        const body = JSON.stringify(data);
        const method = 'PATCH';
        const configs = {
            method,
            body,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/business/updatePermissionPhoto?idUser=${idUser}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function BlockDeliveryMen(
    idUser: number,
    remove: boolean | null,
    token: string,
): Promise<DataResultDeliveryMen> {
    try {
        const method = 'PUT';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const validationPath = remove ? `${baseUrl}/business/blockedUser?idUser=${idUser}&remove=${remove}` : `${baseUrl}/business/blockedUser?idUser=${idUser}`;
        const response = await fetch(validationPath, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function DeleteDeliveryMan(
    idUser: number,
    remove: boolean | null,
    token: string,
): Promise<DataResultDeliveryMen> {
    try {
        const method = 'DELETE';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const validationPath = remove ? `${baseUrl}/business/removeUser?idUser=${idUser}&remove=${remove}` : `${baseUrl}/business/removeUser?idUser=${idUser}`;
        const response = await fetch(validationPath, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function ValidateOrRejectDeliveryMen(
    idUser: string,
    accept: boolean | null,
    token: string,
): Promise<DataResultDeliveryMen> {
    try {
        const method = 'PUT';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const validationPath = accept ? `${baseUrl}/business/validateUser?idUser=${idUser}&accept=${accept}` : `${baseUrl}/business/validateUser?idUser=${idUser}`;
        const response = await fetch(validationPath, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

async function ChangedSellerLinkDeliveryMen(
    idUser: string,
    idSeller: string,
    token: string,
): Promise<DataResultDeliveryMen> {
    try {
        const method = 'PUT';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const validationPath = idSeller !== '0' ? `${baseUrl}/business/changedSellerLinkUser?idUser=${idUser}&idSeller=${idSeller}` : `${baseUrl}/business/changedSellerLinkUser?idUser=${idUser}`;
        const response = await fetch(validationPath, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: [],
            status: 500,
            message: '',
        };
    }
}

export default {
    GetAllDeliveryMen,
    FixDeliveryMen,
    BlockDeliveryMen,
    ValidateOrRejectDeliveryMen,
    ChangedSellerLinkDeliveryMen,
    DeleteDeliveryMan,
    allowChangePhotoDeliveryMan,
};
