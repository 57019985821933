// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// js
import auth from '../../../../services/auth';
import colors from '../../../../styles/colors';
import {
    Home,
    Logo,
    TextActionNavigation,
    ContainerPhoto,
    Header,
    ContainerInner,
    TextInfo,
    ContainerBoxAuth,
    HeaderInput,
    ContainerInfoHeader,
    Container,
} from './loginStyles';
import { backgroundLogin, logo } from '../../../../assets/index';
import { updateSeller } from '../../../../store/modules/seller/actions';

// components
import InputAuth from '../../components/Input';
import ButtonAuth from '../../components/Button';

// components (navigation)
import ToNavigation from '../../../../routes/navigation';

const LoginPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // states
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const onChangeEmail = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setEmail(valueFormat);
    };

    const onChangePassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setPassword(valueFormat);
    };

    const Signin = async () => {
        const getDataSeller = await auth.Login(email, password);
        if (getDataSeller.status === 200) {
            const formatInfoSeller = {
                ...getDataSeller.result.account,
                id_operator: getDataSeller.result.account?.id,
                token: getDataSeller.result.token,
                tokenRefresh: getDataSeller.result.tokenRefresh,
                latitude: getDataSeller.result.account?.typeAccount === 'sub_sellers' ? getDataSeller.result.account?.latitude_seller : getDataSeller.result.account?.latitude,
                longitude: getDataSeller.result.account?.typeAccount === 'sub_sellers' ? getDataSeller.result.account?.longitude_seller : getDataSeller.result.account?.longitude,
                id: getDataSeller.result.account?.typeAccount === 'sub_sellers' ? getDataSeller.result.account?.id_seller : getDataSeller.result.account?.id,
            };
            dispatch(updateSeller(formatInfoSeller));
            if (getDataSeller.result.account?.typeAccount === 'sub_sellers' && getDataSeller.result.account?.type === 'operator') {
                setPathRoute('/delivery');
                setRedirect(true);
            } else {
                setPathRoute('/dashboard');
                setRedirect(true);
            }
        } else {
            // FIXME - Adicionar modal de exibição de avisos.
            // eslint-disable-next-line no-alert
            alert(getDataSeller?.message || 'Erro ao logar');
        }
    };

    const GoToRecoverPassword = () => {
        setPathRoute('/recoverPassword');
        setRedirect(true);
    };

    const GoToRegister = async () => {
        setPathRoute('/registerUser');
        setRedirect(true);
    };

    useEffect(() => {
        window.addEventListener('popstate', () => {
            window.location.replace(document.title);
        });
    }, []);

    // main
    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <ContainerPhoto background={backgroundLogin}>
                    <ContainerInner>
                        <ContainerInfoHeader>
                            <div>
                                <Header>Deixo Já</Header>
                                <TextActionNavigation color={colors.basic.white}>
                                    Seu parceiro de entregas!
                                </TextActionNavigation>
                            </div>
                            <Logo src={logo} />
                        </ContainerInfoHeader>
                        <TextInfo>Seu novo jeito de entregar</TextInfo>
                        <TextInfo>Entrega mais rápida da categoria.</TextInfo>
                        <TextInfo>Entregue com a menor taxa do mercado.</TextInfo>
                        <ContainerBoxAuth>
                            <HeaderInput>E-mail/usuário</HeaderInput>
                            <InputAuth
                                placeholder="Digite seu E-mail ou CNPJ"
                                value={email}
                                type="email"
                                onChange={onChangeEmail}
                                actionButton={Signin}
                            />
                            <HeaderInput>Senha</HeaderInput>
                            <InputAuth
                                placeholder="Digite a sua senha"
                                value={password}
                                type="password"
                                onChange={onChangePassword}
                                actionButton={Signin}
                            />
                            <TextActionNavigation
                                marginTop="20px"
                                onClick={GoToRecoverPassword}
                                cursor
                            >
                                Esqueceu sua senha? Clique aqui
                            </TextActionNavigation>
                        </ContainerBoxAuth>
                        <ContainerInfoHeader>
                            <ButtonAuth onClick={Signin} label="FAZER LOGIN" colorButton={null} colorText={null} />
                            <ButtonAuth onClick={GoToRegister} label="NÃO TEM CONTA? CLIQUE AQUI" colorButton={colors.basic.white} colorText={colors.theme.backgroundButtonLogin} />
                        </ContainerInfoHeader>
                    </ContainerInner>
                </ContainerPhoto>
            </Home>
        </Container>
    );
};

export default LoginPage;
