// libraries
import React, { useState } from 'react';

// js
import auth from '../../../../services/auth';
import colors from '../../../../styles/colors';
import {
    Container,
    Home,
    Logo,
    TextActionNavigation,
    ContainerPhoto,
    Header,
    ContainerInner,
    TextInfo,
    ContainerBoxAuth,
    HeaderInput,
    ContainerInfoHeader,
} from './registerStyles';
import { backgroundLogin, logo } from '../../../../assets/index';

// components
import InputAuth from '../../components/Input';
import ButtonAuth from '../../components/Button';

// components (navigation)
import ToNavigation from '../../../../routes/navigation';

const RegisterPage: React.FC = () => {
    // states
    const [name, setName] = useState('');
    const [telephone, setTelephone] = useState('');
    const [email, setEmail] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const MaskPhone = (item: string) => {
        let valueInitial = item?.replace(/\D/g, '').replace(/^0/, '') || '';
        if (valueInitial.length > 11) {
            valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
        } else if (valueInitial.length > 7) {
            valueInitial = valueInitial.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
        } else if (valueInitial.length > 2) {
            valueInitial = valueInitial.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
        } else if (item?.trim() !== '') {
            valueInitial = valueInitial.replace(/^(\d*)/, '($1');
        }
        return valueInitial;
    };

    const onChangeName = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setName(valueFormat);
    };

    const onChangeTelephone = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const formatTelephone = MaskPhone(txt.target.value);
        setTelephone(formatTelephone);
    };

    const onChangeEmail = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setEmail(valueFormat);
    };

    const Signup = async () => {
        const GetRegister = await auth.RegisterSeller(
            name,
            telephone,
            email,
        );
        if (GetRegister.status) {
            setName('');
            setEmail('');
            setTelephone('');
            // eslint-disable-next-line no-alert
            alert(GetRegister.message);
        }
    };

    const GoToLogin = () => {
        setPathRoute('/');
        setRedirect(true);
    };

    // main
    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <ContainerPhoto background={backgroundLogin}>
                    <ContainerInner>
                        <ContainerInfoHeader>
                            <div>
                                <Header>Deixo Já</Header>
                                <TextActionNavigation color={colors.basic.white}>
                                    Seu parceiro de entregas!
                                </TextActionNavigation>
                            </div>
                            <Logo src={logo} />
                        </ContainerInfoHeader>
                        <TextInfo>Envie seus dados e entraremos em contato!</TextInfo>
                        <ContainerBoxAuth>
                            <HeaderInput>Digite seu nome</HeaderInput>
                            <InputAuth
                                placeholder="Digite seu nome completo"
                                value={name}
                                type="email"
                                onChange={onChangeName}
                            />
                            <HeaderInput>Telefone (com DDD)</HeaderInput>
                            <InputAuth
                                placeholder="Digite seu telefone com DDD"
                                value={telephone}
                                type="email"
                                onChange={onChangeTelephone}
                            />
                            <HeaderInput>Digite seu e-mail</HeaderInput>
                            <InputAuth
                                placeholder="Digite seu e-mail"
                                value={email}
                                type="email"
                                onChange={onChangeEmail}
                            />
                            <TextActionNavigation
                                marginTop="20px"
                                onClick={GoToLogin}
                                cursor
                            >
                                Já possui uma conta? Acesse!
                            </TextActionNavigation>
                        </ContainerBoxAuth>
                        <ContainerInfoHeader>
                            <ButtonAuth onClick={Signup} label="SOLICITAR CONTRATO" colorButton={null} colorText={null} />
                        </ContainerInfoHeader>
                    </ContainerInner>
                </ContainerPhoto>
            </Home>
        </Container>
    );
};

export default RegisterPage;
