/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React, { useState } from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { useDispatch, useSelector } from 'react-redux';

// js
import colors from '../../styles/colors';
import {
    Header,
    Logo,
    BoxHeader,
    Home,
    TabOption,
    OpenIcon,
    ContainerMenu,
    GoActionDrawer,
    ContainerHeaderScreen,
    ContainerInfoSeller,
    ContainerRansom,
    MyMoney,
    MoneyIcon,
    ContainerOpenDropDawn,
    IconOptions,
    ContainerOptions,
    DrawerOption,
    ContainerDrawerOption,
    IconDrawerOption,
    NumberNotification,
    ContainerInfoTabDrawer,
    AdjustIcon,
    LogoHeader,
    ContainerFlex,
} from './menuStyles';
import {
    logoAuth,
    goActionDrawer,
    money,
    iconFinance,
    iconLogo,
    iconMotoboy,
    iconPeople,
    iconDeliveryMan,
    iconDolar,
    iconHome,
    iconEditProfile,
    iconPullRefresh,
    iconPower,
} from '../../assets/index';
import { logoutAuth, updateHistoryRoutes } from '../../store/modules/seller/actions';
import { RootState } from '../../store';
import functions from '../../utils/functions';

// components
import GoBackComponent from '../GoBack/GoBackComponent';

// components (navigation)
import ToNavigation from '../../routes/navigation';

// interfaces
interface Props {
    visibleDrawer: boolean,
    setVisibleDrawer: (visibleDrawer: boolean) => void,
}

const MenuComponent: React.FC<Props> = (props: Props) => {
    // useSelector
    const infoSeller = useSelector((state: RootState) => state.seller.seller);
    const qtdMotoboyPending = useSelector((state: RootState) => state.seller.qtdMotoboyPending);
    const qtdDeliveriesWithRun = useSelector(
        (state: RootState) => state.seller.qtdDeliveriesWithRun,
    );
    const transferable_balance = useSelector(
        (state: RootState) => state.seller.transferable_balance,
    );
    const historyRoutes = useSelector(
        (state: RootState) => state.seller.historyRoutes,
    );

    // dispatch
    const dispatch = useDispatch();

    // states
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // consts
    const {
        buttonProps,
        itemProps,
        isOpen,
        setIsOpen,
    } = useDropdownMenu(2);
    const { visibleDrawer, setVisibleDrawer } = props;
    const allTabs = [
        {
            id: 1,
            title: 'Seu painel',
            path: '/dashboard',
            icon: iconHome,
        },
        {
            id: 2,
            title: 'Entregas',
            path: '/delivery',
            icon: iconMotoboy,
        },
        {
            id: 3,
            title: 'Usuários',
            path: '/users',
            icon: iconPeople,
        },
        {
            id: 4,
            title: 'Entregadores',
            path: '/deliverymen',
            icon: iconDeliveryMan,
        },
        {
            id: 5,
            title: 'Financeiro',
            path: '/finance',
            icon: iconDolar,
        },
        {
            id: 6,
            title: 'Estabelecimento',
            path: '/establishment',
            icon: iconFinance,
        },
    ];
    const TabsOperator = allTabs.filter((v) => v.title === 'Entregas');
    // eslint-disable-next-line max-len
    // const TabsAdmin = allTabs.filter((v) => v.title !== 'Usuários' && v.title !== 'Entregadores' && v.title !== 'Financeiro');
    const TabsBusiness = allTabs.filter((v) => v.title === 'Seu painel' || v.title === 'Entregadores' || v.title === 'Estabelecimento' || v.title === 'Financeiro');
    const TabsSeller = allTabs.filter((v) => v.title !== 'Entregadores' && v.title !== 'Estabelecimento');
    const ValidationRenderTabs = () => {
        if (infoSeller?.typeAccount === 'sub_sellers' && infoSeller?.type === 'operator') {
            return TabsOperator;
        }
        if (infoSeller?.typeAccount === 'sub_sellers' && infoSeller?.type === 'admin') {
            return TabsSeller;
        }
        if (infoSeller?.typeAccount === 'business') {
            return TabsBusiness;
        }
        if (infoSeller?.typeAccount === 'sellers') {
            return TabsSeller;
        }

        return allTabs;
    };

    // states
    const [visibleItem, setVisibleItem] = useState(false);

    // methods
    const onLogout = () => {
        if (isOpen) {
            dispatch(logoutAuth());
            // setTimeout(() => {
            //     setRedirect(true);
            //     setPathRoute('/');
            // }, 1000);
            // setRedirect(true);
            // setPathRoute('/');
        }
    };

    const tradeVisibleItem = () => {
        setVisibleDrawer(!visibleDrawer);
        setVisibleItem(!visibleItem);
    };

    const GoToProfileSeller = () => {
        if (isOpen) {
            if (historyRoutes.slice(-1)[0] !== window.location.pathname) {
                dispatch(updateHistoryRoutes([...historyRoutes, window.location.pathname]));
            }
            setPathRoute('/profileSeller');
            setRedirect(true);
            setVisibleItem(false);
        }
    };

    const GoToFinance = () => {
        if (isOpen) {
            if (historyRoutes.slice(-1)[0] !== window.location.pathname) {
                dispatch(updateHistoryRoutes([...historyRoutes, window.location.pathname]));
            }
            setPathRoute('/finance');
            setRedirect(true);
            setVisibleItem(false);
        }
    };

    const OpenDropDawn = () => {
        setIsOpen(!isOpen);
    };

    // renders
    const RenderAllTabs = () => (
        ValidationRenderTabs().map((v) => {
            const GoToNavigationTab = () => {
                if (v.path === window.location.pathname) {
                    window.location.reload();
                    if (historyRoutes.slice(-1)[0] === window.location.pathname) {
                        // eslint-disable-next-line max-len
                        const removeLastItem = historyRoutes.length === 1 ? [] : historyRoutes.slice(0, historyRoutes.length - 1);
                        dispatch(updateHistoryRoutes(removeLastItem));
                    }
                } else {
                    if (historyRoutes.slice(-1)[0] !== window.location.pathname) {
                        dispatch(updateHistoryRoutes([...historyRoutes, window.location.pathname]));
                    }
                    setPathRoute(v.path);
                    setRedirect(true);
                    setVisibleItem(false);
                    setVisibleDrawer(false);
                }
            };
            const RenderNumberNotification = () => {
                if (['Entregas', 'Entregadores'].includes(v.title)) {
                    return (
                        <NumberNotification>{v.title.includes('Entregas') ? qtdDeliveriesWithRun?.length || 0 : qtdMotoboyPending}</NumberNotification>
                    );
                }
                return null;
            };
            return (
                <ContainerDrawerOption onClick={GoToNavigationTab} key={v.id}>
                    <ContainerInfoTabDrawer>
                        <AdjustIcon>
                            <IconDrawerOption src={v.icon} width={v.title === 'Entregadores' ? '51px' : '30px'} />
                            {RenderNumberNotification()}
                        </AdjustIcon>
                        <DrawerOption>{v.title}</DrawerOption>
                    </ContainerInfoTabDrawer>
                </ContainerDrawerOption>
            );
        })
    );

    const RenderDropDawn = () => (
        <ContainerOpenDropDawn {...buttonProps} isOpen={!isOpen} role="menu" visibleItem={visibleItem} typeAccount={infoSeller?.typeAccount}>
            <ContainerOptions>
                <IconOptions src={iconEditProfile} isOpen={!isOpen} />
                <TabOption
                    {...itemProps[0]}
                    onClick={GoToProfileSeller}
                    isOpen={!isOpen}
                >
                    Editar Perfil
                </TabOption>
            </ContainerOptions>
            {['sellers'].includes(infoSeller?.typeAccount) ? (
                <ContainerOptions>
                    <IconOptions src={iconPullRefresh} isOpen={!isOpen} />
                    <TabOption
                        {...itemProps[1]}
                        onClick={GoToFinance}
                        isOpen={!isOpen}
                    >
                        Recarga App
                    </TabOption>
                </ContainerOptions>
            ) : null}
            <ContainerOptions>
                <IconOptions src={iconPower} isOpen={!isOpen} />
                <TabOption
                    {...itemProps[['sellers', 'business'].includes(infoSeller?.typeAccount) ? 2 : 1]}
                    onClick={onLogout}
                    isOpen={!isOpen}
                    color={colors.theme.backgroundButtonLogin}
                >
                    Deslogar
                </TabOption>
            </ContainerOptions>
        </ContainerOpenDropDawn>
    );

    return (
        <ContainerMenu>
            <ToNavigation pathRoute={pathRoute} redirect={redirect} />
            <Header visibleItem={visibleItem}>
                {!visibleItem ? (
                    <OpenIcon src={goActionDrawer} onClick={tradeVisibleItem} />
                ) : null}
                <Home visibleItem={visibleItem}>
                    <BoxHeader>
                        <Logo src={iconLogo} />
                        <GoActionDrawer src={goActionDrawer} onClick={tradeVisibleItem} />
                    </BoxHeader>
                    {RenderAllTabs()}
                </Home>
            </Header>
            <ContainerHeaderScreen widthScreen="100%" visibleItem={visibleItem}>
                <ContainerInfoSeller visibleItem={visibleItem}>
                    {historyRoutes?.length ? (
                        <GoBackComponent />
                    ) : <div />}
                    <ContainerFlex>
                        {['sellers', 'business'].includes(infoSeller?.typeAccount) ? (
                            <ContainerRansom>
                                <MoneyIcon src={money} />
                                <MyMoney>
                                    {`R$ ${functions.toPrice(transferable_balance, 2)}`}
                                </MyMoney>
                            </ContainerRansom>
                        ) : null}
                        <ContainerRansom>
                            <MyMoney>
                                {infoSeller?.name || ''}
                                {infoSeller?.typeAccount === 'sub_sellers' ? (
                                    <MyMoney fontWeight="bold">{`(${infoSeller?.name_seller})`}</MyMoney>
                                ) : null}
                            </MyMoney>
                        </ContainerRansom>
                        <LogoHeader
                            src={infoSeller?.logo || infoSeller?.photo || logoAuth}
                            onClick={OpenDropDawn}
                            cursorActive
                        />
                    </ContainerFlex>
                </ContainerInfoSeller>
            </ContainerHeaderScreen>
            {RenderDropDawn()}
        </ContainerMenu>
    );
};

export default MenuComponent;
