// libraries
import getPreciseDistance from 'geolib/es/getPreciseDistance';

// js
import deliveryApi from '../../services/delivery';
import functions from '../../utils/functions';
import { DeliveryInterfaces } from './deliveryInterfaces';
import { DataSellerInterface, DataOrdersInterfaces } from '../../interfaces';

async function getDistanceForCalc(
    inputs: DeliveryInterfaces,
    setInputs: (v: DeliveryInterfaces) => void,
    pickers: { back: string },
    infoSeller: DataSellerInterface,
    infoExemption: boolean,
): Promise<void> {
    // buscando dados
    const response = await deliveryApi.GetCoordinatesWithAddress(
        `${inputs.streetClient}, ${inputs.numberClient}, ${inputs.cityClient}`,
        infoSeller?.token,
    );
    if (response.status === 200) {
        const latitude = Number(response.result?.lat);
        const longitude = Number(response.result?.lng);
        const distance = getPreciseDistance(
            { latitude: infoSeller?.latitude, longitude: infoSeller?.longitude },
            { latitude, longitude },
        );
        const distanceKm = Number((distance / 1000).toFixed(1));
        // const distanceKmCharged = inputs.cityClient === 'Campina Grande' ?
        // (distanceKm - 3) : (distanceKm - 4); // 'Campina Grande' (3) | 'João Pessoa' (4),
        // distância em km que vai ser cobrado.

        const getPriceForDistanceKm = () => {
            if (distanceKm < 10) {
                const interval = distanceKm;
                const pricePerKm = 0.50;
                const priceBase = 5.50;
                return priceBase + (interval * pricePerKm);
            }
            if (distanceKm < 20) {
                const intervalMedium = distanceKm - 10;
                const pricePerKmMedium = 1;
                const priceBaseMedium = 11;
                return priceBaseMedium + (intervalMedium * pricePerKmMedium);
            }
            const intervalMax = distanceKm - 20;
            const pricePerKmMax = 5;
            const priceBaseMax = 25;
            return priceBaseMax + (intervalMax * pricePerKmMax);
        };

        // constants
        const pricePerDistance = getPriceForDistanceKm();
        const priceForBack = pickers.back === 'Não' ? 0 : 0.50;
        const percentageTaxApp = 0.10; // 10%

        // calculo
        const rateMotoboy = pricePerDistance + priceForBack;
        const rateSystem = infoExemption ? 0 : (rateMotoboy * percentageTaxApp);
        const total = rateSystem + rateMotoboy;
        setInputs({
            ...inputs,
            latitude,
            longitude,
            distance: distanceKm,
            rateSystem: functions.priceMask(rateSystem),
            rateMotoboy: functions.priceMask(rateMotoboy),
            total: functions.priceMask(total),
        });
    }
}

const GetInfoScreen = async (
    idFilter: '' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS',
    setAllOrders: (v: Array<DataOrdersInterfaces>) => void,
    infoSeller: DataSellerInterface,
): Promise<void> => {
    const GetOrders = await deliveryApi.GetOrders('', 'finished', idFilter, infoSeller?.id, infoSeller?.token);
    setAllOrders(GetOrders?.result || []);
};

const GetInfoOrder = async (
    idOrder: number,
    idFilter: '' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS',
    infoSeller: DataSellerInterface,
    setOneOrder: (v: Array<DataOrdersInterfaces>) => void,
    setIdQueue: (v: number) => void,
): Promise<void> => {
    const GetOneOrder = await deliveryApi.GetOrders(idOrder, 'run', idFilter, infoSeller?.id, infoSeller?.token);
    setOneOrder([GetOneOrder?.result[0]?.nextQueue?.order] || []);
    setIdQueue(GetOneOrder?.result[0]?.nextQueue?.order?.queue[0]?.id || 0);
};

const ActionGetForOrder = async (
    inputs: DeliveryInterfaces,
    setInputs: (v: DeliveryInterfaces) => void,
    infoSeller: DataSellerInterface,
): Promise<void> => {
    const GetInfoForOrders = await deliveryApi.GetInfoOrders(
        inputs.cpfClient.replace(/[^0-9]/g, ''),
        infoSeller?.id,
        infoSeller?.token,
    );
    if (GetInfoForOrders.status === 200) {
        setInputs({
            ...inputs,
            nameClient: GetInfoForOrders.result?.name_client || '',
            telephoneClient: GetInfoForOrders.result?.telephone_client || '',
            cepClient: functions.MaskCep(GetInfoForOrders.result?.cep_client || ''),
            numberClient: GetInfoForOrders.result?.number_client || '',
            complementClient: GetInfoForOrders.result?.complement_client || '',
            referencePointClient: GetInfoForOrders.result?.reference_point_client || '',
            observation: GetInfoForOrders.result?.observation || '',
        });
    }
};

export default {
    getDistanceForCalc,
    GetInfoScreen,
    GetInfoOrder,
    ActionGetForOrder,
};
