// libraries
import styled from 'styled-components';

// js
import colors from '../../../../styles/colors';
import consts from '../../../../utils/consts';

// interfaces
interface TabOptionInterfaces {
    focus: boolean,
}

interface TextDefaultInterfaces {
    backgroundColor?: string,
    padding?: string,
    color?: string,
    fontSize?: string,
    fontWeight?: string,
}

interface LabelInterface {
    textAlign?: string,
}

interface TxtStatusInterfaces {
    width?: string,
}

interface ButtonStatusInterfaces {
    backgroundColor?: string,
}

interface ContainerFiltersInterfaces {
    backgroundColor?: string,
    borderBottom?: string,
    borderRadius?: string,
    marginBottom?: string,
    height?: string,
    width?: string,
}

interface ContainerHeaderInterface {
    spaccing?: number,
}

interface ContainerSellerInterfaces {
    justifyContent?: string,
}

export const ContainerInfo = styled.div`
    display: flex;
    flex-direction: column;
    /* background-color: green; */
    /* height: 200px; */
    width: 100%;
    padding: 20px;
`;

export const ContainerFrame = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
`;

export const ButtonFilters = styled.div<TabOptionInterfaces>`
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => (props.focus ? colors.theme.backgroundFilters : 'transparent')};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    height: 30px;
    width: 20%;
`;

export const ContainerFilters = styled.div<ContainerFiltersInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    border-radius: ${(props) => (props.borderRadius || '5px 5px 0px 0px')};
    border-bottom: ${(props) => (props.borderBottom || 'none')};
    padding-left: 10px;
    cursor: pointer;
    padding-right: 10px;
    height: ${(props) => (props.height || 'auto')};
    width: ${(props) => (props.width || '100%')};
    @media (max-width: 1200px) {
        height: 40px;
    }
`;

export const ContainerSeller = styled.div<ContainerSellerInterfaces>`
    align-items: center;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    width: 24%;
    display: flex;
`;

export const PhotoSeller = styled.img`
    object-fit: cover;
    border-radius: 50%;
    height: 35px;
    margin-right: 5px;
    width: 35px;
    @media (max-width: 1200px) {
        border-radius: 50%;
        height: 20px;
        width: 20px;
    }
`;

export const ContainerShadow = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 100%;
`;

export const TextDefault = styled.text<TextDefaultInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    font-size: ${(props) => (props.fontSize || '14px')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    border-radius: 5px;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    padding: ${(props) => (props.padding || '0px')};
    text-align: center;
    width: 24%;
    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const ContainerHeader = styled.div<ContainerHeaderInterface>`
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: ${(props) => (`${(props.spaccing || 20) / 2}px`)};
    padding-bottom: ${(props) => (`${(props.spaccing || 20) / 2}px`)};
    margin-top: ${(props) => (`${(props.spaccing || 20) / 2}px`)};
    margin-bottom: ${(props) => (`${(props.spaccing || 20) / 2}px`)};
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${colors.theme.backgroundCardInfo};
    border-radius: 10px;
`;

export const TitleLabel = styled.text<LabelInterface>`
    flex: 1;
    color: ${colors.basic.black};
    font-weight: ${consts.getFontWeight('Inter-Bold')};
    font-size: 15px;
    text-transform: uppercase;
    text-align: ${(props) => (props?.textAlign || 'start')};

    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const Input = styled.input`
    color: ${colors.basic.black};
    font-weight: ${consts.getFontWeight('Inter-Regular')};
    background-color: ${colors.basic.white};
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 10px;
    height: 35px;
    padding-left: 15px;
    flex: 3;
`;

export const ContainerWarning = styled.div`
    display: flex;
    align-items: center;
    width: 70%;
    @media (max-width: 800px) {
        width: 100%;
    }
`;

export const ImgWarning = styled.img`
    object-fit: contain;
    height: 84px;
    width: 84px;
    @media (max-width: 800px) {
        height: 64px;
        width: 64px;
    }
`;

export const TextWarning = styled.text`
    color: ${colors.basic.black};
    font-size: 20px;
    @media (max-width: 800px) {
        font-size: 15px;
    }
`;

export const ContainerQueue = styled.div`
    align-items: center;
    background-color: ${colors.theme.backgroundContainerInfoTabs};
    display: flex;
    border-radius: 30px;
    padding-bottom: 50px;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 20px;
    width: 60%;
    @media (max-width: 800px) {
        width: 90%;
    };
`;

export const ContainerEvaluation = styled.div`
    align-items: center;
    background-color: ${colors.theme.backgroundContainerInfoTabs};
    display: flex;
    border-radius: 30px;
    padding-bottom: 50px;
    flex-direction: column;
    margin-top: 20px;
    width: 35%;
    max-height: 150px;
    @media (max-width: 800px) {
        width: 90%;
    };
`;

export const TitleBoxQueue = styled.text`
    color: ${colors.basic.black};
    display: flex;
    font-size: 18px;
    font-weight: bold;
`;

export const ButtonStatus = styled.div<ButtonStatusInterfaces>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    background-color: ${(props) => (props.backgroundColor || colors.basic.grayUltra)};
    width: 150px;
`;

export const ContainerOnlyQueue = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    margin-top: 10px;
    width: 90%;
`;

export const TxtStatus = styled.text<TxtStatusInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    cursor: ${(props) => (props.cursor || 'default')};
    max-width: ${(props) => (props.width || 'auto')};
`;

export const ContainerMore = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

export const TextSeller = styled.text`
    color: ${colors.theme.textTabs};
    font-weight: bold;
    font-size: 14px;
`;

export const ContainerMotoboy = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    width: 90%;
`;

export const ContainerModalEvaluation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Star = styled.img`
    height: 48px;
    cursor: pointer;
    width: 48px;
`;

export const ContainerAllStar = styled.div`
    align-items: center;
    justify-content: center;
    margin: 20px 0px 20px 0px;
    display: flex;
    width: 100%;
`;
