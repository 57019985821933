/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React from 'react';

// js
import {
    ContainerHeader,
    ContainerInfo,
    PhotoSeller,
    TitleDefault,
    ImgEllipsis,
    ContainerNull,
    TabOption,
    ContainerOptions,
    ContainerOpenDropDawn,
    ContainerBodyDeliveryMen,
    ContainerImgEllipsis,
} from './establishmentStyles';
import {
    iconEllipsis,
    logoAuth,
} from '../../assets/index';
import colors from '../../styles/colors';
import {
    DataSellerInterface,
} from '../../interfaces';
import functions from '../../utils/functions';
import deliveryManFunctions from './establishmentFunctions';
import { DataInputsInterfaces } from '../Users/usersInterfaces';
import sellers from '../../services/sellers';
import auth from '../../services/auth';
import { DataInputsCreateSellerInterfaces } from './establishmentInterfaces';
import { updateHistoryRoutes } from '../../store/modules/seller/actions';

const RenderAllSellers = (
    allDeliveryMen: Array<any>,
    setAllDeliveryMen: (v: Array<any>) => void,
    infoSeller: DataSellerInterface,
    GetInfoScreen: () => void,
    idScreen: boolean,
    setIdScreen: (v: boolean) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    buttonProps: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    itemProps: any,
    idActionDropDawn: number | null,
    setIdActionDropDawn: (v: number | null) => void,
    setQtdDropDawn: (v: number) => void,
    setViewEditSubSeller: (v: any) => void,
    setInputs: (v: DataInputsInterfaces) => void,
    setInputsCreateSeller: (v: DataInputsCreateSellerInterfaces) => void,
    setIdSellerSelect: (v: number) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    historyRoutes: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dispatch: any,
): React.ReactElement | null => {
    if (allDeliveryMen.length) {
        return (
            <>
                {allDeliveryMen.map((v, i) => {
                    // constants
                    const arrayOptionsDropDawn = deliveryManFunctions.ArrayOptionsDropDawn(
                        v,
                        idScreen,
                    );

                    // methods
                    const openDropDawn = () => {
                        if (idActionDropDawn) {
                            setIdActionDropDawn(null);
                            setQtdDropDawn(4);
                        } else {
                            setIdActionDropDawn(v.id);
                            setQtdDropDawn(arrayOptionsDropDawn.length);
                        }
                    };

                    // renders
                    const RenderAcceptRegistred = () => {
                        if (v.type === 'operator') {
                            return (
                                <TitleDefault padding="2px 5px 2px 5px" backgroundColor={colors.theme.backgroundStatusDeliveryMenValidate} color={colors.theme.colorStatusDeliveryMen}>
                                    Operador
                                </TitleDefault>
                            );
                        }
                        if (v.type === 'admin') {
                            return (
                                <TitleDefault padding="2px 5px 2px 5px" backgroundColor={colors.theme.redLightMedium} color={colors.theme.colorStatusDeliveryMen}>
                                    Administrador
                                </TitleDefault>
                            );
                        }

                        return null;
                    };
                    const RenderOptionsDropDawn = () => {
                        if (v.id === idActionDropDawn) {
                            return arrayOptionsDropDawn.map((label, index) => {
                                const onFunctionDropDawn = async () => {
                                    if (label === 'Editar loja') {
                                        dispatch(updateHistoryRoutes(
                                            [...historyRoutes, window.location.pathname],
                                        ));
                                        setInputsCreateSeller({
                                            name: v.name,
                                            identifier: functions.MaskCNPJ(v.cnpj),
                                            email: v.email,
                                            password: '',
                                            confirmPassword: '',
                                            address: v.address,
                                            cep: functions.MaskCep(v.cep),
                                            number: v.number_home,
                                            complement: v.complement,
                                            neighborhood: '',
                                            city: '',
                                            telephone: functions.MaskPhone(v.telephone),
                                            typePix: v?.type_pix || 'hash',
                                            id: v.id,
                                            pix: v?.pix || '',
                                            photo: v.logo,
                                            exemption: v.exemption,
                                            cashback: v.cashback,
                                            activeCashback: v.cashback > 0,
                                            bank_number: v.bank_number || '',
                                            account_number: v?.account_number?.replace(/[^0-9]/g, ''),
                                            agency_number: v.agency_number,
                                            account_type: v.account_type || '',
                                            account_document: v?.account_document || '',
                                        });
                                    }
                                    if (label === 'Recuperar senha') {
                                        const GetInfoRecoverPassword = await
                                        auth.RecoverPassword(
                                            v.email,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoRecoverPassword.message);
                                    }
                                    if (label === 'Alterar permissões') {
                                        setViewEditSubSeller(v);
                                        dispatch(updateHistoryRoutes(
                                            [...historyRoutes, window.location.pathname],
                                        ));
                                        setInputs({
                                            nameClient: v.name,
                                            emailClient: v.email,
                                            photo: v.photo,
                                            type: v.type,
                                            identifier: v.identifier,
                                            id: v.id,
                                            passwordClient: '',
                                            confirmPasswordClient: '',
                                        });
                                    }
                                    if (label === 'Ver entregadores') {
                                        setIdSellerSelect(v.id);
                                    }
                                    if (label === 'Bloquear') {
                                        const GetInfoBlockSellerOrSubSeller = await
                                        sellers.BlockedOneSellerOrSubSeller(
                                            v.id,
                                            v.type ? 'sub_sellers' : 'sellers',
                                            true,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoBlockSellerOrSubSeller.message);
                                    }
                                    if (label === 'Desbloquear') {
                                        const GetInfoBlockSellerOrSubSeller = await
                                        sellers.BlockedOneSellerOrSubSeller(
                                            v.id,
                                            v.type ? 'sub_sellers' : 'sellers',
                                            false,
                                            infoSeller?.token,
                                        );
                                        GetInfoScreen();
                                        // eslint-disable-next-line no-alert
                                        alert(GetInfoBlockSellerOrSubSeller.message);
                                    }
                                    if (label === 'Excluir') {
                                        if (idScreen) {
                                            const GetInfoDeleteSellerOrSubSeller = await
                                            sellers.DeleteOneOrAllSubSellers(
                                                v.id,
                                                null,
                                                [],
                                                infoSeller?.token,
                                            );
                                            // eslint-disable-next-line no-alert
                                            alert(GetInfoDeleteSellerOrSubSeller.message);
                                        } else {
                                            const GetInfoDeleteSellerOrSubSeller = await
                                            sellers.DeleteOneOrAllSubSellers(
                                                null,
                                                v.id,
                                                [],
                                                infoSeller?.token,
                                            );
                                            // eslint-disable-next-line no-alert
                                            alert(GetInfoDeleteSellerOrSubSeller.message);
                                        }
                                        GetInfoScreen();
                                    }
                                };
                                return (
                                    <ContainerOptions>
                                        <TabOption
                                            {...itemProps[index]}
                                            onClick={onFunctionDropDawn}
                                            color={label === 'Excluir' ? colors.theme.backgroundButtonLogin : colors.theme.backgroundDrawer}
                                        >
                                            {label}
                                        </TabOption>
                                    </ContainerOptions>
                                );
                            });
                        }

                        return null;
                    };
                    return (
                        <ContainerHeader marginBottom={i === allDeliveryMen?.length - 1 ? '50px' : '0px'} padding="0px 0px 0px 0px" backgroundColor={v.fix ? colors.theme.redLightLow : colors.theme.backgroundContainerInfoTabs} justifyContent="space-between">
                            <ContainerBodyDeliveryMen isOpen={v.id === idActionDropDawn}>
                                <ContainerInfo flexDirection="row" width="15%" alignItems="center">
                                    <PhotoSeller src={v.logo || logoAuth} />
                                    <ContainerNull>
                                        <TitleDefault marginBottom="5px">{v.name}</TitleDefault>
                                        {idScreen ? (
                                            <TitleDefault>
                                                {functions.MaskPhone(v.telephone)}
                                            </TitleDefault>
                                        ) : null}
                                    </ContainerNull>
                                </ContainerInfo>
                                <ContainerInfo width="15%">
                                    <TitleDefault widthText="100%">{v.email}</TitleDefault>
                                </ContainerInfo>
                                <ContainerInfo width="15%">
                                    <TitleDefault widthText="30%">{idScreen ? v.qtdMotoboy : v.seller.name}</TitleDefault>
                                </ContainerInfo>
                                {!idScreen ? (
                                    <ContainerInfo width="15%">
                                        {RenderAcceptRegistred()}
                                    </ContainerInfo>
                                ) : (
                                    <ContainerInfo width="15%">
                                        <TitleDefault widthText="50%">{`R$ ${functions.toPrice(v?.transferable_balance || 0, 2)}`}</TitleDefault>
                                    </ContainerInfo>
                                )}
                            </ContainerBodyDeliveryMen>
                            <ContainerOpenDropDawn
                                {...buttonProps}
                                isOpen={v.id === idActionDropDawn}
                                role="menu"
                                onClick={openDropDawn}
                            >
                                <ContainerImgEllipsis>
                                    <ImgEllipsis src={iconEllipsis} />
                                </ContainerImgEllipsis>
                                {RenderOptionsDropDawn()}
                            </ContainerOpenDropDawn>
                        </ContainerHeader>
                    );
                })}
            </>
        );
    }

    return null;
};

export default {
    RenderAllSellers,
};
