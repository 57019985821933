/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';

// js
import uploads from '../../services/uploads';
import profile from '../../services/profile';
import {
    Home,
    Container,
    ContainerInner,
    TitleScreen,
    Logo,
    ContainerPointer,
    ContainerBoxProfile,
    Content,
    ContainerBox,
    ContainerFrame,
    TitleLabel,
    Input,
    ContainerCamera,
    ImgCamera,
    ContainerButtonAction,
    ContainerInfoProfile,
    NameUser,
} from './profileStyles';
import { RootState } from '../../store';
import { updateSeller } from '../../store/modules/seller/actions';
import sellers from '../../services/sellers';
import { DataInputsInterfaces } from './profileInterfaces';

// components
import ButtonAuth from '../Auth/components/Button';
import MenuComponent from '../../components/Menu/MenuComponent';
import { camera, logoAuth } from '../../assets';
import profileConstants from './profileConstants';

const ProfilePage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const infoSeller = useSelector((state: RootState) => state.seller.seller);

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [inputs, setInputs] = useState<DataInputsInterfaces>(profileConstants.initialInputs);

    // methods
    async function SelectedPhotoCard(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        const UploadFile = await uploads.UploadFile(newDataFiles.eventFile);
        setInputs({ ...inputs, photo: UploadFile });
    }

    const EditProfile = async () => {
        if (
            inputs.newPassword === inputs.confirmNewPassword
            && inputs.oldPassword.length
            && inputs.newPassword.length
            && inputs.confirmNewPassword.length
        ) {
            const RequestCorrect = () => {
                let newFormatInfoSeller = {};
                if (infoSeller?.typeAccount === 'sellers' || infoSeller?.typeAccount === 'business') {
                    newFormatInfoSeller = {
                        ...infoSeller,
                        logo: inputs.photo,
                    };
                }
                if (infoSeller?.typeAccount === 'sub_sellers') {
                    newFormatInfoSeller = {
                        ...infoSeller,
                        photo: inputs.photo,
                    };
                }
                dispatch(updateSeller(newFormatInfoSeller));
                setInputs(profileConstants.initialInputs);
            };
            if (infoSeller?.typeAccount === 'sellers' || infoSeller?.typeAccount === 'business') {
                const EditDataProfile = await profile.EditProfile(
                    inputs.oldPassword,
                    inputs.newPassword,
                    inputs.photo,
                    infoSeller?.id,
                    infoSeller?.typeAccount,
                    infoSeller?.token,
                );
                if (EditDataProfile.status === 200) {
                    RequestCorrect();
                }
            }
            if (infoSeller?.typeAccount === 'sub_sellers') {
                const allInputs = {
                    photo: inputs.photo,
                    nameClient: infoSeller?.name,
                    identifier: infoSeller?.identifier,
                    emailClient: infoSeller?.email,
                    passwordClient: inputs.newPassword,
                    confirmPasswordClient: inputs.newPassword,
                    type: infoSeller?.type,
                };
                const EditDataProfile = await sellers.EditOneSubSeller(
                    allInputs,
                    infoSeller?.id,
                    infoSeller?.token,
                );
                if (EditDataProfile.status === 200) {
                    RequestCorrect();
                }
            }
        }
    };

    const UpdateInfoSeller = async () => {
        const GetInfoSeller = await profile.GetInfoSeller(
            '',
            infoSeller?.id,
            infoSeller?.typeAccount,
            infoSeller?.token,
        );
        if (GetInfoSeller.status === 200) {
            const newFormatInfoSeller = {
                ...infoSeller,
                ...GetInfoSeller.result[0],
            };
            dispatch(updateSeller(newFormatInfoSeller));
        } else {
            // eslint-disable-next-line no-alert
            alert(GetInfoSeller.message || 'Erro ao atualizar seus dados de perfil');
        }
    };

    useEffect(() => {
        setInterval(() => {
            UpdateInfoSeller();
        }, 5000);
    }, []);

    // renders
    const RenderArchiveProfile = () => {
        if (inputs.photo) {
            return inputs.photo;
        }
        if ((infoSeller?.logo || infoSeller?.photo) && !inputs.photo) {
            return infoSeller?.logo || infoSeller?.photo;
        }
        return logoAuth;
    };

    function RenderInput(
        label: string,
        type: 'oldPassword' | 'newPassword' | 'confirmNewPassword',
        divideInput: string,
        disabled: boolean,
    ) {
        // methods
        const onChange = (txt: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = txt.target;
            return setInputs({ ...inputs, [type]: value });
        };

        // main
        return (
            <ContainerFrame divideInput={divideInput}>
                <TitleLabel>{label}</TitleLabel>
                <Input
                    value={inputs[type]}
                    onChange={onChange}
                    type="password"
                    disabled={disabled}
                />
            </ContainerFrame>
        );
    }

    const RenderScreen = () => (
        <ContainerBoxProfile visibleItem={visibleDrawer}>
            <TitleScreen visibleItem={visibleDrawer}>Editar Perfil</TitleScreen>
            <Content>
                <ContainerBox>
                    {RenderInput('Sua senha', 'oldPassword', '100%', false)}
                    {RenderInput('Nova senha', 'newPassword', '100%', false)}
                    {RenderInput('Confirmar nova senha', 'confirmNewPassword', '100%', false)}
                </ContainerBox>
                <ContainerInfoProfile>
                    <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                        {({ getRootProps, getInputProps }) => (
                            <ContainerPointer
                                {...getRootProps()}
                                existPhoto={RenderArchiveProfile()}
                            >
                                <Logo background={RenderArchiveProfile()}>
                                    <ContainerCamera existPhoto={RenderArchiveProfile()}>
                                        <ImgCamera src={camera} />
                                    </ContainerCamera>
                                </Logo>
                                <input
                                    {...getInputProps()}
                                    onChange={SelectedPhotoCard}
                                />
                            </ContainerPointer>
                        )}
                    </Dropzone>
                    <NameUser>
                        {infoSeller?.name}
                        <NameUser fontWeight="bold">
                            {infoSeller?.email}
                        </NameUser>
                    </NameUser>
                </ContainerInfoProfile>
            </Content>
            <ContainerButtonAction>
                <ButtonAuth onClick={EditProfile} label="SALVAR INFORMAÇÕES" colorButton={null} colorText={null} />
            </ContainerButtonAction>
        </ContainerBoxProfile>
    );

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent
                        visibleDrawer={visibleDrawer}
                        setVisibleDrawer={setVisibleDrawer}
                    />
                    {RenderScreen()}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default ProfilePage;
