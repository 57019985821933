// js
import consts from '../utils/consts';
import functions from '../utils/functions';

// constants
const { baseUrl } = consts;

// interfaces
interface GetInfoSellerInterfaces {
    status: number,
    result: any,
    message: string,
}

// api
async function GenerateRecharge(
    value: string,
    type: 'pix' | 'boleto' | 'card',
    dueDateBoleto: string,
    numberCard: string,
    validityCard: string,
    codeCard: string,
    nameCard: string,
    idAccount: string,
    typeAccount: string,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        let data = {};
        if (type === 'card') {
            data = {
                type,
                value: functions.priceUnmask(value),
                numberCard,
                validityCard,
                codeCard,
                nameCard,
            };
        }
        if (type === 'boleto') {
            data = {
                type,
                value: functions.priceUnmask(value),
                dueDateBoleto,
            };
        }
        if (type === 'pix') {
            data = {
                type,
                value: functions.priceUnmask(value),
            };
        }
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            body,
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const path = `${baseUrl}/recharges?idAccount=${idAccount}&typeAccount=${typeAccount}`;
        const response = await fetch(path, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            result: '',
            message: '',
        };
    }
}

async function GetRecharges(
    period: '' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS',
    idAccount: string,
    typeAccount: string,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const path = `${baseUrl}/recharges?idAccount=${idAccount}&typeAccount=${typeAccount}`;
        const response = await fetch(path, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            result: [],
            message: '',
        };
    }
}

async function HistoryEntryAndExit(
    status: string,
    idUser: string,
    typeAccount: string,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const method = 'GET';
        const headers = {
            'x-access-token': token,
        };
        const configs = {
            method,
            headers,
        };
        const validationPath = status ? `${baseUrl}/withdraw?idAccount=${idUser}&typeAccount=${typeAccount}&status=${status}` : `${baseUrl}/withdraw?idAccount=${idUser}&typeAccount=${typeAccount}`;
        const response = await fetch(validationPath, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            status: 500,
            result: [],
            message: '',
        };
    }
}

async function ActionRecharges(
    value: number,
    idAccount: string,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const data = {
            value,
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            body,
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const path = `${baseUrl}/recharges?idAccount=${idAccount}&typeAccount=sellers`;
        const response = await fetch(path, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            result: '',
            message: '',
        };
    }
}

export default {
    GenerateRecharge,
    GetRecharges,
    HistoryEntryAndExit,
    ActionRecharges,
};
