/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React from 'react';
import Dropzone from 'react-dropzone';

// js
import colors from '../../../styles/colors';
import {
    ContainerDeliveryMen,
    TitleScreen,
    ContentCreateEstablishment,
    ContainerBox,
    TitleCreateSeller,
    ContainerPointer,
    Logo,
    ContainerCamera,
    ImgCamera,
    ContainerFrame,
    TitleLabel,
    Input,
    ContainerSeparatorInputs,
    ContainerSeparator,
    ContainerInfo,
    Button,
    TitleDefault,
    Select,
    Option,
    ContainerSelectOptions,
    SelectRadius,
    Checked,
    DivNull,
} from '../establishmentStyles';
import { DataInputsCreateSellerInterfaces } from '../establishmentInterfaces';
import { camera } from '../../../assets';
import functions from '../../../utils/functions';
import establishmentConstants from '../establishmentConstants';

const CreateEstablishmentComponent = (
    inputs: DataInputsCreateSellerInterfaces,
    setInputs: (v: DataInputsCreateSellerInterfaces) => void,
    visibleDrawer: boolean,
    GoToSellers: () => void,
    CreateSeller: () => void,
    allBank: Array<{ label: string, id: string }>,
    allBankDefault: Array<{ label: string, value: string }>,
    openModalBank: () => void,
): React.ReactElement => {
    // methods
    function RenderInput(
        label: string,
        type: 'typePix' | 'name' | 'email' | 'password' | 'confirmPassword' | 'identifier' | 'cep' | 'number' | 'address' | 'complement' | 'telephone' | 'pix' | 'cashback' | 'bank_number' | 'account_number' | 'agency_number' | 'account_document',
        divideInput: string,
        disabled: boolean,
    ) {
        // constants
        const ValidationAddress = type === 'address' && inputs.cep.length === 9 ? `${inputs?.address}, ${inputs?.number} - ${inputs?.neighborhood}, ${inputs?.city}` : inputs[type];

        // methods
        const onChange = (txt: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = txt.target;
            if (type === 'identifier' || type === 'account_document') {
                const OnlyNumber = value.replace(/\D/g, '');
                if (type === 'account_document' && OnlyNumber.length <= 14) {
                    return setInputs({
                        ...inputs,
                        [type]: functions.MaskIdentifier(OnlyNumber),
                    });
                }
                return setInputs({
                    ...inputs,
                    [type]: functions.MaskCNPJ(OnlyNumber),
                });
            }
            if (type === 'cep') {
                return setInputs({ ...inputs, [type]: functions.MaskCep(value) });
            }
            if (type === 'telephone') {
                return setInputs({ ...inputs, [type]: functions.MaskPhone(value) });
            }
            if (type === 'number' || type === 'account_number' || type === 'bank_number' || type === 'agency_number') {
                return setInputs({ ...inputs, [type]: value.replace(/[^0-9]/g, '') });
            }
            if (type === 'cashback') {
                if (value.replace(/[^0-9]/g, '').length <= 4) {
                    return setInputs({ ...inputs, [type]: Number(value.replace(/[^0-9]/g, '')) });
                }
                return setInputs({ ...inputs, [type]: inputs.cashback });
            }
            if (type === 'pix') {
                if (inputs.typePix === 'cpf') {
                    const OnlyNumber = value.replace(/\D/g, '');
                    return setInputs({
                        ...inputs,
                        [type]: functions.MaskIdentifier(OnlyNumber),
                    });
                }
                if (inputs.typePix === 'telephone') {
                    return setInputs({
                        ...inputs,
                        [type]: functions.MaskPhone(value),
                    });
                }
                if (['email', 'hash'].includes(inputs.typePix)) {
                    return setInputs({ ...inputs, [type]: value });
                }
            }
            return setInputs({ ...inputs, [type]: value });
        };
        const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
            const { value } = e.target;
            setInputs({ ...inputs, [type]: value, pix: type === 'typePix' ? '' : inputs.pix });
        };
        const decideMaxLength = () => {
            if (type === 'identifier' || type === 'account_document') {
                return 18;
            }
            if (type === 'pix') {
                if (inputs.typePix === 'cpf') {
                    return 14;
                }
                return 200;
            }
            return 64;
        };

        const SelectOption = (value: { id: string, label: string }) => (
            <Option selected value={value.id}>
                {value.label}
            </Option>
        );

        // main
        return (
            <ContainerFrame divideInput={divideInput}>
                <TitleLabel>{label}</TitleLabel>
                {['typePix'].includes(type) ? (
                    <Select
                        id={type}
                        value={inputs[type]}
                        onChange={onChangeSelect}
                    >
                        {establishmentConstants.allTypePix.map((value) => SelectOption(value))}
                    </Select>
                ) : (
                    <Input
                        value={ValidationAddress}
                        onChange={onChange}
                        maxLength={decideMaxLength()}
                        type={type === 'password' || type === 'confirmPassword' ? 'password' : 'email'}
                        disabled={disabled}
                    />
                )}
            </ContainerFrame>
        );
    }
    function RenderSwitch(type: string) {
        const TradeTypeUser = () => {
            if (type === 'Cashback') {
                setInputs({ ...inputs, activeCashback: !inputs.activeCashback });
            } else if (type === 'Isento de Taxas') {
                setInputs({ ...inputs, exemption: !inputs.exemption });
            } else if (type === 'Conta Corrente' || type === 'Conta Poupança') {
                setInputs({ ...inputs, account_type: type === 'Conta Corrente' ? 'CHECKING' : 'SAVINGS' });
            }
        };

        const ValidationChecked = () => {
            if (type === 'Cashback') {
                return inputs.activeCashback;
            }
            if (type === 'Conta Corrente') {
                return inputs.account_type === 'CHECKING';
            }
            if (type === 'Conta Poupança') {
                return inputs.account_type === 'SAVINGS';
            }
            return inputs.exemption;
        };

        return (
            <ContainerSelectOptions>
                <SelectRadius onClick={TradeTypeUser}>
                    {ValidationChecked() ? (
                        <Checked />
                    ) : null}
                </SelectRadius>
                <TitleLabel marginBottom="0px">{type}</TitleLabel>
            </ContainerSelectOptions>
        );
    }
    const RenderArchiveProfile = () => {
        if (typeof inputs.photo !== 'string') {
            return inputs.photo?.file;
        }
        if (typeof inputs.photo === 'string') {
            return inputs.photo;
        }
        return '';
    };

    function SelectedPhotoCard(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        setInputs({ ...inputs, photo: newDataFiles });
    }

    return (
        <ContainerDeliveryMen visibleItem={visibleDrawer}>
            <TitleScreen>{inputs.id ? 'Editar Empresa' : 'Cadastrar Empresa'}</TitleScreen>
            <ContentCreateEstablishment>
                <ContainerBox>
                    <TitleCreateSeller>Detalhes da empresa</TitleCreateSeller>
                    <ContainerSeparatorInputs>
                        <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                            {({ getRootProps, getInputProps }) => (
                                <ContainerPointer
                                    {...getRootProps()}
                                    existPhoto={RenderArchiveProfile()}
                                    cursor={!inputs.id}
                                >
                                    <Logo src={RenderArchiveProfile()} />
                                    {!inputs.id ? (
                                        <input
                                            {...getInputProps()}
                                            onChange={SelectedPhotoCard}
                                        />
                                    ) : null}
                                    <ContainerCamera existPhoto={RenderArchiveProfile()}>
                                        <ImgCamera src={camera} />
                                    </ContainerCamera>
                                </ContainerPointer>
                            )}
                        </Dropzone>
                        <ContainerSeparator>
                            {RenderInput('Nome da empresa', 'name', '100%', inputs.id !== 0)}
                            {RenderInput('CNPJ', 'identifier', '100%', inputs.id !== 0)}
                            {/* {RenderInput('Tipo do PIX', 'typePix', '100%', false)} */}
                            {/* {RenderInput('PIX', 'pix', '100%', false)} */}
                            {RenderInput('Telefone', 'telephone', '100%', false)}
                            {RenderInput('CNPJ/CPF da Conta', 'account_document', '100%', false)}
                            {/* {RenderInput('Banco', 'bank_number', '100%', false)} */}
                            <ContainerFrame divideInput="100%">
                                <TitleLabel>Banco</TitleLabel>
                                <DivNull onClick={openModalBank}>
                                    <Input
                                        value={allBank.find(
                                            (el) => el?.id === inputs.bank_number,
                                        )?.label}
                                        placeholder="Clique aqui para selecionar um banco"
                                        disabled
                                    />
                                </DivNull>
                            </ContainerFrame>
                            {RenderInput('Número da Conta + Dígito', 'account_number', '100%', false)}
                            {RenderInput('Agência', 'agency_number', '100%', false)}
                            <ContainerSeparatorInputs>
                                {RenderSwitch('Conta Corrente')}
                                {RenderSwitch('Conta Poupança')}
                            </ContainerSeparatorInputs>
                            <ContainerSeparatorInputs>
                                {RenderSwitch('Isento de Taxas')}
                                {RenderSwitch('Cashback')}
                            </ContainerSeparatorInputs>
                            {inputs.activeCashback ? (
                                RenderInput('Cashback(%)', 'cashback', '50%', false)
                            ) : null}
                        </ContainerSeparator>
                    </ContainerSeparatorInputs>
                    {RenderInput('Email', 'email', '100%', false)}
                    <ContainerSeparatorInputs>
                        {RenderInput('Senha', 'password', '48%', inputs.id !== 0)}
                        {RenderInput('Confirme a senha', 'confirmPassword', '48%', inputs.id !== 0)}
                    </ContainerSeparatorInputs>
                </ContainerBox>
                <ContainerBox>
                    <TitleCreateSeller>Endereço</TitleCreateSeller>
                    {RenderInput('Endereço', 'address', '100%', true)}
                    <ContainerSeparatorInputs>
                        {RenderInput('CEP da loja', 'cep', '25%', false)}
                        {RenderInput('Número', 'number', '35%', false)}
                        {RenderInput('Complemento', 'complement', '35%', false)}
                    </ContainerSeparatorInputs>
                </ContainerBox>
            </ContentCreateEstablishment>
            <ContainerInfo flexDirection="row" justifyContent="center" marginTop="40px">
                <Button onClick={CreateSeller}>
                    <TitleDefault fontWeight="bold" fontSize="15px" color={colors.basic.white} cursorActive>{inputs.id ? 'EDITAR EMPRESA' : 'CRIAR EMPRESA'}</TitleDefault>
                </Button>
                <Button onClick={GoToSellers}>
                    <TitleDefault fontWeight="bold" fontSize="15px" color={colors.basic.white} cursorActive>VOLTAR</TitleDefault>
                </Button>
            </ContainerInfo>
        </ContainerDeliveryMen>
    );
};

export default CreateEstablishmentComponent;
