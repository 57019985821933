// js
import { DataArchiveInterface } from '../../interfaces';
import { DataInputsCreateSellerInterfaces } from './establishmentInterfaces';

// constants
const initialArchive: DataArchiveInterface = {
    eventFile: new Blob(),
    eventName: '',
    file: '',
};

const initialInputsCreateSeller: DataInputsCreateSellerInterfaces = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    photo: initialArchive,
    identifier: '',
    address: '',
    cep: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    pix: '',
    telephone: '',
    exemption: false,
    typePix: 'hash',
    cashback: 0,
    activeCashback: false,
    id: 0,
    agency_number: '',
    bank_number: '',
    account_document: '',
    account_type: 'CHECKING',
    account_number: '',
};

const allTypePix: Array<{ id: string, label: string }> = [
    {
        id: 'cpf',
        label: 'CPF',
    },
    {
        id: 'telephone',
        label: 'Telefone',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'hash',
        label: 'Hash',
    },
];

export default {
    initialInputsCreateSeller,
    allTypePix,
};
