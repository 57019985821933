// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ContainerFinanceInterfaces {
    visibleItem?: boolean,
}

interface TabOptionInterfaces {
    focus: boolean,
}

interface ContainerInfoInterfaces {
    flexDirection?: string,
    justifyContent?: string,
}

interface ContainerInfoTabsInterfaces {
    width?: string,
}

interface ContainerSelectInterfaces {
    minWidth?: string,
    width?: string,
    marginLeft?: string,
}

interface ContainerFrameInterfaces {
    divideInput: string,
}

interface ContainerFiltersInterfaces {
    backgroundColor?: string,
    borderBottom?: string,
    borderRadius?: string,
    marginBottom?: string,
    height?: string,
    width?: string,
}

interface TextDefaultInterfaces {
    backgroundColor?: string,
    padding?: string,
    color?: string,
    fontSize?: string,
    fontWeight?: string,
    width?: string,
}

interface ContainerSellerInterfaces {
    justifyContent?: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreenApp};
`;

export const ContainerInner = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const TitleScreen = styled.div`
    color: ${colors.basic.black};
    font-weight: bold;
    font-size: 24px;
`;

export const ContainerFinance = styled.div<ContainerFinanceInterfaces>`
    position: absolute;
    flex-direction: column;
    transition-duration: 400ms;
    display: flex;
    width: 100%;
    padding-left: ${(props) => (props.visibleItem ? '130px' : '60px')};
    top: 70px;
    max-height: 85%;
    overflow-y: auto;
`;

export const ContentFinance = styled.div`
    width: 100%;
    margin-top: 30px;
`;

export const ContainerInfo = styled.div<ContainerInfoInterfaces>`
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'column')};
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
`;

export const TabOption = styled.div<TabOptionInterfaces>`
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => (props.focus ? colors.theme.backgroundTabs : colors.theme.backgroundContainerInfoTabs)};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    height: 50px;
    padding: 5px;
    margin-right: 5px;
    width: 19%;
    @media (max-width: 1200px) {
        height: 40px;
    }
    @media (max-width: 500px) {
        height: 30px;
    }
`;

export const TextTabOption = styled.text`
    color: ${colors.basic.black};
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    @media (max-width: 1200px) {
        font-size: 12px;
    }
    @media (max-width: 500px) {
        font-size: 8px;
    }
`;

export const ContainerInfoTabs = styled.div<ContainerInfoTabsInterfaces>`
    display: flex;
    flex-direction: column;
    background-color: ${colors.theme.backgroundBoxCreateSeller};
    flex-direction: column;
    padding: 15px 15px 15px 15px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: ${(props) => (props.width || '50%')};
`;

export const ImgTab = styled.img`
    object-fit: contain;
    height: 20px;
    margin-right: 10px;
    width: 20px;
    @media (max-width: 1200px) {
        height: 16px;
        width: 16px;
    }
    @media (max-width: 800px) {
        height: 12px;
        width: 12px;
    }
`;

export const ContainerSeparatorInputs = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const ContainerFrame = styled.div<ContainerFrameInterfaces>`
    flex-direction: column;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    width: ${(props) => (props.divideInput || '100%')};
`;

export const TitleLabel = styled.text`
    color: ${colors.theme.labelInputsCreateSeller};
    font-size: 15px;
    margin-bottom: 5px;

    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const Input = styled.input`
    color: ${colors.basic.black};
    background-color: ${colors.basic.white};
    border: none;
    border-radius: 5px;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    @media (max-width: 1200px) {
        font-size: 11px;
        height: 30px;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        height: 20px;
    }
`;

export const ContainerFilters = styled.div<ContainerFiltersInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    border-radius: ${(props) => (props.borderRadius || '5px 5px 0px 0px')};
    border-bottom: ${(props) => (props.borderBottom || 'none')};
    padding-left: 10px;
    padding-right: 10px;
    height: ${(props) => (props.height || 'auto')};
    width: ${(props) => (props.width || '100%')};
    @media (max-width: 1200px) {
        height: 40px;
    }
`;

export const TextDefault = styled.text<TextDefaultInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    font-size: ${(props) => (props.fontSize || '14px')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    border-radius: 5px;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    padding: ${(props) => (props.padding || '0px')};
    text-align: center;
    width: ${(props) => (props.width || '24%')};
    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const ButtonFilters = styled.div<TabOptionInterfaces>`
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => (props.focus ? colors.theme.backgroundFilters : 'transparent')};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    height: 30px;
    width: 20%;
`;

export const ContainerShadow = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 100%;
`;

export const ContainerSeller = styled.div<ContainerSellerInterfaces>`
    align-items: center;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    width: 24%;
    display: flex;
`;

export const ContainerSelect = styled.select<ContainerSelectInterfaces>`
    background-color: ${colors.basic.white};
    border: 1px solid ${colors.theme.backgroundHeaderScreenApp};
    border-radius: 20px;
    padding-left: 5px;
    color: ${colors.basic.gray};
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 11px;
    height: 30px;
    margin-left: ${(props) => (props.marginLeft || '10px')};
    width: ${(props) => (props.width || '50%')};
    min-width: ${(props) => (props.minWidth || 'auto')};
`;

export const Option = styled.option``;

export const TitlePayment = styled.text`
    color: ${colors.basic.black};
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
        font-size: 18px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

export const ContainerDocumentPayment = styled.iframe`
    min-width: 100%;
    border: none;
    min-height: 100%;
    max-height: 100%;
`;

export const ContainerAdjustDocument = styled.div`
    max-width: 95%;
    min-width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px;
    min-height: 85vh;
    max-height: 85vh;
`;
