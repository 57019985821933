// libraries
import styled from 'styled-components';

export const ImageGoBack = styled.img`
    object-fit: contain;
    cursor: pointer;
    height: 30px;
    margin-left: 10px;
    width: 30px;
`;
