// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ContainerDeliveryMenInterfaces {
    visibleItem?: boolean,
}

interface ContainerHeaderInterfaces {
    backgroundColor?: string,
    justifyContent?: string,
    cursorActive?: boolean,
    padding?: string,
    marginBottom?: string,
}

interface LabelInterface {
    size?: number,
    textAlign?: string,
    marginBottom?: string,
}

interface HeaderInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
    marginBottom?: string,
    marginLeft?: string,
    marginTop?: string,
    marginRight?: string,
    backgroundColor?: string,
    widthText?: string,
    padding?: string,
    cursorActive?: boolean,
    alignSelf?: string,
}

interface ContainerNullInterfaces {
    alingItems?: string,
    marginLeft?: string,
    justifyContent?: string,
    width?: string,
}

interface TabOptionInterfaces {
    focus: boolean,
}

interface ContainerInfoInterfaces {
    alignItems?: string,
    flexDirection?: string,
    marginRight?: string,
    width?: string,
    marginBottom?: string,
    justifyContent?: string,
    backgroundColor?: string,
    marginTop?: string,
}

interface TextDefaultInterfaces {
    backgroundColor?: string,
    padding?: string,
    color?: string,
    fontSize?: string,
    fontWeight?: string,
}

interface ContainerOpenDropDawnInterfaces {
    visibleItem?: boolean,
    isOpen?: boolean,
}

interface ContentDeliveryMenInterfaces {
    flexDirection?: string,
    marginTop?: string
}

interface ContainerPointerInterfaces {
    existPhoto: string,
    cursor?: boolean
}

interface ButtonInterfaces {
    tradeBackground?: boolean,
}

interface ContainerFiltersInterfaces {
    backgroundColor?: string,
    borderBottom?: string,
    borderRadius?: string,
    marginBottom?: string,
    height?: string,
    width?: string,
}

interface ContainerSellerInterfaces {
    justifyContent?: string,
}

interface ContainerFrameInterfaces {
    divideInput: string,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreenApp};
`;

export const ContainerInner = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const PhotoSeller = styled.img`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 4px;
    @media (max-width: 1200px) {
        width: 25px;
        height: 25px;
    }
    @media (max-width: 800px) {
        width: 20px;
        height: 20px;
    }
    @media (max-width: 500px) {
        width: 15px;
        height: 15px;
    }
`;

export const CheckBox = styled.input`
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
    height: 15px;
    width: 15px;
    @media (max-width: 500px) {
        height: 10px;
        width: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
`;

export const ContainerInfo = styled.div<ContainerInfoInterfaces>`
    align-items: ${(props) => (props.alignItems || 'flex-start')};
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'column')};
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    width: ${(props) => (props.width || 'auto')};
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
`;

export const ContainerDeliveryMen = styled.div<ContainerDeliveryMenInterfaces>`
    position: absolute;
    flex-direction: column;
    transition-duration: 400ms;
    display: flex;
    min-width: 100%;
    padding-left: ${(props) => (props.visibleItem ? '130px' : '60px')};
    top: 70px;
    max-height: 85%;
    overflow-y: auto;
`;

export const TitleScreen = styled.div`
    color: ${colors.basic.black};
    font-weight: bold;
    font-size: 24px;
`;

export const ContentDeliveryMen = styled.div<ContentDeliveryMenInterfaces>`
    width: 100%;
    margin-top: ${(props) => (props.marginTop || '30px')};
    display: flex;
    justify-content: space-between;
    flex-direction: ${(props) => (props.flexDirection || 'column')};
`;

export const ContainerImgEllipsis = styled.div`
    min-width: 20px;
`;

export const ContainerHeader = styled.div<ContainerHeaderInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor || colors.theme.gray)};
    display: flex;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    cursor: ${(props) => (props.cursorActive ? 'pointer' : 'default')};
    padding: ${(props) => (props.padding || '5px 10px 5px 10px')};
    @media (max-width: 500px) {
        height: 30px;
    }
`;

export const TitleDefault = styled.text<HeaderInterfaces>`
    color: ${(props) => (props.color || colors.theme.colorTextDeliveryMen)};
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '14px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    margin-left: ${(props) => (props.marginLeft || '0px')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    width: ${(props) => (props.widthText || 'auto')};
    max-width: ${(props) => (props.widthText || 'auto')};
    padding: ${(props) => (props.padding || '0px 0px 0px 0px')};
    cursor: ${(props) => (props.cursorActive ? 'pointer' : 'default')};
    align-self: ${(props) => (props.alignSelf || 'auto')};
    border-radius: 3px;
    word-break: break-all;
    @media (max-width: 1200px) {
        font-size: 11px;
    }
    @media (max-width: 800px) {
        font-size: 9px;
        white-space: nowrap; // Removendo quebra de linha
        overflow: hidden; // Removendo a barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final do texto
    }
    @media (max-width: 500px) {
        font-size: 5px;
        white-space: nowrap; // Removendo quebra de linha
        overflow: hidden; // Removendo a barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final do texto
    }
`;

export const ImgEllipsis = styled.img`
    height: 20px;
    width: 20px;
    @media (max-width: 1200px) {
        width: 15px;
        height: 15px;
    }
    @media (max-width: 800px) {
        width: 12px;
        height: 12px;
    }
    @media (max-width: 500px) {
        width: 10px;
        height: 10px;
    }
`;

export const ContainerOpenDropDawn = styled.button<ContainerOpenDropDawnInterfaces>`
    align-items: flex-end;
    background-color: ${(props) => (props.isOpen ? colors.basic.white : 'transparent')};
    flex-direction: column;
    display: flex;
    justify-content: ${(props) => (props.isOpen ? 'flex-start' : 'center')};
    border-radius: 5px;
    border: none;
    height: ${(props) => (props.isOpen ? 'auto' : '0px')};
    padding: ${(props) => (props.isOpen ? '10px' : '0px')};
    cursor: pointer;
    transition-duration: 400ms;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    position: ${(props) => (props.isOpen ? 'absolute' : 'relative')};
    right: ${(props) => (props.isOpen ? '25px' : '0px')};
    box-shadow: ${(props) => (props.isOpen ? '0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2)' : 'none')};
    width: ${(props) => (props.isOpen ? '10%' : 'auto')};
`;

export const ContainerOptions = styled.div`
    display: flex;
    margin-top: 3px;
    padding: 2px 0px 2px 0px;
    width: 100%;
`;

export const TabOption = styled.text<TabOptionInterfaces>`
    color: ${(props) => (props.color || colors.theme.colorTextDeliveryMen)};
    cursor: pointer;
    font-weight: 500;
    display: flex;
    font-size: 12px;
    @media (max-width: 1200px) {
        font-size: 11px;
    }
    @media (max-width: 800px) {
        font-size: 9px;
    }
    @media (max-width: 500px) {
        font-size: 5px;
    }
`;

export const ContainerBodyDeliveryMen = styled.div<ContainerOpenDropDawnInterfaces>`
    align-items: center;
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 5px 10px 5px 10px;
`;

export const ContainerNull = styled.div<ContainerNullInterfaces>`
    align-items: ${(props) => (props.alingItems || 'flex-start')};
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    margin-left: ${(props) => (props.marginLeft || '5px')};
    width: ${(props) => (props.width || 'auto')};
`;

export const TabOptionScreen = styled.div<TabOptionInterfaces>`
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => (props.focus ? colors.theme.backgroundTabs : colors.theme.redLightLow)};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    height: 50px;
    margin-right: 10px;
    width: 25%;
    @media (max-width: 1200px) {
        height: 40px;
    }
    @media (max-width: 500px) {
        height: 30px;
    }
`;

export const TextTabOption = styled.text`
    color: ${colors.basic.white};
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    @media (max-width: 1200px) {
        font-size: 12px;
    }
    @media (max-width: 500px) {
        font-size: 8px;
    }
`;

export const TabCreateEstablishment = styled.div`
    align-self: center;
    align-items: center;
    cursor: pointer;
    background-color: ${colors.theme.backgroundTabs};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    height: 50px;
    margin-top: 50px;
    width: 25%;
    @media (max-width: 1200px) {
        height: 40px;
    }
    @media (max-width: 500px) {
        height: 30px;
    }
`;

export const ContentCreateEstablishment = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

export const ContainerBox = styled.div`
    background-color: ${colors.theme.backgroundBoxCreateSeller};
    padding: 20px;
    width: 48%;
    @media (max-width: 1200px) {
        margin-bottom: 20px;
        width: 100%;
    }
`;

export const TitleCreateSeller = styled.text`
    color: ${colors.basic.black};
    font-size: 20px;
    @media (max-width: 1200px) {
        font-size: 18px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

export const ContainerPointer = styled.button<ContainerPointerInterfaces>`
    background-color: ${colors.basic.white};
    border: 2px solid ${colors.theme.backgroundScreen};
    cursor: ${(props) => (props.cursor ? 'pointer' : 'default')};
    border-radius: 50%;
    display: flex;
    align-items: ${(props) => (props.existPhoto ? 'center' : 'flex-end')};
    justify-content: ${(props) => (props.existPhoto ? 'center' : 'flex-end')};
    margin-top: 20px;
    height: 122px;
    width: 122px;
    @media (max-width: 1200px) {
        height: 82px;
        width: 82px;
    }
    @media (max-width: 600px) {
        height: 42px;
        width: 42px;
    }
`;

export const ContainerSelectOptions = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 48%;
    height: 40px;
    @media (max-width: 1200px) {
        height: 30px;
    }
    @media (max-width: 600px) {
        height: 20px;
    }
`;

export const Logo = styled.img`
    border-radius: 50%;
    height: 120px;
    object-fit: cover;
    display: ${(props) => (props.src ? 'flex' : 'none')};
    /* margin-right: 100px; */
    /* margin-top: 10px; */
    width: 120px;
    @media (max-width: 1200px) {
        height: 80px;
        width: 80px;
    }
    @media (max-width: 600px) {
        height: 40px;
        width: 40px;
    }
`;

export const ContainerCamera = styled.div<ContainerPointerInterfaces>`
    align-items: center;
    background-color: ${colors.theme.backgroundScreen};
    display: ${(props) => (props.existPhoto ? 'none' : 'flex')};
    height: 26px;
    justify-content: center;
    border-radius: 13px;
    width: 26px;
    @media (max-width: 1200px) {
        height: 18px;
        width: 18px;
    }
    @media (max-width: 600px) {
        height: 14px;
        width: 14px;
    }
`;

export const ImgCamera = styled.img`
    object-fit: contain;
    height: 17px;
    width: 17px;
    @media (max-width: 1200px) {
        height: 13px;
        width: 13px;
    }
    @media (max-width: 600px) {
        height: 9px;
        width: 9px;
    }
`;

export const ContainerFrame = styled.div<ContainerFrameInterfaces>`
    flex-direction: column;
    display: flex;
    margin-top: 20px;
    width: ${(props) => (props.divideInput || '100%')};
`;

export const TitleLabel = styled.text<LabelInterface>`
    text-align: ${(props) => (props?.textAlign || 'start')};
    color: ${colors.theme.labelInputsCreateSeller};
    font-size: 15px;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};

    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

interface InputInterfaces {
    border?: string,
}

export const Input = styled.input<InputInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    background-color: ${colors.basic.white};
    border: ${(props) => (props.border || 'none')};
    border-radius: 5px;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    @media (max-width: 1200px) {
        font-size: 11px;
        height: 30px;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        height: 20px;
    }
`;

export const ContainerSeparatorInputs = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const ContainerSeparator = styled.div`
    width: 70%;
`;

export const Button = styled.button<ButtonInterfaces>`
    background-color: ${colors.theme.backgroundTabs};
    height: 60px;
    width: 200px;
    border-radius: 20px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 20px;
    :hover {
        background-color: ${`${colors.theme.backgroundTabs}D9`};
    }
    @media (max-width: 1200px) {
        height: 40px;
        width: 120px;
        border-radius: 10px;
    }
    @media (max-width: 500px) {
        height: 30px;
        width: 100px;
        border-radius: 10px;
    }
`;

export const ContainerModal = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
`;

export const ContainerBoxModal = styled.div`
    /* align-items: center; */
    background-color: ${colors.theme.backgroundBodyModal};
    /* display: flex; */
    /* flex-direction: column; */
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 80px;
    max-height: calc(100vh - 210px);
    width: 80%;
    max-width: 1000px;
    overflow-y: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const ContainerShadow = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-top: 30px;
    width: 100%;
`;

export const ContainerFilters = styled.div<ContainerFiltersInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    border-radius: ${(props) => (props.borderRadius || '5px 5px 0px 0px')};
    border-bottom: ${(props) => (props.borderBottom || 'none')};
    padding-left: 10px;
    padding-right: 10px;
    height: ${(props) => (props.height || 'auto')};
    width: ${(props) => (props.width || '100%')};
    @media (max-width: 1200px) {
        height: 40px;
    }
`;

export const TextDefault = styled.text<TextDefaultInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    font-size: ${(props) => (props.fontSize || '14px')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    border-radius: 5px;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    padding: ${(props) => (props.padding || '0px')};
    cursor: ${(props) => (props.cursor || 'default')};
    text-align: center;
    width: 24%;
    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const TextDisabledFixed = styled.text`
    color: ${colors.theme.logout};
    background-color: ${colors.theme.backgroundDisabledFixedMotoboy};
    font-size: 12px;
    padding: 5px;
    border-radius: 10;
    font-weight: bold;
    cursor: pointer;
    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const ContainerSeller = styled.div<ContainerSellerInterfaces>`
    align-items: center;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    width: 24%;
    display: flex;
`;

export const ContainerClosedModal = styled.div`
    align-items: center;
    background-color: ${colors.basic.black};
    display: flex;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 20px;
    height: 40px;
    width: 40px;
`;

export const TextClosedModal = styled.text`
    font-size: 26px;
    font-weight: bold;
`;

export const ContainerHeaderModal = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const Select = styled.select`
    color: ${colors.theme.backgroundDrawer};
    background-color: ${colors.basic.white};
    border: none;
    border-radius: 5px;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    @media (max-width: 1200px) {
        font-size: 11px;
        height: 30px;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        height: 20px;
    }
`;

export const Option = styled.option``;

export const SelectRadius = styled.div`
    border-radius: 10px;
    display: flex;
    cursor: pointer;
    background-color: ${colors.basic.white};
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    height: 20px;
    width: 20px;
`;

export const Checked = styled.div`
    background-color: ${colors.theme.backgroundButtonLogin};
    border-radius: 6px;
    cursor: pointer;
    height: 12px;
    width: 12px;
`;

export const ContainerBanks = styled.div`
    background-color: ${colors.basic.white};
    display: flex;
    flex-direction: column;
`;

export const TextBank = styled.text`
    margin-top: 15px;
    cursor: pointer;
    color: ${colors.basic.black};
    padding: 5px;
`;

export const DivNull = styled.div`
    cursor: pointer;
`;
