// libraries
import React, { useState } from 'react';

// js
import colors from '../../../../styles/colors';
import auth from '../../../../services/auth';
import {
    Container,
    Home,
    Logo,
    TextActionNavigation,
    ContainerPhoto,
    Header,
    ContainerInner,
    TextInfo,
    ContainerBoxAuth,
    HeaderInput,
    ContainerInfoHeader,
} from './recoverPasswordStyles';
import { backgroundLogin, logo } from '../../../../assets/index';

// components
import InputAuth from '../../components/Input';
import ButtonAuth from '../../components/Button';

// components (navigation)
import ToNavigation from '../../../../routes/navigation';

const RecoverPasswordPage: React.FC = () => {
    // states
    const [email, setEmail] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const onChangeEmail = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setEmail(valueFormat);
    };

    const RecoverPassword = async () => {
        const GetRecover = await auth.RecoverPassword(email);
        if (GetRecover.status === 200) {
            setEmail('');
        }
        // eslint-disable-next-line no-alert
        alert(GetRecover.message);
    };

    const GoToLogin = () => {
        setPathRoute('/');
        setRedirect(true);
    };

    // main
    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <ContainerPhoto background={backgroundLogin}>
                    <ContainerInner>
                        <ContainerInfoHeader>
                            <div>
                                <Header>Deixo Já</Header>
                                <TextActionNavigation color={colors.basic.white}>
                                    Seu parceiro de entregas!
                                </TextActionNavigation>
                            </div>
                            <Logo src={logo} />
                        </ContainerInfoHeader>
                        <TextInfo>Esqueceu sua senha? Preencha os</TextInfo>
                        <TextInfo>dados que recuperamos para você!</TextInfo>
                        <ContainerBoxAuth>
                            <HeaderInput>Digite seu e-mail</HeaderInput>
                            <InputAuth
                                placeholder="Digite seu E-mail"
                                value={email}
                                type="email"
                                onChange={onChangeEmail}
                            />
                            <TextActionNavigation
                                marginTop="20px"
                                onClick={GoToLogin}
                                cursor
                            >
                                Já possui uma conta? Acesse!
                            </TextActionNavigation>
                        </ContainerBoxAuth>
                        <ContainerInfoHeader>
                            <ButtonAuth onClick={RecoverPassword} label="REDEFINIR MINHA SENHA AGORA" colorButton={null} colorText={null} />
                        </ContainerInfoHeader>
                    </ContainerInner>
                </ContainerPhoto>
            </Home>
        </Container>
    );
};

export default RecoverPasswordPage;
