// js
import colors from '../../styles/colors';
import {
    // iconTicket,
    iconCard,
    iconPix,
} from '../../assets/index';
import { DataInputsInterfaces } from './financeInterfaces';

// interfaces
interface AllFiltersInterfaces {
    title: string,
    id: '' | 'LAST_7_DAYS' | 'LAST_15_DAYS' | 'LAST_30_DAYS',
}

// constants
const allTabs = [
    // {
    //     icon: iconTicket,
    //     id: 1,
    //     title: 'Recarga com Boleto',
    // },
    {
        icon: iconPix,
        id: 2,
        title: 'Recarga',
    },
    // {
    //     icon: iconCard,
    //     id: 3,
    //     title: 'Recarga com cartão',
    // },
    // {
    //     icon: iconCard,
    //     id: 4,
    //     title: 'Histórico de Recargas',
    // },
    {
        icon: iconCard,
        id: 5,
        title: 'Histórico de Entrada/Saída',
    },
];

const validationCards = [
    {
        id: 1,
        type: 'visa',
        regex: /^4[0-9]{12}(?:[0-9]{3})/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 2,
        type: 'mastercard',
        regex: /^5[1-5][0-9]{14}/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 3,
        type: 'amex',
        regex: /^3[47][0-9]{13}/,
        mask: '9999 999999 99999',
    },
    {
        id: 4,
        type: 'diners',
        regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        mask: '9999 999999 9999',
    },
    {
        id: 5,
        type: 'discover',
        regex: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 6,
        type: 'jcb',
        regex: /^(?:2131|1800|35\d{3})\d{11}/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 7,
        type: 'elo',
        regex: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
        mask: '9999 9999 9999 9999',
    },
    {
        id: 8,
        type: 'hipercard',
        regex: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
        mask: '9999 9999 9999 9999',
    },
];

const initialInputs: DataInputsInterfaces = {
    codCard: '',
    expiration: '',
    nameCard: '',
    numberCard: '',
    rechargePix: '',
    rechargeTicket: '',
    rechargeCard: '',
    validCard: '',
    brandCard: '',
    maskNumberCard: '',
};

const stylesInputNumberCard = {
    color: colors.basic.black,
    backgroundColor: colors.basic.white,
    border: 'none',
    borderRadius: 5,
    height: 40,
    paddingLeft: 15,
    width: '100%',
};

const allFilters: Array<AllFiltersInterfaces> = [
    {
        id: '',
        title: 'Todas as Entregas',
    },
    {
        id: 'LAST_7_DAYS',
        title: 'Últimos 7 dias',
    },
    {
        id: 'LAST_15_DAYS',
        title: 'Últimos 15 dias',
    },
    {
        id: 'LAST_30_DAYS',
        title: 'Últimos 30 dias',
    },
];

const allFiltersTwo: Array<{id: string, title: string}> = [
    {
        id: '',
        title: 'TODAS',
    },
    {
        id: 'positive',
        title: 'ENTRADAS',
    },
    {
        id: 'negative',
        title: 'SAÍDAS',
    },
];

const allHistory = [
    {
        id: 1,
        status: 'finished',
        typeRecharge: 'pix',
        valueTotal: 1000,
    },
    {
        id: 2,
        status: 'cancel',
        typeRecharge: 'pix',
        valueTotal: 550,
    },
    {
        id: 3,
        status: 'cancel',
        typeRecharge: 'pix',
        valueTotal: 105,
    },
];

export default {
    allTabs,
    initialInputs,
    validationCards,
    stylesInputNumberCard,
    allFilters,
    allFiltersTwo,
    allHistory,
};
