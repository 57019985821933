// libraries
import React from 'react';

// js
import colors from '../../../styles/colors';
import { ButtonAuthStyles, TextButtonAuthStyles } from '../screens/Login/loginStyles';

// interfaces
interface Props {
    onClick: () => Promise<void>,
    label: string,
    colorButton: string | null,
    colorText: string | null,
}

const ButtonAuth: React.FC<Props> = ({
    onClick,
    label,
    colorButton,
    colorText,
}: Props) => (
    <ButtonAuthStyles onClick={onClick} color={colorButton || colors.theme.backgroundButtonLogin}>
        <TextButtonAuthStyles color={colorText || colors.basic.white}>{label}</TextButtonAuthStyles>
    </ButtonAuthStyles>
);

export default ButtonAuth;
