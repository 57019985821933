/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// js
import financeConstants from './financeConstants';
import financeApi from '../../services/finance';
import { DataInputsInterfaces } from './financeInterfaces';

const ActionPayment = async (
    idScreen: number,
    inputs: DataInputsInterfaces,
    setInputs: (v: DataInputsInterfaces) => void,
    infoSeller: any,
    // GetHistoryRecharges: any,
): Promise<void> => {
    let GeneratePayment = {
        status: 500,
        message: '',
        result: null,
    };
    if (idScreen === 1) {
        GeneratePayment = await financeApi.GenerateRecharge(
            inputs.rechargeTicket,
            'boleto',
            inputs.expiration,
            '',
            '',
            '',
            '',
            infoSeller?.id,
            infoSeller?.type === 'admin' ? 'sellers' : infoSeller?.typeAccount,
            infoSeller?.token,
        );
    }
    if (idScreen === 2) {
        GeneratePayment = await financeApi.GenerateRecharge(
            inputs.rechargePix,
            'pix',
            '',
            '',
            '',
            '',
            '',
            infoSeller?.id,
            infoSeller?.type === 'admin' ? 'sellers' : infoSeller?.typeAccount,
            infoSeller?.token,
        );
    }
    if (idScreen === 3) {
        GeneratePayment = await financeApi.GenerateRecharge(
            inputs.rechargeCard,
            'card',
            '',
            inputs.numberCard,
            inputs.validCard,
            inputs.codCard,
            inputs.nameCard,
            infoSeller?.id,
            infoSeller?.type === 'admin' ? 'sellers' : infoSeller?.typeAccount,
            infoSeller?.token,
        );
    }
    if (GeneratePayment.status === 201) {
        // eslint-disable-next-line no-alert
        alert(GeneratePayment?.message);
        setInputs(financeConstants.initialInputs);
        // GetHistoryRecharges();
    } else {
        // eslint-disable-next-line no-alert
        alert(GeneratePayment?.message);
    }
};

const RenderType = (type: string): string => {
    if (type === 'card') {
        return 'Cartão de Crédito';
    }
    if (type === 'boleto') {
        return 'Boleto Bancário';
    }
    if (type === 'pix') {
        return 'PIX';
    }

    return '';
};

export default {
    ActionPayment,
    RenderType,
};
