/* eslint-disable react/jsx-props-no-spreading */
// libraries
import React from 'react';
import Dropzone from 'react-dropzone';

// js
import colors from '../../styles/colors';
import {
    ContainerUsers,
    TitleScreen,
    TitleDefault,
    ContentUsers,
    ContainerHeader,
    // CheckBox,
    ContainerInfo,
    ContainerInfoTabs,
    // PhotoSeller,
    ContainerFrame,
    ContainerPointer,
    Logo,
    Input,
    TitleLabel,
    TitleDetailsUser,
    ContainerCamera,
    ImgCamera,
    ContainerSeparatorInputs,
    ContainerSelectOptions,
    SelectRadius,
    Checked,
    ContainerOpenDropDawn,
    ContainerImgEllipsis,
    ImgEllipsis,
    ContainerOptions,
    TabOption,
    ContainerBodyDeliveryMen,
} from './usersStyles';
import { DataSubSellerInterfaces, DataSellerInterface } from '../../interfaces';
import { DataInputsInterfaces } from './usersInterfaces';
import { logoAuth, camera, iconEllipsis } from '../../assets/index';
import sellers from '../../services/sellers';
import functions from '../../utils/functions';
import ButtonAuth from '../Auth/components/Button';
import { updateHistoryRoutes } from '../../store/modules/seller/actions';

const RenderAllSellers = (
    allSellers: Array<DataSubSellerInterfaces>,
    setAllSellers: (v: Array<DataSubSellerInterfaces>) => void,
    infoSeller: DataSellerInterface,
    GetInfoScreen: () => void,
    setIdScreen: (v: string) => void,
    setInputs: (v: DataInputsInterfaces) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    buttonProps: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    itemProps: any,
    idActionDropDawn: number | null,
    setIdActionDropDawn: (v: number | null) => void,
    setQtdDropDawn: (v: number) => void,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    historyRoutes: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dispatch: any,
): React.ReactElement | null => {
    if (allSellers.length) {
        return (
            <>
                {allSellers.map((v) => {
                    // const onChangeIsCheckedAll = () => {
                    //     const formatCheckedSelected = allSellers.map((value) => {
                    //         if (value.id === v.id) {
                    //             return {
                    //                 ...value,
                    //                 checked: !value.checked,
                    //             };
                    //         }

                    //         return value;
                    //     });
                    //     setAllSellers(formatCheckedSelected);
                    // };
                    const DeleteOneSubSeller = async () => {
                        const deleteAction = await sellers.DeleteOneOrAllSubSellers(
                            null,
                            v.id,
                            [],
                            infoSeller?.token,
                        );
                        if (deleteAction.status === 200) {
                            GetInfoScreen();
                            // eslint-disable-next-line no-alert
                            alert('SubSeller deletado!');
                        }
                    };
                    const GoToEditSubSeller = () => {
                        setIdScreen('edit');
                        setInputs({
                            // eslint-disable-next-line max-len
                            identifier: v.identifier?.length === 11 ? functions.MaskIdentifier(v.identifier) : functions.MaskCNPJ(v.identifier || ''),
                            type: v.type || 'operator',
                            photo: v.photo || '',
                            emailClient: v.email || '',
                            nameClient: v.name || '',
                            confirmPasswordClient: '',
                            passwordClient: '',
                            id: v.id,
                        });
                    };
                    const GoToEditPassword = () => {
                        setIdScreen('tradePassword');
                        setInputs({
                            identifier: v.identifier?.length === 11 ? functions.MaskIdentifier(v.identifier) : functions.MaskCNPJ(v.identifier || ''),
                            type: v.type || 'operator',
                            photo: v.photo || '',
                            emailClient: v.email || '',
                            nameClient: v.name || '',
                            confirmPasswordClient: '',
                            passwordClient: '',
                            id: v.id,
                        });
                    };
                    const arrayOptionsDropDawn = ['Alterar senha', 'Editar', 'Excluir'];
                    const openDropDawn = () => {
                        if (idActionDropDawn) {
                            setIdActionDropDawn(null);
                            setQtdDropDawn(4);
                        } else {
                            setIdActionDropDawn(v.id);
                            setQtdDropDawn(arrayOptionsDropDawn.length);
                        }
                    };
                    const RenderOptionsDropDawn = () => {
                        if (v.id === idActionDropDawn) {
                            return arrayOptionsDropDawn.map((label, index) => {
                                const onFunctionDropDawn = async () => {
                                    if (label === 'Alterar senha') {
                                        GoToEditPassword();
                                        dispatch(updateHistoryRoutes(
                                            [...historyRoutes, window.location.pathname],
                                        ));
                                    }
                                    if (label === 'Editar') {
                                        GoToEditSubSeller();
                                        dispatch(updateHistoryRoutes(
                                            [...historyRoutes, window.location.pathname],
                                        ));
                                    }
                                    if (label === 'Excluir') {
                                        DeleteOneSubSeller();
                                    }
                                };
                                return (
                                    <ContainerOptions>
                                        <TabOption
                                            {...itemProps[index]}
                                            onClick={onFunctionDropDawn}
                                            color={label === 'Excluir' ? colors.theme.backgroundButtonLogin : colors.theme.backgroundDrawer}
                                        >
                                            {label}
                                        </TabOption>
                                    </ContainerOptions>
                                );
                            });
                        }

                        return null;
                    };
                    return (
                        <ContainerHeader padding="0px 0px 0px 0px" backgroundColor={colors.basic.white} justifyContent="space-between">
                            <ContainerBodyDeliveryMen isOpen={v.id === idActionDropDawn}>
                                {/* eslint-disable-next-line max-len */}
                                {/* <CheckBox type="checkbox" checked={v.checked} onChange={onChangeIsCheckedAll} value={JSON.stringify(!v.checked)} /> */}
                                <ContainerInfo flexDirection="row" width="20%" alignItems="center" marginRight="5px">
                                    {/* <PhotoSeller src={v.photo || logoAuth} /> */}
                                    <TitleDefault marginLeft="5px" color={colors.theme.textTabs}>{v.name}</TitleDefault>
                                </ContainerInfo>
                                <ContainerInfo width="20%" marginRight="5px">
                                    <TitleDefault widthText="100%" color={colors.theme.textTabs}>{v.email}</TitleDefault>
                                </ContainerInfo>
                                <ContainerInfo width="20%" marginRight="5px">
                                    <TitleDefault
                                        padding="2px 5px 2px 5px"
                                        color={v.type === 'admin' ? colors.basic.green : colors.basic.blueLight}
                                        backgroundColor={v.type === 'admin' ? colors.theme.backgroundMediaRunCancel : colors.theme.backgroundStatusProgress}
                                    >
                                        {v.type === 'operator' ? 'Operador' : 'Admin'}
                                        {/* {v.type[0].toUpperCase() + v.type?.substr(1)} */}
                                    </TitleDefault>
                                </ContainerInfo>
                            </ContainerBodyDeliveryMen>
                            <ContainerOpenDropDawn
                                {...buttonProps}
                                isOpen={v.id === idActionDropDawn}
                                role="menu"
                                onClick={openDropDawn}
                            >
                                <ContainerImgEllipsis>
                                    <ImgEllipsis src={iconEllipsis} />
                                </ContainerImgEllipsis>
                                {RenderOptionsDropDawn()}
                            </ContainerOpenDropDawn>
                        </ContainerHeader>
                    );
                })}
            </>
        );
    }

    return null;
};

const RenderModifySubSeller = (
    inputs: DataInputsInterfaces,
    setInputs: (v: DataInputsInterfaces) => void,
    idScreen: string,
    visibleDrawer: boolean,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    CreateSubSeller: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    GoToListSubSeller: any,
): React.ReactElement => {
    // methods
    function RenderInput(
        label: string,
        type: 'nameClient' | 'emailClient' | 'passwordClient' | 'confirmPasswordClient' | 'identifier' | 'type',
        divideInput: string,
        disabled: boolean,
    ) {
        // methods
        const onChange = (txt: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = txt.target;
            if (type === 'identifier') {
                const TradeMask = value.length > 14;
                const OnlyNumber = value.replace(/\D/g, '');
                let newValue = '';
                if (TradeMask) {
                    newValue = functions.MaskCNPJ(OnlyNumber);
                }
                if (!TradeMask) {
                    newValue = functions.MaskIdentifier(OnlyNumber);
                }
                return setInputs({
                    ...inputs,
                    // eslint-disable-next-line max-len
                    [type]: newValue,
                });
            }
            return setInputs({ ...inputs, [type]: value });
        };

        const TradeTypeUser = () => {
            setInputs({ ...inputs, [type]: inputs.type === 'operator' ? 'admin' : 'operator' });
        };

        // main
        return (
            <ContainerFrame divideInput={divideInput}>
                <TitleLabel>{label}</TitleLabel>
                {type === 'type' ? (
                    <ContainerSelectOptions>
                        <SelectRadius onClick={TradeTypeUser}>
                            {inputs.type === 'operator' ? (
                                <Checked />
                            ) : null}
                        </SelectRadius>
                        <TitleLabel>Operador</TitleLabel>
                        <SelectRadius onClick={TradeTypeUser}>
                            {inputs.type === 'admin' ? (
                                <Checked />
                            ) : null}
                        </SelectRadius>
                        <TitleLabel>Admin Empresa</TitleLabel>
                    </ContainerSelectOptions>
                ) : (
                    <Input
                        value={inputs[type]}
                        onChange={onChange}
                        disabled={disabled}
                        maxLength={type === 'identifier' ? 18 : 64}
                        type={type === 'passwordClient' || type === 'confirmPasswordClient' ? 'password' : 'email'}
                    />
                )}
            </ContainerFrame>
        );
    }

    function SelectedPhotoCard(event: React.ChangeEvent<HTMLInputElement>) {
        const fileSelected = event.target.files || [];
        const newDataFiles = {
            eventFile: fileSelected[0],
            eventName: fileSelected[0].name,
            file: URL.createObjectURL(fileSelected[0]),
        };
        setInputs({ ...inputs, photo: newDataFiles });
    }

    // renders
    const RenderArchiveProfile = () => {
        if (typeof inputs.photo !== 'string') {
            return inputs.photo?.file || logoAuth;
        }
        if (typeof inputs.photo === 'string') {
            return inputs.photo || logoAuth;
        }
        return logoAuth;
    };

    const RenderTitle = () => {
        if (['create', 'edit', 'tradePassword'].includes(idScreen)) {
            return 'Adicionar/editar usuário';
        }
        if (idScreen === 'editSubSeller') {
            return '';
        }
        return 'Usuários';
    };

    // main
    return (
        <ContainerUsers visibleItem={visibleDrawer}>
            <TitleScreen>{RenderTitle()}</TitleScreen>
            <ContentUsers>
                <ContainerInfoTabs>
                    <TitleDetailsUser>Detalhes do usuário</TitleDetailsUser>
                    <Dropzone accept={['image/jpeg', 'image/jpg', 'image/png']}>
                        {({ getRootProps, getInputProps }) => (
                            <ContainerPointer
                                {...getRootProps()}
                                existPhoto={RenderArchiveProfile()}
                            >
                                <Logo background={RenderArchiveProfile()}>
                                    <ContainerCamera>
                                        <ImgCamera src={camera} />
                                    </ContainerCamera>
                                </Logo>
                                <input
                                    {...getInputProps()}
                                    onChange={SelectedPhotoCard}
                                />
                            </ContainerPointer>
                        )}
                    </Dropzone>
                    {RenderInput('Nome do usuário', 'nameClient', '100%', false)}
                    {RenderInput('E-mail do usuário', 'emailClient', '100%', false)}
                    <ContainerSeparatorInputs>
                        {RenderInput('CPF/CNPJ', 'identifier', '48%', false)}
                        {RenderInput('Tipo de acesso', 'type', '48%', false)}
                    </ContainerSeparatorInputs>
                    {RenderInput('Senha', 'passwordClient', '100%', false)}
                    {RenderInput('Confirmar senha', 'confirmPasswordClient', '100%', false)}
                </ContainerInfoTabs>
                <ContainerInfo flexDirection="row" justifyContent="flex-start">
                    <ButtonAuth onClick={CreateSubSeller} label={['editSubSeller', 'tradePassword', 'edit'].includes(idScreen) ? 'SALVAR ALTERAÇÕES' : 'CRIAR USUÁRIO'} colorButton={null} colorText={null} />
                    <ButtonAuth onClick={GoToListSubSeller} label="VOLTAR" colorButton={null} colorText={null} />
                </ContainerInfo>
            </ContentUsers>
        </ContainerUsers>
    );
};

export default {
    RenderAllSellers,
    RenderModifySubSeller,
};
