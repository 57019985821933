// libraries
import styled from 'styled-components';
import { logoAuth } from '../../assets';

// js
import colors from '../../styles/colors';

// interfaces
interface ContainerDeliveryInterfaces {
    visibleItem?: boolean,
}

interface ContainerFrameInterfaces {
    divideInput: string,
}

interface ContainerPointerInterfaces {
    existPhoto: string,
}

interface LogoInterfaces {
    background?: string,
}

interface NameUserInterfaces {
    fontWeight?: string,
}

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const Content = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

export const ContainerBox = styled.div`
    background-color: ${colors.theme.backgroundBoxCreateSeller};
    padding: 20px;
    width: 48%;
    @media (max-width: 1200px) {
        margin-bottom: 20px;
        width: 100%;
    }
`;

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreenApp};
`;

export const ContainerInner = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Logo = styled.div<LogoInterfaces>`
    border-radius: 50%;
    height: ${(props) => (props.background === logoAuth ? '110px' : '120px')};
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    width: 120px;
    @media (max-width: 1200px) {
        height: 80px;
        width: 80px;
    }
    @media (max-width: 600px) {
        height: 40px;
        width: 40px;
    }
`;

export const InputProfileStyles = styled.input`
    color: ${colors.basic.black};
    background-color: ${colors.basic.white};
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 10px;
    height: 35px;
    padding-left: 15px;
    margin-top: 10px;
    width: 100%;
`;

export const ContainerPointer = styled.button<ContainerPointerInterfaces>`
    background-color: ${colors.theme.backgroundLogoDefault};
    border: 2px solid ${colors.theme.backgroundButtonLogin};
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    margin-right: 10px;
    justify-content: flex-end;
    align-items: flex-end;
    height: 124px;
    width: 124px;
    @media (max-width: 1200px) {
        height: 82px;
        width: 82px;
    }
    @media (max-width: 600px) {
        height: 42px;
        width: 42px;
    }
`;

export const ContainerBody = styled.div`
    background-color: green;
    display: flex;
    height: 250px;
    min-width: 100%;
`;

export const TitleScreen = styled.div<ContainerDeliveryInterfaces>`
    color: ${colors.basic.black};
    font-weight: bold;
    position: absolute;
    font-size: 24px;
    transition-duration: 400ms;
    top: 0px;
    left: ${(props) => (props.visibleItem ? '130px' : '60px')};
`;

export const ContainerBoxProfile = styled.div<ContainerDeliveryInterfaces>`
    position: absolute;
    align-items: center;
    justify-content: center;
    transition-duration: 400ms;
    display: flex;
    width: 100%;
    padding-left: ${(props) => (props.visibleItem ? '130px' : '60px')};
    top: 70px;
    max-height: 85%;
    overflow-y: auto;
    flex-direction: column;
    min-height: 85%;
`;

export const ContainerFrame = styled.div<ContainerFrameInterfaces>`
    flex-direction: column;
    display: flex;
    margin-top: 20px;
    width: ${(props) => (props.divideInput || '100%')};
`;

export const TitleLabel = styled.text`
    color: ${colors.theme.labelInputsCreateSeller};
    font-size: 15px;
    margin-bottom: 5px;

    @media (max-width: 1200px) {
        font-size: 8px;
    }
`;

export const Input = styled.input`
    color: ${colors.basic.black};
    background-color: ${colors.basic.white};
    border: none;
    border-radius: 5px;
    height: 40px;
    padding-left: 15px;
    width: 100%;
    @media (max-width: 1200px) {
        font-size: 11px;
        height: 30px;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        height: 20px;
    }
`;

export const ContainerCamera = styled.div<ContainerPointerInterfaces>`
    align-items: center;
    background-color: ${colors.theme.backgroundButtonLogin};
    display: flex;
    height: 30px;
    justify-content: center;
    border-radius: 15px;
    width: 30px;
    @media (max-width: 1200px) {
        height: 18px;
        width: 18px;
    }
    @media (max-width: 600px) {
        height: 14px;
        width: 14px;
    }
`;

export const NameUser = styled.div<NameUserInterfaces>`
    color: ${colors.basic.black};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: 16px;
`;

export const ImgCamera = styled.img`
    height: 17px;
    width: 17px;
    @media (max-width: 1200px) {
        height: 13px;
        width: 13px;
    }
    @media (max-width: 600px) {
        height: 9px;
        width: 9px;
    }
`;

export const ContainerButtonAction = styled.div`
    margin-top: 10px;
    width: 100%;
`;

export const ContainerInfoProfile = styled.div`
    margin-left: 30px;
    display: flex;
`;
