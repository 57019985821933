// libraries
import styled from 'styled-components';
import { logoAuth } from '../../assets';

// js
import colors from '../../styles/colors';

// interfaces
interface ContainerDeliveryMenInterfaces {
    visibleItem?: boolean,
}

interface ContainerHeaderInterfaces {
    backgroundColor?: string,
    justifyContent?: string,
    cursorActive?: boolean,
    padding?: string,
    marginBottom?: string,
}

interface HeaderInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
    marginBottom?: string,
    marginLeft?: string,
    marginTop?: string,
    marginRight?: string,
    backgroundColor?: string,
    widthText?: string,
    padding?: string,
    cursorActive?: boolean,
    alignSelf?: string,
    width?: string,
}

interface ContainerInfoInterfaces {
    alignItems?: string,
    flexDirection?: string,
    marginRight?: string,
    width?: string,
    marginBottom?: string,
    justifyContent?: string,
    backgroundColor?: string,
    marginTop?: string,
}

interface ContainerNullInterfaces {
    alingItems?: string,
    marginLeft?: string,
    justifyContent?: string,
    width?: string,
}

interface ContainerOpenDropDawnInterfaces {
    visibleItem?: boolean,
    isOpen?: boolean,
}

interface TabOptionInterfaces {
    color?: string,
}

interface ContainerDataPersonalDeliveryMenInterfaces {
    justifyContent?: string,
    flexDirection?: string,
}

interface ContainerPointerInterfaces {
    existPhoto: string,
}

interface LogoInterfaces {
    background?: string,
}

interface ContentDeliveryMenInterfaces {
    flexDirection?: string,
    marginTop?: string
}

interface InputInterfaces {
    existSearch?: boolean,
}

// styles
export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const ContainerSelect = styled.select`
    background-color: ${colors.basic.white};
    border: 1px solid ${colors.theme.backgroundHeaderScreenApp};
    border-radius: 20px;
    padding-left: 5px;
    color: ${colors.basic.gray};
    font-weight: bold;
    font-size: 11px;
    height: 20px;
    width: 50%;
    @media (max-width: 1200px) {
        font-size: 11px;
    }
    @media (max-width: 800px) {
        font-size: 9px;
    }
    @media (max-width: 500px) {
        font-size: 5px;
    }
`;

export const Option = styled.option``;

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreenApp};
`;

export const ContainerInner = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ContainerBox = styled.div<ContainerDataPersonalDeliveryMenInterfaces>`
    align-items: flex-end;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    margin-bottom: 20px;
`;

export const PhotoSeller = styled.img`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 4px;
    @media (max-width: 1200px) {
        width: 25px;
        height: 25px;
    }
    @media (max-width: 800px) {
        width: 20px;
        height: 20px;
    }
    @media (max-width: 500px) {
        width: 15px;
        height: 15px;
    }
`;

export const CheckBox = styled.input`
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
    height: 15px;
    width: 15px;
    @media (max-width: 500px) {
        height: 10px;
        width: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
`;

export const ContainerInfo = styled.div<ContainerInfoInterfaces>`
    align-items: ${(props) => (props.alignItems || 'flex-start')};
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'column')};
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    width: ${(props) => (props.width || 'auto')};
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
`;

export const ContainerDeliveryMen = styled.div<ContainerDeliveryMenInterfaces>`
    position: absolute;
    flex-direction: column;
    transition-duration: 400ms;
    display: flex;
    width: 100%;
    padding-left: ${(props) => (props.visibleItem ? '130px' : '60px')};
    top: 70px;
    max-height: 85%;
    overflow-y: auto;
`;

export const TitleScreen = styled.div`
    color: ${colors.basic.black};
    font-weight: bold;
    font-size: 24px;
`;

export const ContentDeliveryMen = styled.div<ContentDeliveryMenInterfaces>`
    width: 100%;
    margin-top: ${(props) => (props.marginTop || '30px')};
    display: flex;
    justify-content: space-between;
    flex-direction: ${(props) => (props.flexDirection || 'column')};
`;

export const ContainerBoxInfoDeliveryMen = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    height: 20px;
    border-radius: 30px;
    margin-left: 5px;
    justify-content: center;
    padding: 3px;
    width: 50%;
    @media (max-width: 1200px) {
        border-radius: 20px;
    }
    @media (max-width: 800px) {
        border-radius: 10px;
    }
    @media (max-width: 500px) {
        border-radius: 5px;
    }
`;

export const ContainerBoxDeliveryMen = styled.div<HeaderInterfaces>`
    background-color: ${colors.theme.backgroundHeaderScreenApp};
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: ${(props) => (props.padding || '0px 0px 0px 0px')};
    width: ${(props) => (props.width || '40%')};
    padding-left: 10px;
    padding-right: 10px;
`;

export const ContainerImgEllipsis = styled.div`
    min-width: 20px;
`;

export const ContainerDataPersonalDeliveryMen = styled.div<ContainerDataPersonalDeliveryMenInterfaces>`
    display: flex;
    justify-content: ${(props) => (props.justifyContent || 'space-between')};
    padding-right: 5px;
    padding-left: 5px;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

export const ContainerHeader = styled.div<ContainerHeaderInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor || colors.theme.gray)};
    display: flex;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    cursor: ${(props) => (props.cursorActive ? 'pointer' : 'default')};
    padding: ${(props) => (props.padding || '5px 10px 5px 10px')};
    @media (max-width: 500px) {
        height: 30px;
    }
`;

export const TitleDefault = styled.text<HeaderInterfaces>`
    color: ${(props) => (props.color || colors.theme.colorTextDeliveryMen)};
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '14px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    margin-left: ${(props) => (props.marginLeft || '0px')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    width: ${(props) => (props.widthText || 'auto')};
    max-width: ${(props) => (props.widthText || 'auto')};
    padding: ${(props) => (props.padding || '0px 0px 0px 0px')};
    cursor: ${(props) => (props.cursorActive ? 'pointer' : 'default')};
    align-self: ${(props) => (props.alignSelf || 'auto')};
    border-radius: 3px;
    word-break: break-all;
    @media (max-width: 1200px) {
        font-size: 11px;
    }
    @media (max-width: 800px) {
        font-size: 9px;
        white-space: nowrap; // Removendo quebra de linha
        overflow: hidden; // Removendo a barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final do texto
    }
    @media (max-width: 500px) {
        font-size: 5px;
        white-space: nowrap; // Removendo quebra de linha
        overflow: hidden; // Removendo a barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final do texto
    }
`;

export const TitleResultStatistics = styled.text`
    font-size: 11px;
    font-weight: bold;
    color: ${colors.basic.gray};
    @media (max-width: 1200px) {
        font-size: 9px;
    }
    @media (max-width: 800px) {
        font-size: 6px;
    }
    @media (max-width: 500px) {
        font-size: 5px;
    }
`;

export const ImgEllipsis = styled.img`
    height: 20px;
    width: 20px;
    @media (max-width: 1200px) {
        width: 15px;
        height: 15px;
    }
    @media (max-width: 800px) {
        width: 12px;
        height: 12px;
    }
    @media (max-width: 500px) {
        width: 10px;
        height: 10px;
    }
`;

export const ContainerNull = styled.div<ContainerNullInterfaces>`
    align-items: ${(props) => (props.alingItems || 'flex-start')};
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    margin-left: ${(props) => (props.marginLeft || '5px')};
    width: ${(props) => (props.width || 'auto')};
`;

export const ContainerOpenDropDawn = styled.button<ContainerOpenDropDawnInterfaces>`
    align-items: flex-end;
    background-color: ${(props) => (props.isOpen ? colors.basic.white : 'transparent')};
    flex-direction: column;
    display: flex;
    justify-content: ${(props) => (props.isOpen ? 'flex-start' : 'center')};
    border-radius: 5px;
    border: none;
    height: ${(props) => (props.isOpen ? 'auto' : '0px')};
    padding: ${(props) => (props.isOpen ? '10px' : '0px')};
    cursor: pointer;
    transition-duration: 400ms;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    position: ${(props) => (props.isOpen ? 'absolute' : 'relative')};
    right: ${(props) => (props.isOpen ? '25px' : '0px')};
    box-shadow: ${(props) => (props.isOpen ? '0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2)' : 'none')};
    width: ${(props) => (props.isOpen ? '10%' : 'auto')};
`;

export const ContainerOptions = styled.div`
    display: flex;
    margin-top: 3px;
    padding: 2px 0px 2px 0px;
    width: 100%;
`;

export const TabOption = styled.text<TabOptionInterfaces>`
    color: ${(props) => (props.color || colors.theme.colorTextDeliveryMen)};
    cursor: pointer;
    font-weight: 500;
    display: flex;
    font-size: 12px;
    text-align: left;
    @media (max-width: 1200px) {
        font-size: 11px;
    }
    @media (max-width: 800px) {
        font-size: 9px;
    }
    @media (max-width: 500px) {
        font-size: 5px;
    }
`;

export const PhotoArchives = styled.div<LogoInterfaces>`
    object-fit: cover;
    height: 400px;
    cursor: pointer;
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    width: 100%;
    @media (max-width: 1200px) {
        height: 300px;
    }
    @media (max-width: 800px) {
        height: 250px;
    }
    @media (max-width: 500px) {
        height: 200px;
    }
`;

export const ContainerBodyDeliveryMen = styled.div<ContainerOpenDropDawnInterfaces>`
    align-items: center;
    display: flex;
    width: 90%;
    justify-content: space-between;
    cursor: pointer;
    padding: 5px 10px 5px 10px;
`;

export const ContainerButtonAction = styled.div`
    margin-top: 10px;
    display: flex;
    width: 100%;
`;

export const TitleDetailsUser = styled.text`
    color: ${colors.basic.black};
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
        font-size: 18px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

export const ContainerPointer = styled.button<ContainerPointerInterfaces>`
    background-color: ${colors.theme.backgroundLogoDefault};
    border: 2px solid ${colors.theme.backgroundButtonLogin};
    border-radius: 50%;
    display: flex;
    margin-right: 10px;
    justify-content: flex-end;
    align-items: flex-end;
    height: 124px;
    width: 124px;
    @media (max-width: 1200px) {
        height: 82px;
        width: 82px;
    }
    @media (max-width: 600px) {
        height: 42px;
        width: 42px;
    }
`;

export const Logo = styled.div<LogoInterfaces>`
    border-radius: 50%;
    height: ${(props) => (props.background === logoAuth ? '110px' : '120px')};
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-image: ${(props) => (`url(${props.background})`)};
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    width: 120px;
    @media (max-width: 1200px) {
        height: 80px;
        width: 80px;
    }
    @media (max-width: 600px) {
        height: 40px;
        width: 40px;
    }
`;

export const ContainerCamera = styled.div<ContainerPointerInterfaces>`
    align-items: center;
    background-color: ${colors.theme.backgroundButtonLogin};
    display: flex;
    height: 30px;
    justify-content: center;
    border-radius: 15px;
    width: 30px;
    @media (max-width: 1200px) {
        height: 18px;
        width: 18px;
    }
    @media (max-width: 600px) {
        height: 14px;
        width: 14px;
    }
`;

export const ImgCamera = styled.img`
    height: 17px;
    width: 17px;
    @media (max-width: 1200px) {
        height: 13px;
        width: 13px;
    }
    @media (max-width: 600px) {
        height: 9px;
        width: 9px;
    }
`;

export const BackgroundCheck = styled.div`
    background-color: ${colors.basic.green};
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

export const Checked = styled.img`
    object-fit: contain;
    height: 65px;
    width: 60px;
`;

export const Input = styled.input<InputInterfaces>`
    color: ${colors.theme.backgroundDrawer};
    background-color: ${colors.theme.backgroundCardInfo};
    border: ${(props) => (props.existSearch ? `1px solid ${colors.theme.backgroundTabs}` : `1px solid ${colors.theme.backgroundCardInfo}`)};
    border-radius: 5px;
    transition-duration: 400ms;
    min-height: 40px;
    padding-left: 15px;
    margin-top: 20px;
    width: 30%;
    @media (max-width: 1200px) {
        font-size: 11px;
        height: 30px;
    }
    @media (max-width: 600px) {
        font-size: 9px;
        height: 20px;
    }
`;
