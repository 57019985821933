// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ArrayOptionsDropDawn = (v: any, idScreen: boolean): Array<string> => {
    let arrayOptionsDropDawn: Array<string> = ['Recuperar senha', 'Excluir'];
    if (!v.blocked_at) {
        arrayOptionsDropDawn = ['Bloquear', ...arrayOptionsDropDawn];
    }
    if (v.blocked_at) {
        arrayOptionsDropDawn = ['Desbloquear', ...arrayOptionsDropDawn];
    }
    if (idScreen) {
        arrayOptionsDropDawn = ['Editar loja', 'Ver entregadores', ...arrayOptionsDropDawn];
    }
    if (!idScreen) {
        arrayOptionsDropDawn = ['Alterar permissões', ...arrayOptionsDropDawn];
    }
    return arrayOptionsDropDawn;
};

export default {
    ArrayOptionsDropDawn,
};
