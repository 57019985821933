// js
import consts from '../utils/consts';
import { DataInputsInterfaces } from '../pages/Users/usersInterfaces';
import { DataInputsCreateSellerInterfaces } from '../pages/Establishment/establishmentInterfaces';

// constants
const { baseUrl } = consts;

// interfaces
interface GetInfoSellerInterfaces {
    status: number,
    message: string,
}

// api
async function DeleteOneOrAllSubSellers(
    idSeller: number | null,
    idSubSeller: number | null,
    idSubSellers: Array<number>,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const method = 'DELETE';
        const configs = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const ValidationPath = () => {
            if (idSubSeller && !idSeller && !idSubSellers.length) {
                return `${baseUrl}/sellers?idSubSeller=${idSubSeller}`;
            }
            if (!idSubSeller && !idSeller && idSubSellers.length) {
                return `${baseUrl}/sellers?idSubSellers=${idSubSellers}`;
            }
            if (!idSubSeller && idSeller && !idSubSellers.length) {
                return `${baseUrl}/sellers?idSeller=${idSeller}`;
            }

            return '';
        };
        const path = ValidationPath();
        const response = await fetch(path, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function CreateOneSubSeller(
    inputs: DataInputsInterfaces,
    idSeller: number,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const data = {
            photo: inputs.photo,
            name: inputs.nameClient,
            identifier: inputs.identifier,
            email: inputs.emailClient,
            password: inputs.passwordClient,
            type: inputs.type,
            idSeller,
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            body,
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/sellers/createSubSeller`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function CreateOneSeller(
    inputs: DataInputsCreateSellerInterfaces,
    uploadPhoto: string,
    fullAddress: string,
    latitude: number,
    longitude: number,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const data = {
            photo: uploadPhoto || inputs.photo,
            name: inputs.name,
            cnpj: inputs.identifier,
            email: inputs.email,
            password: inputs.password,
            telephone: inputs.telephone,
            cep: inputs.cep,
            neighborhood: inputs.neighborhood,
            numberHome: inputs.number,
            complement: inputs.complement,
            latitude,
            longitude,
            // pix: inputs.pix,
            // typePix: inputs.typePix,
            address: fullAddress,
            exemption: inputs.exemption,
            cashback: inputs.cashback,
            agencyNumber: inputs.agency_number,
            bankNumber: inputs.bank_number,
            accountType: inputs.account_type,
            accountNumber: inputs.account_number,
            accountDocument: inputs.account_document,
        };
        const body = JSON.stringify(data);
        const method = 'POST';
        const configs = {
            body,
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/sellers`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function EditOneSubSeller(
    inputs: DataInputsInterfaces,
    idSubSeller: number,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const data = {
            photo: inputs.photo,
            name: inputs.nameClient,
            identifier: inputs.identifier,
            email: inputs.emailClient,
            password: inputs.passwordClient,
            type: inputs.type,
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            body,
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/sellers/updateSubSeller?idSubSeller=${idSubSeller}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function EditOneSeller(
    inputs: DataInputsCreateSellerInterfaces,
    uploadPhoto: string,
    fullAddress: string,
    latitude: number,
    longitude: number,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const data = {
            photo: uploadPhoto || inputs.photo,
            name: inputs.name,
            cnpj: inputs.identifier,
            email: inputs.email,
            password: inputs.password,
            telephone: inputs.telephone,
            cep: inputs.cep,
            neighborhood: inputs.neighborhood,
            numberHome: inputs.number,
            complement: inputs.complement,
            latitude,
            longitude,
            // pix: inputs.pix,
            // typePix: inputs.typePix,
            address: fullAddress,
            exemption: inputs.exemption,
            cashback: inputs.cashback,
            agencyNumber: inputs.agency_number,
            bankNumber: inputs.bank_number,
            accountType: inputs.account_type,
            accountNumber: inputs.account_number,
            accountDocument: inputs.account_document,
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            body,
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/sellers?idSeller=${inputs.id}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function BlockedOneSellerOrSubSeller(
    idAccount: number,
    typeAccount: 'sellers' | 'sub_sellers',
    remove: boolean,
    token: string,
): Promise<GetInfoSellerInterfaces> {
    try {
        const method = 'PUT';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const path = remove ? `${baseUrl}/sellers/blocked?idAccount=${idAccount}&typeAccount=${typeAccount}&remove=${remove}` : `${baseUrl}/sellers/blocked?idAccount=${idAccount}&typeAccount=${typeAccount}`;
        const response = await fetch(path, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function GetListMotoboyFixed(
    idSeller: number,
    token: string,
): Promise<any> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/sellers/listMotoboyFixed?idSeller=${idSeller}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function AvaliationMotoboy(
    avaliation: number,
    idMotoboy: number,
    idOrder: number,
    token: string,
): Promise<any> {
    try {
        const data = {
            avaliation,
        };
        const body = JSON.stringify(data);
        const method = 'PUT';
        const configs = {
            body,
            method,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/sellers/avaliationMotoboy?idMotoboy=${idMotoboy}&idOrder=${idOrder}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

async function DisabledMotoboyFixed(
    idMotoboy: number,
    token: string,
): Promise<any> {
    try {
        const method = 'PUT';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/sellers/disabledMotoboyFixed?idMotoboy=${idMotoboy}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            status: 500,
            message: '',
        };
    }
}

export default {
    DeleteOneOrAllSubSellers,
    CreateOneSubSeller,
    EditOneSubSeller,
    BlockedOneSellerOrSubSeller,
    CreateOneSeller,
    GetListMotoboyFixed,
    DisabledMotoboyFixed,
    EditOneSeller,
    AvaliationMotoboy,
};
