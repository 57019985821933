// js
import consts from '../utils/consts';
import { DataResultInfoDashboard } from '../interfaces';

// constants
const { baseUrl } = consts;

// interfaces
interface GetInfoDashboard {
    status: number,
    result: DataResultInfoDashboard | null,
    message: string,
}

interface GeneratorReportInterfaces {
    status: number,
    result: { file: string },
    message: string,
}

// api
async function GetInfoDashboard(
    idAccount: string,
    typeAccount: string,
    idSeller: string,
    token: string,
): Promise<GetInfoDashboard> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const path = `${baseUrl}/sellers/dashboard?idAccount=${idAccount}&typeAccount=${typeAccount}${idSeller && `&idSeller=${idSeller}`}`;
        const response = await fetch(path, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: null,
            status: 500,
            message: '',
        };
    }
}

async function GeneratorReport(
    type: 'balance' | 'delivery',
    status: string,
    dateInitial: string,
    dateFinal: string,
    hourInitial: string,
    hourFinal: string,
    typeAccount: string,
    idSeller: string,
    token: string,
): Promise<GeneratorReportInterfaces> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        let NewUrl = '';
        let NewPath = '';
        if (typeAccount === 'business') {
            NewPath = '/business/report';
        }
        if (typeAccount === 'sellers') {
            NewPath = '/sellers/report';
        }
        if (type === 'delivery') {
            NewUrl = `${baseUrl}${NewPath}?type=${type}${idSeller && `&idSeller=${idSeller}`}${status && `&status=${status}`}&dateInitial=${dateInitial}&dateFinal=${dateFinal}&hourInitial=${hourInitial}&hourFinal=${hourFinal}`;
        }
        if (type === 'balance') {
            NewUrl = `${baseUrl}${NewPath}?type=${type}${idSeller && `&idSeller=${idSeller}`}`;
        }
        const response = await fetch(NewUrl, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        // FIXME - Adicionar modal de exibição de avisos.
        // eslint-disable-next-line no-alert
        // alert('Ocorreu um erro em nosso servidor. Tenta novamente mais tarde.');
        return {
            result: { file: '' },
            status: 500,
            message: '',
        };
    }
}

export default {
    GetInfoDashboard,
    GeneratorReport,
};
