// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface HeaderInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
    marginBottom?: string,
    marginLeft?: string,
    marginTop?: string,
    backgroundColor?: string,
    textAlign?: string,
}

interface ContainerDeliveryInterfaces {
    visibleItem?: boolean,
}

interface ContainerAllChartInterfaces {
    width?: string,
}

interface ContainerInfoInterfaces {
    alignItems?: string,
    flexDirection?: string,
    marginRight?: string,
    width?: string,
    marginBottom?: string,
    justifyContent?: string,
}

interface ContainerHeaderCartInterfaces {
    marginBottom?: string,
}

interface ContainerFiltersInterfaces {
    backgroundColor?: string,
    borderBottom?: string,
    borderRadius?: string,
    marginBottom?: string,
    height?: string,
    width?: string,
    cursorActive?: boolean,
}

interface ContainerBoxInfoInterfaces {
    background?: string,
}

interface BarGraphInterfaces {
    percentage: number,
    maxNumber: number,
}

interface TextDefaultInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
}

interface ContainerSellerInterfaces {
    justifyContent?: string,
    width?: string,
}

interface ButtonStatusInterfaces {
    backgroundColor?: string,
}

interface ContainerSelectInterfaces {
    minWidth?: string,
    width?: string,
    marginLeft?: string,
}

export const TextDefault = styled.text<TextDefaultInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    font-size: ${(props) => (props.fontSize || '14px')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    text-align: center;
    min-width: 16%;
    @media (max-width: 1200px) {
        font-size: 8px;
    }
    @media (max-width: 500px) {
        font-size: 6px;
    }
`;

export const PhotoSeller = styled.img`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 4px;
    @media (max-width: 1200px) {
        width: 20px;
        height: 20px;
    }
`;

export const ContainerHeaderDashboard = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 100%;
`;

export const ContainerSelect = styled.select<ContainerSelectInterfaces>`
    background-color: ${colors.basic.white};
    border: 1px solid ${colors.theme.backgroundHeaderScreenApp};
    border-radius: 20px;
    padding-left: 5px;
    color: ${colors.basic.gray};
    font-weight: bold;
    font-size: 11px;
    height: 30px;
    margin-left: ${(props) => (props.marginLeft || '10px')};
    width: ${(props) => (props.width || '50%')};
    min-width: ${(props) => (props.minWidth || 'auto')};
`;

export const Option = styled.option``;

export const ContainerSeller = styled.div<ContainerSellerInterfaces>`
    align-items: center;
    justify-content: center;
    min-width: 16%;
    display: flex;
`;

export const Container = styled.div`
    background-color: ${colors.theme.backgroundScreenApp};
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const ContainerBoxInfo = styled.div`
    background-color: ${colors.theme.logout};
    border-radius: 10px;
    display: flex;
    margin-bottom: 20px;
    padding: 15px;
    flex-direction: column;
    width: 100%;
    @media (max-width: 1200px) {
        width: 300px;
    }
`;

export const LineGray = styled.div`
    background-color: ${colors.theme.lineChart};
    height: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

export const ContainerMediaGeneral = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 5px;
    width: 100%;
    @media (max-width: 1200px) {
        width: 300px;
    }
`;

export const ContainerPercentage = styled.div`
    align-items: center;
    display: flex;
    margin-top: 5px;
`;

export const IconPercentage = styled.img`
    object-fit: contain;
    width: 17px;
`;

export const ContainerAdjustSpace = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    min-width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

export const ContainerChart = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 5px;
    margin: 5px;
    width: 95%;
`;

export const ContainerGraph = styled.div`
    align-items: flex-end;
    display: flex;
    margin-top: 20px;
    max-height: 200px;
    width: 100%;
`;

export const BarGraph = styled.div<BarGraphInterfaces>`
    background-color: ${(props) => (props.maxNumber === props.percentage ? colors.basic.white : colors.basic.white)};
    min-height: ${(props) => (`${props.percentage}px`)};
    margin-right: 5px;
    width: 15px;
`;

export const ContainerInfo = styled.div<ContainerInfoInterfaces>`
    align-items: ${(props) => (props.alignItems || 'flex-start')};
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'column')};
    justify-content: ${(props) => (props.justifyContent || 'flex-start')};
    margin-right: ${(props) => (props.marginRight || '0px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    width: ${(props) => (props.width || 'auto')};
`;

export const CirclePurple = styled.div`
    background-color: ${colors.basic.purple};
    border-radius: 50%;
    height: 10px;
    width: 10px;
`;

export const ContainerShadow = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 100%;
`;

export const ContainerFilters = styled.div<ContainerFiltersInterfaces>`
    align-items: center;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    display: flex;
    cursor: ${(props) => (props.cursorActive ? 'pointer' : 'default')};
    justify-content: space-between;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    border-radius: ${(props) => (props.borderRadius || '5px 5px 0px 0px')};
    border-bottom: ${(props) => (props.borderBottom || 'none')};
    padding-left: 10px;
    padding-right: 10px;
    height: ${(props) => (props.height || 'auto')};
    width: ${(props) => (props.width || '100%')};
    @media (max-width: 1200px) {
        height: 30px;
    }
`;

export const ContainerHeaderCart = styled.div<ContainerHeaderCartInterfaces>`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
`;

export const ContainerDashboard = styled.div<ContainerDeliveryInterfaces>`
    position: absolute;
    flex-direction: column;
    transition-duration: 400ms;
    padding-left: ${(props) => (props.visibleItem ? '130px' : '60px')};
    top: 70px;
    min-width: 100%;
    max-height: 85%;
    overflow-y: auto;
`;

export const ContainerCardShadow = styled.div`
    box-shadow: 0.5px 0.5px 3px 0.5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin: 5px;
    width: 22%;
    @media (max-width: 1200px) {
        width: 230px;
    }
`;

export const ContainerInfoCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    @media (max-width: 1200px) {
        width: 130px;
    }
`;

export const ContentCards = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
`;

export const IconCard = styled.img`
    object-fit: contain;
    width: 60px;
`;

export const TitleDefault = styled.text<HeaderInterfaces>`
    color: ${(props) => (props.color || colors.basic.black)};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    font-size: ${(props) => (props.fontSize || '14px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
    margin-left: ${(props) => (props.marginLeft || '0px')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    text-align: ${(props) => (props.textAlign || 'start')};;
`;

export const ContainerBackgroundResult = styled.div<ButtonStatusInterfaces>`
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    padding: 2px 10px 2px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TitleScreen = styled.div`
    color: ${colors.basic.black};
    font-weight: bold;
    font-size: 24px;
`;

export const Home = styled.div`
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: ${colors.theme.backgroundScreenApp};
`;

export const ContainerInner = styled.div<ContainerBoxInfoInterfaces>`
    background-color: ${(props) => (props.background || colors.theme.backgroundScreenApp)};
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Button = styled.button`
    background-color: ${colors.theme.backgroundTabs};
    height: 30px;
    width: 200px;
    border-radius: 20px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;
    margin-top: 20px;
`;

export const ContainerAllChart = styled.div<ContainerAllChartInterfaces>`
    margin-bottom: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width || '70%')};
    @media (max-width: 1200px) {
        width: 100%;
    }
`;
